import { Modal, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

function SubmitBasketModal({ open, onCancel, onOk, confirmLoading }) {
  return (
    <Modal
      title="Submit Basket"
      closable={false}
      icon={<ExclamationCircleOutlined />}
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      okText="Submit"
      confirmLoading={confirmLoading}
    >
      <Typography>
        <Typography.Paragraph>
          <ol>
            <li>Submit your basket</li>
            <li>Await from approval</li>
            <li>Access the slides</li>
          </ol>
          <Typography.Text type="danger">
            Warning: Once you submit an application your basket will be frozen
            and no further changes can be made
          </Typography.Text>
        </Typography.Paragraph>
      </Typography>
    </Modal>
  );
}

export default SubmitBasketModal;
