import React from "react";
import "@aws-amplify/ui-react/styles.css";
import "antd/dist/reset.css";
import MetadataTree from "./MetadataTree";
import { Modal, Row, Col, Input, Form } from "antd";
import "./MetadataModal.less";
import { SearchOutlined } from "@ant-design/icons";

function MetadataModal(props) {
  const [form] = Form.useForm();
  const filterValue = Form.useWatch("filter", form);

  return (
    <Modal
      className="metadata-modal"
      title={props.doc._id}
      onCancel={props.closeCallback}
      footer={null}
      open={props.open}
      width={900}
    >
      <Row justify="end" align="middle">
        <Col span={12}></Col>
        <Col span={12} style={{ padding: "12px" }}>
          <Form form={form} layout="vertical" autoComplete="off">
            {" "}
            <Form.Item name="filter">
              <Input placeholder="Filter Fields" suffix={<SearchOutlined />} />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <MetadataTree
        data={props.doc}
        showTechnicalMetadata={false}
        filterTerm={filterValue}
      />
      <MetadataTree
        data={props.doc}
        showTechnicalMetadata={true}
        filterTerm={filterValue}
      />
    </Modal>
  );
}

export default MetadataModal;
