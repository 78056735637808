import React from "react";

import { Alert } from "antd";
import {
  WifiOutlined,
  SmileOutlined,
  ArrowRightOutlined,
  CloseOutlined,
} from "@ant-design/icons";
function CommonAlert(props) {
  var icon;
  var defaultMessage;
  var message;
  var messageType;

  if (props.code < 200) {
    // 1xx
    icon = <WifiOutlined />;
  } else if (props.code < 300) {
    // 2xx
    icon = <SmileOutlined />;
  } else if (props.code < 400) {
    // 3xx
    icon = <ArrowRightOutlined />;
  } else if (props.code < 500) {
    // 4xx
    icon = <SmileOutlined rotate={180} />;
  } else {
    // 5xx
    icon = <CloseOutlined />;
  }

  if (typeof props.data === "object" && "message" in props.data) {
    message = props.data.message;
  } else if (typeof props.data === "string") {
    message = props.data;
  }
  if (props.code < 200) {
    // 1xx
    defaultMessage = "Something is wrong with your network";
    messageType = "info";
  } else if (props.code < 300) {
    // 2xx
    defaultMessage = "Success";
    messageType = "success";
  } else if (props.code < 400) {
    // 3xx
    messageType = "warning";
  } else if (props.code < 500) {
    // 4xx
    defaultMessage = "Sorry. This hasn't worked!";
    messageType = "error";
  } else {
    // 5xx
    defaultMessage = "Sorry. Something has gone wrong!";
    messageType = "error";
  }

  return (
    <Alert
      message={message ? message : defaultMessage}
      banner
      closable
      icon={icon}
      type={messageType}
      style={{ margin: 16 }}
    />
  );
}

export default CommonAlert;
