import { Col, Row, Table, Skeleton, Empty, Popover, Image } from "antd";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (name, slide) => (
      <>
        <Popover
          placement="rightBottom"
          content={
            <Image
              preview={false}
              width={200}
              src={slide._presigned_thumbnail}
            />
          }
        >
          {name}
        </Popover>
      </>
    ),
  },
  {
    title: "Case",
    dataIndex: "case_id",
    key: "case_id",
    render: (name, _) => <>{name}</>,
  },
  {
    title: "Project",
    dataIndex: "project",
    key: "project",
  },
  {
    title: "Dataset",
    dataIndex: "dataset",
    key: "dataset",
  },
  {
    title: "Sex",
    dataIndex: "sex",
    key: "sex",
  },
  {
    title: "Age Range",
    dataIndex: "age_range",
    key: "age_range",
    render: (age_range, _) => {
      if (age_range) {
        return (
          <>
            {age_range.lower} - {age_range.upper}
          </>
        );
      }
    },
  },
  {
    title: "Staining",
    dataIndex: "stain",
    key: "stain",
    render: (stain, _) => {
      if (stain) {
        return (
          <>
            {stain.name ? stain.name : ""} {stain.type ? `(${stain.type})` : ""}
          </>
        );
      }
    },
  },
  {
    title: "Diagnosis",
    dataIndex: "diagnosis",
    key: "diagnosis",
    render: (diagnosis, _) => {
      if (diagnosis) {
        return (
          <>
            {diagnosis.codes
              ? `${diagnosis.standard}: ${diagnosis.codes.join(", ")}`
              : ""}{" "}
          </>
        );
      }
    },
  },
  {
    title: "Tissue",
    dataIndex: "tissue_type",
    key: "tissue_type",
  },
  {
    title: "Procedure",
    dataIndex: "procedure",
    key: "procedure",
    render: (procedure, _) => {
      if (procedure) {
        return <>{procedure.type ? procedure.type : ""}</>;
      }
    },
  },
];

const small_columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (name, slide) => (
      <>
        <Popover
          placement="rightBottom"
          content={
            <Image
              preview={false}
              width={200}
              src={slide._presigned_thumbnail}
            />
          }
        >
          {name.substring(0, 5)}...
        </Popover>
      </>
    ),
  },
  {
    title: "Staining",
    dataIndex: "stain",
    key: "stain",
    render: (stain, slide) => {
      if (stain) {
        return (
          <>
            {stain.name ? stain.name : ""} {stain.type ? `(${stain.type})` : ""}
          </>
        );
      }
    },
  },
  {
    title: "Tissue",
    dataIndex: "tissue_type",
    key: "tissue_type",
  },
];

function BasketTable({
  data,
  onChange,
  pagination,
  isLoading,
  rowSelection,
  isNotXL,
}) {
  if (isLoading) {
    return (
      <>
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </>
    );
  }
  if (!data) {
    return (
      <Row align="middle" style={{ height: "100%" }}>
        <Col span={24}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Col>
      </Row>
    );
  }
  return (
    <Table
      rowSelection={rowSelection}
      columns={isNotXL ? small_columns : columns}
      dataSource={data}
      onChange={onChange}
      pagination={pagination}
    />
  );
}

export default BasketTable;
