import React, { useState } from "react";
import "@aws-amplify/ui-react/styles.css";
import { Row, Col, Empty, Modal, Button } from "antd";
import { ExpandAltOutlined } from "@ant-design/icons";
import "antd/dist/reset.css";
import { Link } from "react-router-dom";
import Tile from "./Tile";
import SlideViewerContainer from "../SlideViewer/SlideViewerContainer";
import Folder from "./Folder";
import CommonAlert from "../Core/CommonAlert";

function SearchResults(props) {
  const [isSlideViewerOpen, setIsSlideViewerOpen] = useState(false);
  const [miniViewer, setMiniViewer] = useState({});

  const searchCaseSlides = (caseID) => {
    props.searchCase(caseID);
  };

  return (
    <>
      {props.error && (
        <CommonAlert data={props.error.data} code={props.error.code} />
      )}
      {!props.isLoading && (
        <>
          {props.docs.length ? (
            <>
              {props.groupBy === "GroupByCase" ? (
                <Row
                  gutter={[16, 16]}
                  wrap={true}
                  justify="center"
                  style={{ padding: 24 }}
                >
                  {props.docs.map((doc) => (
                    <Col
                      flex="1 1 360px"
                      style={{ maxWidth: "360px" }}
                      span={8}
                      key={doc.key}
                    >
                      <Folder
                        doc={doc}
                        searchCaseSlidesCallback={(doc) =>
                          searchCaseSlides(doc.key)
                        }
                      ></Folder>
                    </Col>
                  ))}
                </Row>
              ) : (
                <Row
                  gutter={[16, 16]}
                  wrap={true}
                  justify="center"
                  style={{ padding: 24 }}
                >
                  {props.docs.map((doc) => (
                    <Col
                      flex="1 1 360px"
                      style={{ maxWidth: "360px" }}
                      span={8}
                      key={doc._id}
                    >
                      <Tile
                        doc={doc}
                        showSlideViewerCallback={(doc) => {
                          var dimensions = [0, 0];
                          if ("level_dimensions" in doc) {
                            dimensions =
                              doc.level_dimensions.length === 0
                                ? [0, 0]
                                : doc.level_dimensions[0];
                          }

                          if (
                            doc.name &&
                            doc.file_path &&
                            dimensions[0] &&
                            dimensions[1]
                          ) {
                            setIsSlideViewerOpen(true);
                            setMiniViewer({
                              name: doc.name,
                              width: dimensions[0],
                              height: dimensions[1],
                              path: doc.file_path,
                              index: doc._index,
                            });
                          }
                        }}
                        inSelectionMode={props.inSelectionMode}
                        removeSelectedSlide={props.removeSelectedSlide}
                        addSelectedSlide={props.addSelectedSlide}
                        isSelected={props.selectedSlides.has(doc._id)}
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </>
          ) : (
            <Row align="middle" style={{ height: "100%", minHeight: "50vh" }}>
              <Col span={24}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Col>
            </Row>
          )}
        </>
      )}
      <Modal
        title={miniViewer.name}
        footer={
          <Link
            to={`/viewer/${miniViewer.name}?index=${miniViewer.index}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button icon={<ExpandAltOutlined />}>New Tab</Button>
          </Link>
        }
        open={isSlideViewerOpen}
        centered={true}
        width="80%"
        onCancel={() => setIsSlideViewerOpen(false)}
        destroyOnClose={true}
      >
        <SlideViewerContainer
          name={miniViewer.name}
          width={miniViewer.width}
          height={miniViewer.height}
          path={miniViewer.path}
          containerHeight="600px"
        />
      </Modal>
    </>
  );
}

export default SearchResults;
