import React from "react";
import { Row, Col, Segmented } from "antd";
import { PictureOutlined, FolderOpenOutlined } from "@ant-design/icons";
function ToolBar(props) {
  console.log(props.requestState.caseId);
  return (
    <>
      <Row style={{ width: "100%" }} justify="space-between" gutter={16}>
        <Col>
          <p>Results : {!props.isLoading ? props.noOfResults : "..."}</p>
        </Col>

        <Col>
          <Segmented
            size="large"
            value={
              props.requestState.groupBy !== "GroupByCase" ? "slides" : "cases"
            }
            options={[
              {
                label: "Slides",
                value: "slides",
                icon: <PictureOutlined style={{ padding: 12 }} />,
              },
              {
                label: "Cases",
                value: "cases",
                icon: <FolderOpenOutlined style={{ padding: 12 }} />,
              },
            ]}
            onChange={(value) => {
              if (value === "cases") {
                props.search("GroupByCase");
                props.setInSelectionMode(false);
                props.setSelectedBasket(null);
              } else {
                props.search();
              }
            }}
          />
        </Col>
      </Row>
    </>
  );
}

export default ToolBar;
