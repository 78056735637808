import { Col, Card, Skeleton, Empty, Typography } from "antd";

function StatisticsCol({ title, isLoading, hasData, children }) {
  return (
    <Col
      style={{
        padding: 12,
      }}
      span={24}
      md={12}
      lg={6}
    >
      <Card style={{ width: "100%", height: "100%" }}>
        <Typography.Title level={3}>{title}</Typography.Title>
        {isLoading && (
          <Skeleton
            style={{ width: "100%", height: "100%" }}
            paragraph={{ rows: 8 }}
            active
          />
        )}
        {!isLoading && !hasData && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        {!isLoading && hasData && <>{children}</>}
      </Card>
    </Col>
  );
}

export default StatisticsCol;
