import React, { useState } from "react";
import { Auth } from "aws-amplify";

import { Button, Card, Form, Input, Typography, Upload, Switch } from "antd";
import { API } from "aws-amplify";
import { UploadOutlined } from "@ant-design/icons";
import CommonAlert from "../Core/CommonAlert";

const { Title } = Typography;
const { TextArea } = Input;

function CreateProject({ user }, props) {
  const [error, setError] = useState(null);
  const [hasSuccess, setSuccess] = useState(false);

  const myForm = React.createRef();

  const createProject = async (values) => {
    const session = await Auth.currentSession();

    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      response: false, //Only want response body and not the whole Axiom object
      body: values,
    };

    API.post("PathLakeRESTAPI", `/projects/`, myInit)
      .then((response) => {
        setSuccess(true);
      })
      .catch((error) => {
        setError({ data: error.response.data, code: error.response.status });
      });
  };

  const handleProjectNameChange = (event) => {
    var id = event.target.value;
    id = id.replace(/\s/g, "_");
    id = id.toLowerCase();
    myForm.current?.setFieldsValue({
      id: id,
    });
  };

  return (
    <Card>
      <Form
        ref={myForm}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={createProject}
        style={{ width: "500px" }}
      >
        <Title>Create Project</Title>
        {hasSuccess && <CommonAlert data="Created Project" code={200} />}
        {error && <CommonAlert data={error.data} code={error.code} />}
        <Form.Item
          label="Name"
          name="name"
          tooltip="Your project name"
          rules={[
            {
              required: true,
              message: "Please input the name!",
            },
          ]}
        >
          <Input onChange={handleProjectNameChange} />
        </Form.Item>
        <Form.Item
          label="Term Match"
          name="term"
          tooltip="Project name matching the name used in metadata"
          rules={[
            {
              required: true,
              message: "Please input the term!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Project ID"
          name="id"
          tooltip="Project ID can not contain any uppercase letters or whitespace"
          rules={[
            {
              required: true,
              message: "Please input the project ID!",
            },
            {
              message:
                "Project ID can not contain any uppercase letters or whitespace!",
              validator: (_, value) => {
                if (/\s/g.test(value) || /[A-Z]/.test(value)) {
                  return Promise.reject("Some message here");
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Allow All Access" name="all_access">
          <Switch />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: "Please input the description!" }]}
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item name="image_url">
          <Upload {...props}>
            <Button disabled={true} icon={<UploadOutlined />}>
              Upload Logo
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default CreateProject;
