import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";

import AdminSider from "./AdminSider";
import { Card, Input, Typography, Layout, Breadcrumb } from "antd";
import { API } from "aws-amplify";
import dayjs from "dayjs";
import CommonAlert from "../Core/CommonAlert";

const { Content } = Layout;
const { TextArea } = Input;

function ViewManifest(props, { user }) {
  const [error, setError] = useState(null);
  const [manifest, setManifest] = useState({});
  const [loaded, setLoaded] = useState(false);

  const fetchData = async () => {
    const session = await Auth.currentSession();
    let query = {};
    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      queryStringParameters: query,
      response: false, //Only want response body and not the whole Axiom object
    };

    API.get("PathLakeRESTAPI", `/manifests/${props.manifestId}`, myInit)
      .then((response) => {
        setLoaded(true);
        setManifest(response);
      })
      .catch((error) => {
        setLoaded(true);
        setError({ data: error.response.data, code: error.response.status });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout>
      <Layout>
        <AdminSider />
        <Content>
          {loaded && (
            <Card>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <a href="/admin#manifests">Manifests</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{manifest.name}</Breadcrumb.Item>
              </Breadcrumb>
              <Typography.Title>{manifest.name}</Typography.Title>
              {error && <CommonAlert data={error.data} code={error.code} />}
              <Card title="Manifest Details">
                <p>
                  Created at:{" "}
                  {dayjs(manifest.created_at).format("YYYY MMM D H:m:s")}
                </p>
                <p>
                  Created by:{" "}
                  {"created_by" in manifest ? (
                    <a href={`/users/${manifest.created_by}`}>
                      {manifest.created_by}
                    </a>
                  ) : (
                    manifest.user_id
                  )}
                </p>
                <p>Approved: {manifest.is_approved ? "True" : "False"}</p>
                <p>
                  Approved By:{" "}
                  {manifest.approved_by ? manifest.approved_by : "N/A"}
                </p>
              </Card>
              {manifest.slides && (
                <Card title="Manifest Slides">
                  <TextArea
                    rows={30}
                    value={manifest.slides.join("\r\n")}
                  ></TextArea>
                </Card>
              )}
            </Card>
          )}
        </Content>
      </Layout>
    </Layout>
  );
}

export default ViewManifest;
