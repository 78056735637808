import React from "react";
import "@aws-amplify/ui-react/styles.css";
import { Form, Input, Button, Tooltip } from "antd";
import { useMediaQuery } from "react-responsive";

import "antd/dist/reset.css";
import "./ProjectsSearchBar.less";

import {
  UnorderedListOutlined,
  SearchOutlined,
  CloseOutlined,
} from "@ant-design/icons";

function ProjectsSearchBar(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <Form.Item name="q">
      <Input
        className="searchBar"
        placeholder="Enter a project, case, tissue or name"
        autoComplete="off"
        allowClear={{
          clearIcon: (
            <Tooltip placement="bottom" title="Clear" color="black">
              <Button type="text" icon={<CloseOutlined />} size="large" />
            </Tooltip>
          ),
        }}
        suffix={
          <>
            {(isTabletOrMobile || props.isIndexPage) && (
              <Tooltip placement="bottom" title="Apply Filters" color="black">
                <Button
                  type="text"
                  icon={<UnorderedListOutlined />}
                  size="large"
                  onClick={props.onOpenFilters}
                />
              </Tooltip>
            )}
            <Tooltip placement="bottom" title="Search" color="black">
              <Button
                type="text"
                icon={<SearchOutlined />}
                size="large"
                onClick={props.onSearch}
              />
            </Tooltip>
          </>
        }
        size="large"
        style={{ width: "100%", backgroundColor: "white" }}
      />
    </Form.Item>
  );
}

export default ProjectsSearchBar;
