import React, { useState, useEffect } from "react";
import { useSearchParams, useParams } from "react-router-dom";

import "@aws-amplify/ui-react/styles.css";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import "antd/dist/reset.css";
import SlideViewerContainer from "../components/SlideViewer/SlideViewerContainer";

function ViewerPage() {
  const { slideId } = useParams();
  const [doc, setDoc] = useState({});
  const [levelDimensions, setLevelDimensions] = useState([0, 0]);
  let [searchParams] = useSearchParams();

  useEffect(() => {
    document.title = "PathLAKE - Viewer";
    const makeRequest = async () => {
      const session = await Auth.currentSession();
      const myInit = {
        headers: {
          Authorization: session.getIdToken().getJwtToken(),
        },
        response: false, //Only want response body and not the whole Axiom object
        queryStringParameters: { index: searchParams.get("index") },
      };
      API.get("PathLakeRESTAPI", `/metadata/${slideId}`, myInit)
        .then((response) => {
          setDoc(response);
          const dimensions =
            response.level_dimensions.length === 0
              ? [0, 0]
              : response.level_dimensions[0];
          setLevelDimensions(dimensions);
        })
        .catch((error) => {});
    };
    makeRequest();
  }, [searchParams, slideId]);

  return (
    <>
      <SlideViewerContainer
        name={doc.name}
        width={levelDimensions[0]}
        height={levelDimensions[1]}
        path={doc.file_path}
        containerHeight="100vh"
      />
    </>
  );
}

export default ViewerPage;
