import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { CloseOutlined } from "@ant-design/icons";

import AdminSider from "./AdminSider";
import {
  Button,
  Card,
  Input,
  Form,
  List,
  Typography,
  Tabs,
  Space,
  Layout,
  Breadcrumb,
  Tag,
  Switch,
  Checkbox,
} from "antd";
import { API } from "aws-amplify";
import CommonAlert from "../Core/CommonAlert";

const { Content } = Layout;

function ViewProject(props) {
  const [error, setError] = useState(null);
  const [project, setProject] = useState({});
  const [success, setSuccess] = useState(false);
  const [editMode, setEditMode] = useState(false);

  var fields = [
    "project.keyword",
    "thumbnail_path*",
    "name*",
    "dataset*",
    "case_id*",
    "age_range*",
    "sex*",
    "diagnosis*",
    "year_scanned*",
    "procedure*",
    "tissue_type*",
    "stain*",
    "scanner*",
    "mpp*",
    "dimensions*",
    "objective_power*",
    "level_count*",
    "level_dimensions*",
    "level_downsample*",
    "axes*",
    "suffix*",
    "conversion*",
    "organisation*",
    "project_specific*",
  ];

  const myForm = React.createRef();

  const fetchData = async () => {
    const session = await Auth.currentSession();
    let query = {};
    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      queryStringParameters: query,
      response: false, //Only want response body and not the whole Axiom object
    };

    API.get("PathLakeRESTAPI", `/projects/${props.projectId}`, myInit)
      .then((response) => {
        setProject(response);
      })
      .catch((error) => {
        setError({ data: error.response.data, code: error.response.status });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const update = async (newProject) => {
    const session = await Auth.currentSession();
    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      response: false, //Only want response body and not the whole Axiom object
      body: newProject,
    };

    API.put("PathLakeRESTAPI", `/projects/${props.projectId}`, myInit)
      .then((response) => {
        setSuccess(true);
        setProject(response);
      })
      .catch((error) => {
        setProject({ ...project });
        setSuccess(false);
        setError({ data: error.response.data, code: error.response.status });
      });
  };

  const addUser = (values) => {
    if (project.users.includes(values.username)) {
      setError({
        data: values.username + " is already a member of this project",
        code: 400,
      });
    } else {
      var newProject = JSON.parse(JSON.stringify({ ...project }));
      newProject.users.push(values.username);
      update(newProject);
    }
  };

  const removeUser = (username) => {
    if (!project.users.includes(username)) {
      setError({
        data: username + " is not a member of this project",
        code: 400,
      });
    } else {
      var newProject = { ...project };
      newProject.users = newProject.users.filter((user) => {
        if (user !== username) {
          return user;
        }
        return null;
      });
      update(newProject);
    }
  };

  const removeAdmin = (username) => {
    if (!project.admins.includes(username)) {
      setError({
        data: username + " is not an admin of this project",
        code: 400,
      });
    } else {
      var newProject = { ...project };
      newProject.admins = newProject.admins.filter((user) => {
        if (user !== username) {
          return user;
        }
        return null;
      });
      update(newProject);
    }
  };

  const addAdmin = (values) => {
    if (project.admins.includes(values.username)) {
      setError({
        data: values.username + " is already an admin of this project",
        code: 400,
      });
    } else {
      var newProject = JSON.parse(JSON.stringify({ ...project }));
      newProject.admins.push(values.username);
      update(newProject);
    }
  };

  const submitEdit = (values) => {
    var newProject = { ...project };
    newProject.term = values.term;
    newProject.description = values.description;
    newProject.all_access = values.all_access;
    update(newProject);
    setEditMode(false);
  };

  const submitUpdatePermissions = (values) => {
    var newProject = { ...project };
    newProject.field_access = values;
    update(newProject);
  };

  useEffect(() => {
    if (myForm.current) {
      myForm.current.resetFields();
    }
  }, [project]);

  const CustomList = (props) => {
    return (
      <List
        bordered
        dataSource={props.dataSource}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta title={item} />
            <Button
              danger
              shape="circle"
              icon={<CloseOutlined />}
              onClick={() => {
                props.admin ? removeAdmin(item) : removeUser(item);
              }}
            />
          </List.Item>
        )}
      />
    );
  };

  return (
    <Layout>
      <Layout>
        <AdminSider />
        <Content>
          <Card>
            <Breadcrumb>
              <Breadcrumb.Item>
                <a href="/admin#projects">Projects</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{project.name}</Breadcrumb.Item>
            </Breadcrumb>
            {error && <CommonAlert data={error.data} code={error.code} />}
            {success && <CommonAlert data="Updated" code={200} />}
            <Typography.Title>{project.name}</Typography.Title>
            <Space direction="vertical">
              {!editMode ? (
                <Card
                  title="Project Details"
                  extra={<a onClick={() => setEditMode(!editMode)}>Edit</a>}
                >
                  <p>Organisation: {project.org_id}</p>
                  <p>Description: {project.description}</p>
                  <p>Term: {project.term}</p>
                  <p>ID: {project.id}</p>
                  <p>
                    Allow All Access:{" "}
                    {project.all_access ? (
                      <Tag color="green">True</Tag>
                    ) : (
                      <Tag color="red">False</Tag>
                    )}
                  </p>
                </Card>
              ) : (
                <Card
                  title="Project Details"
                  extra={<a onClick={() => setEditMode(!editMode)}>Cancel</a>}
                >
                  <Form
                    onFinish={submitEdit}
                    initialValues={{
                      description: project.description,
                      term: project.term,
                      all_access: project.all_access
                        ? project.all_access
                        : false,
                    }}
                  >
                    <Form.Item label="Description" name="description">
                      <Input placeholder="Description" />
                    </Form.Item>
                    <Form.Item
                      label="Term"
                      name="term"
                      defaultValue={project.term}
                    >
                      <Input placeholder="Term" />
                    </Form.Item>
                    <Form.Item label="Allow All Access" name="all_access">
                      <Switch defaultChecked={project.all_access} />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Update
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              )}
              <Card>
                <Tabs>
                  <Tabs.TabPane tab="Project Admins" key="admins">
                    <Space direction="vertical">
                      <CustomList dataSource={project.admins} admin={true} />

                      <Form onFinish={addAdmin}>
                        <Form.Item name="username">
                          <Input placeholder="User" />
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Add admin
                          </Button>
                        </Form.Item>
                      </Form>
                    </Space>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab="Project Users"
                    key="users"
                    disabled={project.all_access}
                  >
                    <Space direction="vertical">
                      <p>Project users have read access</p>
                      <CustomList dataSource={project.users} admin={false} />
                      <Form onFinish={addUser}>
                        <Form.Item name="username">
                          <Input placeholder="User" />
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Add user
                          </Button>
                        </Form.Item>
                      </Form>
                    </Space>
                  </Tabs.TabPane>
                </Tabs>
              </Card>
              <Card title="Available Fields">
                <Form
                  onFinish={submitUpdatePermissions}
                  initialValues={project.field_access}
                  ref={myForm}
                >
                  {fields.map((key) => (
                    <Form.Item name={key} label={key}>
                      <Checkbox.Group
                        options={[
                          { label: "User", value: "user" },
                          { label: "Admin", value: "admin" },
                        ]}
                      />
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Update
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Space>
          </Card>
        </Content>
      </Layout>
    </Layout>
  );
}

export default ViewProject;
