import React, { useState, createContext, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState();
  const [userObj, setUserObj] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (user === null) {
      Auth.currentAuthenticatedUser()
        .then((token) => {
          setUser(token);
        })
        .catch(() => {
          setUser(null);
        });
    }
  });

  const handleLogin = async (username, password) => {
    return Auth.signIn(username, password).then((userObj) => {
      setUser(userObj);
      const origin = location.state?.from?.pathname || "/";
      if ("challengeName" in userObj) {
        if (userObj.challengeName === "NEW_PASSWORD_REQUIRED") {
          setUserObj(userObj);
          navigate("/reset");
        }
      } else {
        navigate(origin);
      }
    });
  };

  const handleLogout = async () => {
    Auth.signOut().then(() => {
      setUser(null);
      window.location = "/";
    });
  };

  const handleNewPassword = async (password) => {
    Auth.completeNewPassword(userObj, password).then(() => {
      navigate("/");
    });
  };

  const handleSync = async () => {
    Auth.currentAuthenticatedUser()
      .then((token) => {
        setUser(token);
        setLoaded(true);
      })
      .catch(() => {
        setUser(null);
        setLoaded(true);
      });
  };

  const handleRegister = async (
    username,
    email,
    given_name,
    family_name,
    password,
  ) => {
    var organisation = "Unknown";
    var role = "user";
    return Auth.signUp({
      username,
      password,
      attributes: {
        email,
        given_name,
        family_name,
        ["custom:organisation"]: organisation,
        ["custom:role"]: role,
      },
      autoSignIn: {
        enabled: true,
      },
    }).then(() => {
      navigate("/signin");
    });
  };

  const handleConfirm = async (username, code) => {
    return Auth.confirmSignUp(username, code).then((userObj) => {
      setUser(user);
      const origin = location.state?.from?.pathname || "/";
      navigate(origin);
    });
  };

  const handleForgotPassword = async (username) => {
    return Auth.forgotPassword(username);
  };

  const handleForgotPasswordSubmit = async (username, code, newPassword) => {
    return Auth.forgotPasswordSubmit(username, code, newPassword);
  };

  const value = {
    user,
    loaded,
    onLoad: handleSync,
    onLogin: handleLogin,
    onLogout: handleLogout,
    onNewPassword: handleNewPassword,
    onRegister: handleRegister,
    onConfirm: handleConfirm,
    onForgotPassword: handleForgotPassword,
    onForgotPasswordSubmit: handleForgotPasswordSubmit,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
