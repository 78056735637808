import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import "./IndexPage.css";
import DefaultHeader from "../layout/DefaultHeader";
import DefaultFooter from "../layout/DefaultFooter";
import BasketCard from "../components/BasketsPage/BasketCard";
import dayjs from "dayjs";

import "@aws-amplify/ui-react/styles.css";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, Layout, Row, Col, Empty, Skeleton } from "antd";
import CommonAlert from "../components/Core/CommonAlert";

import "antd/dist/reset.css";
const { Content } = Layout;

function BasketsPage(props, { user }) {
  const [basketList, setBasketList] = useState([]);
  const [error, setError] = useState(null);

  const [isLoading, setisLoading] = useState(true);
  const [isDeleteModalVisible, setIsDeleteModal] = useState(false);
  const [deleteModalBasket, setDeleteModalBasket] = useState(null);

  useEffect(() => {
    document.title = "PathLAKE - Basket";
    const fetchData = async () => {
      let query = {};
      query["limit"] = 20;
      setisLoading(true);
      const session = await Auth.currentSession();
      const myInit = {
        headers: {
          Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
        },
        response: false, //Only want response body and not the whole Axiom object
        queryStringParameters: query,
      };
      API.get("PathLakeRESTAPI", "/baskets", myInit)
        .then((response) => {
          setBasketList(
            response["baskets"].sort((a, b) =>
              dayjs(b.created_at).diff(dayjs(a.created_at)),
            ),
          );
          setisLoading(false);
        })
        .catch((error) => {
          setError({ data: error.response.data, code: error.response.status });
          setisLoading(false);
        });
    };
    fetchData();
  }, []);

  const showModal = (basket) => {
    setDeleteModalBasket(basket);
    setIsDeleteModal(true);
  };

  const handleDelete = async () => {
    // Delete logic
    const session = await Auth.currentSession();
    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      response: false, //Only want response body and not the whole Axiom object
    };
    API.del("PathLakeRESTAPI", `/baskets/${deleteModalBasket.id}`, myInit)
      .then((response) => {
        setBasketList((basketList) =>
          basketList.filter((basket, _) => basket.id !== deleteModalBasket.id),
        );
      })
      .catch((error) => {
        setError({ data: error.response.data, code: error.response.status });
      });

    setDeleteModalBasket(null);
    setIsDeleteModal(false);
  };

  const handleCancel = () => {
    setDeleteModalBasket(null);
    setIsDeleteModal(false);
  };

  return (
    <>
      <Layout>
        <DefaultHeader selected="baskets" />
        <Content style={{ padding: 16, minHeight: "75vh" }}>
          {error && <CommonAlert data={error.data} code={error.code} />}

          {isLoading ? (
            <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          ) : (
            <>
              {" "}
              {basketList.length ? (
                <Row>
                  {basketList.map((basket) => (
                    <Col span={24} lg={12} key={basket.id}>
                      <BasketCard
                        id={basket.id}
                        name={basket.name}
                        date={basket.created_at}
                        noSlides={basket.slides.length}
                        step={
                          basket.manifest
                            ? basket.manifest.is_approved
                              ? 5
                              : 4
                            : 0
                        }
                        errored={false}
                        onDelete={() => showModal(basket)}
                      />
                    </Col>
                  ))}
                </Row>
              ) : (
                <Row align="middle" style={{ height: "100%" }}>
                  <Col span={24}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </Col>
                </Row>
              )}
            </>
          )}
        </Content>
        <DefaultFooter />
      </Layout>
      <Modal
        title="Delete Basket"
        closable={false}
        icon={<ExclamationCircleOutlined />}
        okText="Yes"
        okType="danger"
        open={isDeleteModalVisible}
        onOk={handleDelete}
        onCancel={handleCancel}
      >
        {deleteModalBasket ? (
          <p>Are you sure you want to delete {deleteModalBasket.name}?</p>
        ) : (
          ""
        )}
      </Modal>
    </>
  );
}

export default BasketsPage;
