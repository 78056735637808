import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import "@aws-amplify/ui-react/styles.css";
import { Layout, Row, Col, Spin, FloatButton } from "antd";
import "antd/dist/reset.css";
import SearchResults from "../components/SearchPage/SearchResults";
import DefaultHeader from "../layout/DefaultHeader";
import DefaultFooter from "../layout/DefaultFooter";
import SearchPagination from "../components/SearchPage/SearchPagination";
import SearchBarAndFilters from "../components/SearchPage/SearchBarAndFilters";
import SidebarFilters from "../components/SearchPage/SidebarFilters";
import BasketsModal from "../components/SearchPage/BasketsModal";
const { Content } = Layout;

const DEFAULT_LIMIT = 30;

function SearchPage({ user }) {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [docs, setDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [noOfResults, setNoOfResults] = useState(null);
  const [inSelectionMode, setInSelectionMode] = useState(false);
  const [selectedSlides, setSelectedSlides] = useState(new Set());
  const [searchParams, setSearchParams] = useSearchParams();
  const [isBasketModalOpen, setIsBasketModalOpen] = useState(false);
  const [selectedBasket, setSelectedBasket] = useState(null);
  const [basketsLastUpdated, setBasketsLastUpdated] = useState(null);
  useEffect(() => {
    document.title = "PathLAKE - Search";
  }, []);

  const removeSelectedSlide = (slideId) => {
    var temp = new Set(selectedSlides);
    temp.delete(slideId);
    setSelectedSlides(temp);
  };

  const addSelectedSlide = (slideId) => {
    var temp = new Set(selectedSlides);
    temp.add(slideId);
    setSelectedSlides(temp);
  };

  const clearSelectedSlides = () => {
    setSelectedSlides(new Set());
  };

  return (
    <>
      <Layout>
        <DefaultHeader selected="search" />
        <Layout>
          <Content style={{ padding: 16 }}>
            {/* Search and Filters */}
            <SearchBarAndFilters
              setDocs={setDocs}
              setIsLoading={setIsLoading}
              setError={setError}
              setNoOfResults={setNoOfResults}
              noOfResults={noOfResults}
              isLoading={isLoading}
              setInSelectionMode={setInSelectionMode}
              clearSelectedSlides={clearSelectedSlides}
              setFiltersOpen={setFiltersOpen}
              setSelectedBasket={setSelectedBasket}
            />
            {/* Results */}
            <Row justify="center" style={{ width: "100%" }}>
              <Col span={24} md={4}>
                <SidebarFilters
                  clearSelectedSlides={clearSelectedSlides}
                  open={filtersOpen}
                  onClose={() => setFiltersOpen(false)}
                  openBaskets={() => {
                    setIsBasketModalOpen(true);
                  }}
                  selectedBasket={selectedBasket}
                  toggleInSelectionMode={() => {
                    setInSelectionMode(!inSelectionMode);
                    setSelectedSlides(new Set());
                  }}
                  searchParams={searchParams}
                  selectedSlides={selectedSlides}
                  setSelectedBasket={(selected) => {
                    setSelectedBasket(selected);
                    setSelectedSlides(new Set(selected.slides));
                  }}
                  basketsLastUpdated={basketsLastUpdated}
                  setBasketsLastUpdated={setBasketsLastUpdated}
                  noOfResults={noOfResults}
                />
              </Col>
              {isLoading && (
                <Col span={24} md={20}>
                  <Row
                    align="middle"
                    justify="center"
                    style={{ width: "100%", minHeight: "50vh" }}
                  >
                    <Col>
                      <Spin size="large"></Spin>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col span={24} md={20}>
                <SearchResults
                  docs={docs}
                  isLoading={isLoading}
                  error={error}
                  groupBy={searchParams.get("groupBy")}
                  searchCase={(caseID) => {
                    setIsLoading(true);
                    setSearchParams({
                      caseId: caseID,
                    });
                  }}
                  inSelectionMode={inSelectionMode}
                  addSelectedSlide={addSelectedSlide}
                  removeSelectedSlide={removeSelectedSlide}
                  selectedSlides={selectedSlides}
                />
              </Col>
            </Row>
            {/* Pagination */}
            <Row align="middle" justify="center" style={{ width: "100%" }}>
              <Col style={{ padding: 24 }}>
                <SearchPagination
                  total={noOfResults}
                  page={
                    searchParams.get("page")
                      ? parseInt(searchParams.get("page")) + 1
                      : 1
                  }
                  callback={(page, limit) => {
                    const newParams = {};
                    for (const [key, value] of searchParams.entries()) {
                      newParams[key] = value;
                    }
                    newParams.page = page - 1;
                    newParams.limit = limit;
                    setSearchParams(newParams);
                    setIsLoading(true);
                  }}
                  pageSize={
                    searchParams.get("limit")
                      ? searchParams.get("limit")
                      : DEFAULT_LIMIT
                  }
                  noResultsCallback={(noResults) => setNoOfResults(noResults)}
                />
              </Col>
            </Row>
          </Content>
          <FloatButton.Group shape="circle">
            <FloatButton.BackTop visibilityHeight={0} />
          </FloatButton.Group>
        </Layout>
        <DefaultFooter />
      </Layout>

      <BasketsModal
        isOpen={isBasketModalOpen}
        setIsOpen={setIsBasketModalOpen}
        setSelectedBasket={setSelectedBasket}
        selectedBasket={selectedBasket}
        setSelectedSlides={setSelectedSlides}
        setInSelectionMode={setInSelectionMode}
        basketsLastUpdated={basketsLastUpdated}
        setBasketsLastUpdated={setBasketsLastUpdated}
      />
    </>
  );
}

export default SearchPage;
