import React from "react";
import "@aws-amplify/ui-react/styles.css";
import { Pie, G2 } from "@ant-design/plots";
import "antd/dist/reset.css";

function TissuePieChart(props) {
  const { registerTheme } = G2;
  registerTheme("custom-theme", {
    colors10: [
      "#582475",
      "#382475",
      "#482475",
      "#382475",
      "#682475",
      "#782475",
      "#882475",
    ],
    colors20: [
      "#582475",
      "#382475",
      "#482475",
      "#382475",
      "#682475",
      "#782475",
      "#882475",
    ],
  });
  const config = {
    appendPadding: 10,
    data: props.data,
    angleField: "doc_count",
    colorField: "key",
    radius: 0.75,
    legend: false,
    label: {
      type: "spider",
      labelHeight: 28,
      content: "{name}\n{percentage}",
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    theme: "custom-theme",
  };
  return <Pie {...config} />;
}

export default TissuePieChart;
