import React, { useEffect, useState } from "react";
import "../IndexPage.css";
import AuthLayout from "../../layout/AuthLayout";
import "@aws-amplify/ui-react/styles.css";
import { useAuth } from "../../auth/AuthProvider";
import { useNavigate } from "react-router-dom";

import { Typography, Form, Input, Button, Space } from "antd";
import "antd/dist/reset.css";
const { Text } = Typography;

function ForgottenPasswordPage() {
  const navigate = useNavigate();

  const { onForgotPassword, onForgotPasswordSubmit } = useAuth();
  const [error, setError] = useState();
  const [isConfirmState, setIsConfirmState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "PathLAKE - Forgotten Password";
  }, []);

  const forgotPassword = async (values) => {
    setIsLoading(true);

    onForgotPassword(values.username)
      .then((response) => {
        setIsLoading(false);
        setIsConfirmState(true);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsConfirmState(true);
      });
  };

  const forgotPasswordSubmit = async (values) => {
    setIsLoading(true);

    onForgotPasswordSubmit(values.username, values.code, values.newPassword)
      .then((response) => {
        setIsLoading(false);
        navigate("/");
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  };

  return (
    <AuthLayout title={isConfirmState ? "Confirm" : "Reset Password"}>
      <Form
        name="signIn"
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
        onFinish={isConfirmState ? forgotPasswordSubmit : forgotPassword}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input placeholder="Username" />
        </Form.Item>

        {isConfirmState && (
          <>
            <Form.Item
              name="code"
              rules={[
                {
                  required: true,
                  message: "Please input your verification code!",
                },
              ]}
            >
              <Input placeholder="Verification code" />
            </Form.Item>
            <Form.Item
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Please input your new password!",
                },
              ]}
            >
              <Input.Password placeholder="New Password" />
            </Form.Item>
          </>
        )}

        {!isConfirmState ? (
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        ) : (
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={isLoading}
            >
              Update
            </Button>
          </Form.Item>
        )}
      </Form>
      <Space direction="vertical">
        {error && <Text type="danger">{error}</Text>}
      </Space>
    </AuthLayout>
  );
}

export default ForgottenPasswordPage;
