import React, { useState, useEffect } from "react";
import "./IndexPage.css";
import ProjectsSearchBar from "../components/IndexPage/ProjectsSearchBar";
import ProjectsSearchFilter from "../components/IndexPage/ProjectsSearchFilter";
import IndexHeader from "../layout/IndexHeader";
import IndexFooter from "../layout/IndexFooter";
import DataSetsBarChart from "../components/ExplorePage/DataSetsBarChart";
import TissuePieChart from "../components/ExplorePage/TissuePieChart";
import AgesBarChart from "../components/ExplorePage/AgesBarChart";
import { useNavigate } from "react-router-dom";
import Animate from "rc-animate";
import OverviewBox from "../components/Statistics/OverviewBox";
import { stains, tissues, dataSets, ages } from "../data/StaticChartsData";
import { Parallax } from "rc-scroll-anim";
import "@aws-amplify/ui-react/styles.css";
import { useAuth } from "../auth/AuthProvider";
import {
  Layout,
  Row,
  Col,
  Typography,
  Form,
  Button,
  Spin,
  Image,
  Card,
  Space,
} from "antd";
import "antd/dist/reset.css";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
const { Content } = Layout;
const { Title } = Typography;

function IndexPage() {
  const { user, loaded, onLoad } = useAuth();
  const navigate = useNavigate();
  const [showSearchFilter, setShowSearchFilter] = useState(false);
  const [isAgeRangeEnabled, setIsAgeRangeEnabled] = useState(false);
  const [isAllCodesEnabled, setIsAllCodesEnabled] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [showSecondRowStats, setShowSecondRowStats] = useState(false);
  const myForm = React.createRef();
  const [searchValues, setSearchValues] = useState({
    q: "",
    name: "",
    project: "",
    sex: "",
    age: [0, 104],
    stain: "",
    site: "",
    ethnicity: "",
    dataset: "",
  });
  const generateQueryString = () => {
    var string = "";
    var counter = 0;
    for (const key in searchValues) {
      // Ensures that default values for the age range doesn't
      // end up in the search without being explicitly included
      if (key === "age" && !isAgeRangeEnabled) {
        continue;
      }

      if (searchValues[key] && counter === 0) string += "?";
      else if (searchValues[key] && counter > 0) string += "&";
      if (searchValues[key]) {
        string += key + "=" + encodeURIComponent(searchValues[key]);
        counter++;
      }
    }
    if (isAllCodesEnabled && counter > 0) {
      string += "&allCodes=true";
    }
    return string;
  };

  const toggleListView = (checked) => {
    if (checked) {
      carouselRef.current.goTo(1);
    } else {
      carouselRef.current.goTo(0);
    }
  };

  const carouselRef = React.createRef();

  const sortDocs = (a, b) => {
    return a["doc_count"] < b["doc_count"] ? 1 : -1;
  };

  useEffect(() => {
    document.title = "PathLAKE - Portal";

    if (!loaded) {
      onLoad();
    }
  }, [loaded, onLoad]);

  const search = () => {
    navigate(`/search${generateQueryString()}`);
  };

  return (
    <>
      {isCreatingAccount ? (
        <div
          style={{
            position: "absolute",
            zIndex: 10,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <Row
            style={{ width: "100%", height: "100%" }}
            align="middle"
            justify="center"
          >
            <Col
              style={{
                backgroundColor: "#f5f5f5",
                padding: 16,
                borderRadius: 24,
              }}
            >
              <Spin size="large" />
            </Col>
          </Row>
        </div>
      ) : (
        <></>
      )}
      <Layout id="screen">
        <IndexHeader />
        <Content>
          <Form
            ref={myForm}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="vertical"
            initialValues={searchValues}
            onValuesChange={(values, allValues) => {
              setSearchValues(allValues);
            }}
            onFinish={search}
          >
            <Row align="bottom" justify="center" style={{ minHeight: "40vh" }}>
              <Animate transitionName="fade" transitionAppear></Animate>
              <Animate transitionName="fade" transitionAppear>
                <Col style={{ textAlign: "center" }}>
                  <Title
                    style={{
                      color: "white",
                      width: "680px",
                      textAlign: "center",
                      maxWidth: "90vw",
                    }}
                  >
                    <b>Path</b>ology Image Data <b>L</b>ake for <b>A</b>
                    nalytics, <b>K</b>nowledge & <b>E</b>ducation
                  </Title>

                  {user && (
                    <ProjectsSearchBar
                      isIndexPage={true}
                      toggleListViewCallback={toggleListView}
                      showSearchFilter={showSearchFilter}
                      onSearch={search}
                      onOpenFilters={() => {
                        setShowSearchFilter(!showSearchFilter);
                      }}
                    />
                  )}
                  {!user && (
                    <Row align="middle" justify="center">
                      <Col
                        style={{
                          backgroundColor: "white",
                          borderRadius: 8,
                          margin: 8,
                        }}
                      >
                        <Button
                          type="text"
                          htmlType="submit"
                          className="logInButton"
                          size="large"
                          onClick={() => navigate("/signin")}
                        >
                          Sign In
                        </Button>
                      </Col>
                      <Col
                        style={{
                          backgroundColor: "white",
                          borderRadius: 8,
                          margin: 8,
                        }}
                      >
                        <Button
                          type="text"
                          htmlType="submit"
                          className="logInButton"
                          size="large"
                          onClick={() => navigate("/register")}
                        >
                          Register
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Animate>
            </Row>
            <Row align="top" justify="center" style={{ minHeight: "30vh" }}>
              <ProjectsSearchFilter
                showSearchFilter={showSearchFilter}
                setIsAgeRangeEnabled={setIsAgeRangeEnabled}
                isAgeRangeEnabled={isAgeRangeEnabled}
                setIsAllCodesEnabled={setIsAllCodesEnabled}
                isAllCodesEnabled={isAllCodesEnabled}
                setIsCreatingAccount={(value) => {
                  setIsCreatingAccount(value);
                }}
              />
            </Row>
          </Form>
          {!user && (
            <Animate transitionName="fade" transitionAppear>
              <Row justify="center" style={{ width: "100vw" }}>
                <Col>
                  <Space direction="vertical" align="center">
                    <Typography.Text
                      style={{
                        color: "white",
                        fontSize: "24px",
                      }}
                    >
                      Learn More
                    </Typography.Text>{" "}
                    <Button
                      href="#info"
                      size="large"
                      type="text"
                      shape="round"
                      icon={<DownOutlined />}
                      style={{ color: "white" }}
                    />
                  </Space>
                </Col>
              </Row>
            </Animate>
          )}
        </Content>
        <Parallax animation={{ opacity: 0 }} style={{ opacity: 1 }}>
          <IndexFooter />
        </Parallax>

        {!user && (
          <>
            <Parallax animation={{ opacity: 1 }} style={{ opacity: 0 }}>
              <Row id="info" align="middle" style={{ minHeight: "100vh" }}>
                <Col span={24} lg={16} style={{ padding: 32 }}>
                  <Parallax
                    animation={{ x: 0, playScale: [0, 0.5] }}
                    style={{ transform: "translateX(-24px)" }}
                  >
                    <Title
                      level={2}
                      style={{ color: "white", textAlign: "center" }}
                    >
                      PathLAKE Datalake Portal
                    </Title>
                    <Image preview={false} src="screenshot.png" />
                  </Parallax>
                </Col>
                <Col span={24} lg={8} style={{ padding: 32 }}>
                  <Parallax
                    animation={{ x: 0, playScale: [0, 0.5] }}
                    style={{ transform: "translateX(24px)" }}
                  >
                    <Card style={{ backgroundColor: "white" }}>
                      <Title level={3}>Query</Title>
                      <Typography.Text>
                        Thousands of Whole-Slide Histology Images available
                      </Typography.Text>

                      <Title level={3}>Filter</Title>
                      <Typography.Text>
                        Reduce the results by age, stain, tissues, sex or
                        diagnosis to find exactly what you are looking for
                      </Typography.Text>

                      <Title level={3}>Preview</Title>
                      <Typography.Text>
                        All slides come with thumbnails to give you the complete
                        picture at a glance
                      </Typography.Text>

                      <Title level={3}>Inspect</Title>
                      <Typography.Text>
                        Expand select WSIs to get a detailed look at the tissue
                        samples
                      </Typography.Text>

                      <Title level={3}>Curate</Title>
                      <Typography.Text>
                        Group the slides you like and save them for later, share
                        with your colleagues or use in your next project
                      </Typography.Text>

                      <Title level={3}>Understand</Title>
                      <Typography.Text>
                        Clinical metadata allows you to understand the datasets
                        in context
                      </Typography.Text>

                      <Title level={3}>Explore</Title>
                      <Typography.Text>
                        Get a complete overview of the catalogue with our
                        comprehensive dashboard
                      </Typography.Text>
                    </Card>
                  </Parallax>
                </Col>
                <Col span={24}>
                  <Row
                    align="middle"
                    justify="center"
                    style={{ width: "100vw", minHeight: "20vh" }}
                  >
                    <Col>
                      <Parallax
                        animation={{ y: 0, playScale: [-1, 0.5] }}
                        style={{ transform: "translateY(24px)" }}
                      >
                        <Space direction="vertical" align="center">
                          <Button
                            href="#stats"
                            size="large"
                            type="text"
                            shape="round"
                            icon={<DownOutlined />}
                            style={{ color: "white" }}
                          />
                          <Typography.Text
                            style={{
                              color: "white",
                              fontSize: "24px",
                            }}
                          >
                            Statistics
                          </Typography.Text>{" "}
                        </Space>
                      </Parallax>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Parallax>
            <Parallax animation={{ opacity: 1 }} style={{ opacity: 0 }}>
              <Row id="stats" align="middle" style={{ minHeight: "100vh" }}>
                <Col span={24} lg={24} style={{ padding: 32 }}>
                  <Parallax
                    animation={{ y: 0, playScale: [0, 0.5] }}
                    style={{ transform: "translateY(-24px)" }}
                  >
                    <Title style={{ color: "white", textAlign: "center" }}>
                      See what we have available
                    </Title>
                  </Parallax>
                </Col>

                <Col span={24} lg={12} style={{ padding: 32 }}>
                  <Parallax
                    animation={{ x: 0, playScale: [0, 0.5] }}
                    style={{ transform: "translateX(-24px)" }}
                  >
                    <Card style={{ backgroundColor: "white" }} title="Datasets">
                      <DataSetsBarChart data={dataSets.sort(sortDocs)} />
                    </Card>
                  </Parallax>
                </Col>
                <Col span={24} lg={12} style={{ padding: 32 }}>
                  <Parallax
                    animation={{ x: 0, playScale: [0, 0.5] }}
                    style={{ transform: "translateX(24px)" }}
                  >
                    <Card style={{ backgroundColor: "white" }} title="Tissues">
                      <TissuePieChart data={tissues.sort(sortDocs)} />
                    </Card>
                  </Parallax>
                </Col>
                <Col span={24}>
                  <Animate transitionName="fade" transitionAppear>
                    <Row
                      align="middle"
                      justify="center"
                      style={{ width: "100vw", minHeight: "20vh" }}
                    >
                      <Col>
                        <Parallax
                          animation={{ y: 0, playScale: [-1, 0.5] }}
                          style={{ transform: "translateY(24px)" }}
                        >
                          <Space direction="vertical" align="center">
                            <Button
                              href="#stats2"
                              size="large"
                              type="text"
                              shape="round"
                              icon={<DownOutlined />}
                              style={{ color: "white" }}
                            />
                            <Typography.Text
                              style={{
                                color: "white",
                                fontSize: "24px",
                              }}
                            >
                              Extra
                            </Typography.Text>{" "}
                          </Space>
                        </Parallax>
                      </Col>
                    </Row>
                  </Animate>
                </Col>
              </Row>
            </Parallax>
            <Parallax animation={{ opacity: 1 }} style={{ opacity: 0 }}>
              <Row id="stats2" align="middle" style={{ minHeight: "100vh" }}>
                <Col span={24} lg={12} style={{ padding: 32 }}>
                  <Parallax
                    animation={{ x: 0, playScale: [0, 0.5] }}
                    style={{ transform: "translateX(-24px)" }}
                  >
                    <Card style={{ backgroundColor: "white" }} title="Stains">
                      <TissuePieChart data={stains.sort(sortDocs)} />
                    </Card>
                  </Parallax>
                </Col>
                <Col span={24} lg={12} style={{ padding: 32 }}>
                  <Parallax
                    animation={{ x: 0, playScale: [0, 0.5] }}
                    style={{ transform: "translateX(24px)" }}
                  >
                    <Card style={{ backgroundColor: "white" }} title="Ages">
                      <AgesBarChart data={ages} />
                    </Card>
                  </Parallax>
                </Col>
                <Col span={24} lg={24} style={{ padding: 32 }}>
                  <Parallax
                    animation={{ y: 0, playScale: [0, 0.5] }}
                    style={{ transform: "translateY(24px)" }}
                  >
                    <OverviewBox
                      tissues={64}
                      dataSets={12}
                      total={170000}
                      filled={false}
                    />
                  </Parallax>
                </Col>
                <Col span={24}>
                  <Animate transitionName="fade" transitionAppear>
                    <Row
                      align="middle"
                      justify="center"
                      style={{ width: "100vw", minHeight: "20vh" }}
                    >
                      <Col>
                        <Parallax
                          animation={{ y: 0, playScale: [-1, 0.5] }}
                          style={{ transform: "translateY(24px)" }}
                        >
                          <Space direction="vertical" align="center">
                            <Button
                              onClick={() =>
                                setShowSecondRowStats(!showSecondRowStats)
                              }
                              href="#dataprocess"
                              size="large"
                              type="text"
                              shape="round"
                              icon={<DownOutlined />}
                              style={{ color: "white" }}
                            />
                            <Typography.Text
                              style={{
                                color: "white",
                                fontSize: "24px",
                              }}
                            >
                              Access Process
                            </Typography.Text>{" "}
                          </Space>
                        </Parallax>
                      </Col>
                    </Row>
                  </Animate>
                </Col>
              </Row>
            </Parallax>
            <Parallax animation={{ opacity: 1 }} style={{ opacity: 0 }}>
              <Row
                id="dataprocess"
                align="middle"
                style={{ minHeight: "100vh" }}
                justify="center"
              >
                <Col span={24} style={{ padding: 32 }}>
                  <Parallax
                    animation={{ y: 0, playScale: [0, 0.5] }}
                    style={{ transform: "translateY(-24px)" }}
                  >
                    <Title
                      level={2}
                      style={{ color: "white", textAlign: "center" }}
                    >
                      Data Access Process
                    </Title>
                  </Parallax>
                </Col>
                <Col span={24} lg={16} style={{ padding: 32 }}>
                  <Parallax
                    animation={{ x: 0, playScale: [0, 0.5] }}
                    style={{ transform: "translateX(24px)" }}
                  >
                    <Image preview={false} src="diagram.png" />
                  </Parallax>
                </Col>
                <Col span={24}>
                  <Animate transitionName="fade" transitionAppear>
                    <Row
                      align="middle"
                      justify="center"
                      style={{ width: "100vw", minHeight: "20vh" }}
                    >
                      <Col>
                        <Parallax
                          animation={{ y: 0, playScale: [-1, 0.5] }}
                          style={{ transform: "translateY(24px)" }}
                        >
                          <Space direction="vertical" align="center">
                            <Button
                              href="#screen"
                              size="large"
                              type="text"
                              shape="round"
                              icon={<UpOutlined />}
                              style={{ color: "white" }}
                            />
                            <Typography.Text
                              style={{
                                color: "white",
                                fontSize: "24px",
                              }}
                            >
                              Register
                            </Typography.Text>{" "}
                          </Space>
                        </Parallax>
                      </Col>
                    </Row>
                  </Animate>
                </Col>
              </Row>
            </Parallax>
            <Parallax animation={{ opacity: 0 }} style={{ opacity: 1 }}>
              <IndexFooter />
            </Parallax>
          </>
        )}
      </Layout>
    </>
  );
}

export default IndexPage;
