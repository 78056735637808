import React from "react";
import "@aws-amplify/ui-react/styles.css";
import { Bar, G2 } from "@ant-design/plots";

import "antd/dist/reset.css";

function DataSetsBarChart(props) {
  const { registerTheme } = G2;
  registerTheme("custom-theme", {
    colors10: [
      "#582475",
      "#382475",
      "#482475",
      "#382475",
      "#682475",
      "#782475",
      "#882475",
    ],
    colors20: [
      "#582475",
      "#382475",
      "#482475",
      "#382475",
      "#682475",
      "#782475",
      "#882475",
    ],
  });
  const config = {
    data: props.data,
    xField: "doc_count",
    yField: "key",
    seriesField: "key",
    legend: false,
    theme: "custom-theme",
  };
  return <Bar {...config} />;
}

export default DataSetsBarChart;
