import { Modal, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

function RemoveSlideModal({
  open,
  onOk,
  onCancel,
  removeModalSlide,
  selectedRowKeys,
}) {
  return (
    <Modal
      title="Remove Slide"
      closable={false}
      icon={<ExclamationCircleOutlined />}
      okText="Yes"
      okType="danger"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
    >
      {removeModalSlide ? (
        <Typography.Paragraph>
          Are you sure you want to remove {removeModalSlide.name}?
        </Typography.Paragraph>
      ) : (
        <>
          {selectedRowKeys.length ? (
            <Typography.Paragraph>
              Are you sure you want to remove {selectedRowKeys.length} slides?
            </Typography.Paragraph>
          ) : (
            ""
          )}
        </>
      )}
    </Modal>
  );
}

export default RemoveSlideModal;
