import React, { useState, useEffect } from "react";
import { Spin, Typography } from "antd";
import { API } from "aws-amplify";
import SlideViewer from "../SlideViewer/SlideViewer";
import { Auth } from "aws-amplify";

function SlideViewerContainer(props) {
  const [IsDownloaded, setIsDownloaded] = useState(false);
  const [error, setError] = useState(null);
  const [tryAgain, setTryAgain] = useState(false);

  const { Text } = Typography;

  useEffect(() => {
    const makeRequest = async () => {
      // Using Auth.currentSession() seems to ensure that the token is refreshed
      const session = await Auth.currentSession();
      const myInit = {
        headers: {
          Authorization: session.getIdToken().getJwtToken(),
        },
        response: false,
        queryStringParameters: {
          location: "slide_efs",
          path: props.path,
        },
      };

      API.get("PathLakeRESTAPI", "/slide/" + props.name, myInit)
        .then(() => {
          setIsDownloaded(true);
        })
        .catch((error) => {
          if (error.response.status === 504) {
            setTryAgain(!tryAgain);
          } else {
            setError(error.response.data);
            setIsDownloaded(true);
          }
        });
    };
    if (props.name && props.path) {
      makeRequest();
    }
  }, [props.name, props.path, tryAgain]);

  if (error) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          flexDirection: "column",
          alignContent: "center",
        }}
      >
        <Text type="secondary">
          Oh no! Something went wrong while downloading the slide... Please try
          again.
        </Text>
      </div>
    );
  } else if (!IsDownloaded) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          flexDirection: "column",
          alignContent: "center",
        }}
      >
        <Spin size="large" />

        <Text type="secondary">Downloading Slide...</Text>
      </div>
    );
  } else {
    return (
      <SlideViewer
        name={props.name}
        width={props.width}
        height={props.height}
        path={props.path}
        containerHeight={props.containerHeight}
      />
    );
  }
}

export default SlideViewerContainer;
