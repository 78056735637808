import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
import { Row, Col, Form } from "antd";

import ProjectsSearchBar from "../IndexPage/ProjectsSearchBar";
import ToolBar from "./ToolBar";

function SearchBarAndFilters(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [requestState, setRequestState] = useState({
    q: searchParams.get("q"),
    name: searchParams.get("name"),
    project: searchParams.get("project"),
    dataset: searchParams.get("dataset"),
    sex: searchParams.get("sex"),
    age: searchParams.get("age")
      ? searchParams.get("age").split(",")
      : [0, 109],
    stain: searchParams.get("stain"),
    site: searchParams.get("site"),
    codes: searchParams.get("codes") ? searchParams.get("codes") : "",
    allCodes: searchParams.get("allCodes") === "true" ? true : null,
    ethnicity: searchParams.get("ethnicity"),
    caseId: searchParams.get("caseId"),
    missing: searchParams.get("missing"),
    groupBy: searchParams.get("groupBy"),
    page: searchParams.get("page"),
    limit: searchParams.get("limit"),
  });

  const [searchBarValues, setSearchBarValues] = useState({
    q: searchParams.get("q"),
  });

  const [isAgeRangeEnabled, setIsAgeRangeEnabled] = useState(
    searchParams.get("age") !== null,
  );

  const searchBarForm = React.createRef();

  useEffect(() => {
    setRequestState({
      q: searchParams.get("q"),
      name: searchParams.get("name"),
      project: searchParams.get("project"),
      dataset: searchParams.get("dataset"),
      sex: searchParams.get("sex"),
      age: searchParams.get("age")
        ? searchParams.get("age").split(",")
        : [0, 109],
      codes: searchParams.get("codes") ? searchParams.get("codes") : "",
      allCodes: searchParams.get("allCodes"),
      stain: searchParams.get("stain"),
      site: searchParams.get("site"),
      ethnicity: searchParams.get("ethnicity"),
      caseId: searchParams.get("caseId"),
      missing: searchParams.get("missing"),
      groupBy: searchParams.get("groupBy"),
      page: searchParams.get("page"),
      limit: searchParams.get("limit"),
    });
    setSearchBarValues({ q: searchParams.get("q") });
    searchBarForm.current.setFieldsValue({ q: searchParams.get("q") });
  }, [searchParams]);

  useEffect(() => {
    setSearchParams(
      Object.fromEntries(
        Object.entries(requestState)
          .filter(
            ([k, v]) => v !== null && v !== "" && v !== undefined && v !== [],
          )
          .filter(([k, v]) => !(k === "age" && !isAgeRangeEnabled))
          .map((v, _) => {
            return Array.isArray(v[1]) ? [v[0], v[1].join()] : v;
          }),
      ),
    );
    setSearchBarValues({ q: searchParams.get("q") });
    searchBarForm.current.setFieldsValue({ q: searchParams.get("q") });
  }, [props.page, props.limit]);

  useEffect(() => {
    props.setIsLoading(true);

    let query = {};
    if (requestState.q) {
      query["q"] = requestState.q;
    }
    if (requestState.name) {
      query["name"] = requestState.name;
    }
    if (requestState.project) {
      query["project"] = requestState.project;
    }
    if (requestState.dataset) {
      query["dataset"] = requestState.dataset;
    }
    if (requestState.sex) {
      query["sex"] = requestState.sex;
    }
    if (requestState.age && isAgeRangeEnabled) {
      query["age"] = requestState.age.join();
    }
    if (requestState.codes) {
      query["codes"] = requestState.codes;
    }
    if (requestState.allCodes) {
      query["codesMode"] = "all";
    }
    if (requestState.stain) {
      query["stain"] = requestState.stain;
    }
    if (requestState.site) {
      query["tissue_type"] = requestState.site;
    }
    if (requestState.ethnicity) {
      query["ethnicity"] = requestState.ethnicity;
    }
    if (requestState.caseId) {
      query["case_id"] = requestState.caseId;
    }
    if (requestState.missing) {
      query["missing"] = requestState.missing;
    }
    query["page"] = requestState.page ? requestState.page : 0;
    query["limit"] = requestState.limit ? requestState.limit : 30;

    const makeRequest = async () => {
      // Using Auth.currentSession() seems to ensure that the token is refreshed
      const session = await Auth.currentSession();

      const myInit = {
        headers: {
          Authorization: session.getIdToken().getJwtToken(),
        },
        response: false, //Only want response body and not the whole Axiom object
        queryStringParameters: query,
      };

      if (requestState.groupBy) {
        API.get("PathLakeRESTAPI", "/cases", myInit)
          .then((response) => {
            props.setDocs(response["cases"]);
            props.setNoOfResults(response["total"]);
            props.setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            props.setError({
              data: error.response.data,
              code: error.response.status,
            });
            props.setIsLoading(false);
          });
      } else {
        API.get("PathLakeRESTAPI", "/metadata", myInit)
          .then((response) => {
            props.setDocs(response["slides"]);
            props.setNoOfResults(response["total"]);
            props.setIsLoading(false);
          })
          .catch((error) => {
            props.setError({
              data: error.response.data,
              code: error.response.status,
            });
            props.setIsLoading(false);
            props.setDocs([]);
          });
      }
    };
    makeRequest();
  }, [requestState]);

  const search = () => {
    setSearchParams(
      Object.fromEntries(
        Object.entries({ ...requestState, q: searchBarValues.q })
          .filter(([k, v]) => v !== null && v !== "" && v !== undefined)
          .filter(([k, v]) => !(k === "age" && !isAgeRangeEnabled))
          .filter(([k, v]) => !(k === "page"))
          .map((v, _) => {
            return Array.isArray(v[1]) ? [v[0], v[1].join()] : v;
          }),
      ),
    );
    props.clearSelectedSlides();
  };

  return (
    <>
      <Row style={{ width: "100%" }} justify="center">
        <Col span={12} xs={20}>
          <Form
            ref={searchBarForm}
            initialValues={searchBarValues}
            onValuesChange={(_, allValues) => {
              setSearchBarValues(allValues);
            }}
            onFinish={search}
          >
            <ProjectsSearchBar
              showSearchFilter={true}
              onSearch={search}
              onOpenFilters={() => {
                props.setFiltersOpen(true);
              }}
            />
          </Form>
        </Col>
        <Col span={12} xs={20}>
          <ToolBar
            selectedSlides={props.setNoOfResults}
            clearSelected={props.clearSelectedSlides}
            requestState={requestState}
            isAgeRangeEnabled={isAgeRangeEnabled}
            noOfResults={props.noOfResults}
            isLoading={props.isLoading}
            search={(groupBy) => {
              setRequestState({ ...requestState, groupBy: groupBy });
              searchBarForm.current.submit();
            }}
            setInSelectionMode={props.setInSelectionMode}
            setSelectedBasket={props.setSelectedBasket}
          />
        </Col>
      </Row>
    </>
  );
}
export default SearchBarAndFilters;
