import React, { useState } from "react";
import { Card, Button, Col, Row, Tooltip, Tag, Typography } from "antd";
import "antd/dist/reset.css";
import "./Tile.less";
import MetadataModal from "./MetadataModal";
import Thumbnail from "./Thumbnail";
import {
  CheckOutlined,
  LineOutlined,
  ProfileOutlined,
  FileMarkdownOutlined,
  FileImageOutlined,
  HighlightOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const { Text } = Typography;

function Tile(props) {
  const navigate = useNavigate();
  const [metadataVisible, setMetadataVisible] = useState(false);

  const showMetadata = () => {
    setMetadataVisible(true);
  };

  const goToCase = (caseId) => {
    navigate(`/search?caseId=${caseId}`);
  };

  const toggleSelected = () => {
    if (props.isSelected === true) {
      props.removeSelectedSlide(props.doc._id);
    } else {
      props.addSelectedSlide(props.doc._id);
    }
  };

  const CheckBox = () => {
    let icon;
    let type;
    if (props.isSelected === true) {
      icon = <CheckOutlined />;
      type = "primary";
      return <Button type={type} onClick={toggleSelected} icon={icon} />;
    } else {
      icon = <LineOutlined />;
      type = "default";
      return (
        <Button
          type={type}
          onClick={toggleSelected}
          icon={icon}
          style={{ backgroundColor: "white" }}
        />
      );
    }
  };

  return (
    <>
      <MetadataModal
        doc={props.doc}
        open={metadataVisible}
        closeCallback={() => setMetadataVisible(false)}
      />
      <Card
        className="slide-tile"
        hoverable
        cover={
          <>
            <Row className="buttons" style={{ maxWidth: "80%" }}>
              {props.inSelectionMode && (
                <Col span={24}>
                  <CheckBox />
                </Col>
              )}
              <Col span={24} className="hides">
                <Button
                  onClick={showMetadata}
                  style={{ backgroundColor: "white" }}
                >
                  <Tooltip title="View Metadata">
                    <FileMarkdownOutlined /> Metadata
                  </Tooltip>
                </Button>
              </Col>
              <Col span={24} className="hides">
                <Button
                  onClick={() => goToCase(props.doc.case_id)}
                  style={{ backgroundColor: "white" }}
                >
                  <Tooltip title="View Case">
                    <ProfileOutlined /> Case
                  </Tooltip>
                </Button>
              </Col>
            </Row>
            <Row className="slide-marker">
              <div>
                {props.doc.annotations && (
                  <Tooltip title="Annotations Available">
                    <HighlightOutlined
                      style={{ fontSize: "32px", color: "#582475" }}
                    />
                  </Tooltip>
                )}
                {props.doc.file_path && props.doc.file_path !== "" && (
                  <Tooltip title="Whole Slide Available">
                    <FileImageOutlined
                      style={{ fontSize: "32px", color: "#582475" }}
                    />
                  </Tooltip>
                )}
              </div>
            </Row>
            <div className="tags">
              <Row justify="end" align="bottom" gutter={[4, 4]}>
                {props.doc.procedure && props.doc.procedure.type && (
                  <Col>
                    <Tag className="slide-tag">
                      <Tooltip title="Procedure">
                        <Text copyable ellipsis>
                          {props.doc.procedure.type}
                        </Text>
                      </Tooltip>
                    </Tag>
                  </Col>
                )}
                {props.doc.stain && props.doc.stain.name && (
                  <Col>
                    <Tag className="slide-tag">
                      <Tooltip title="Stain Name">
                        <Text copyable ellipsis>
                          {props.doc.stain.name}
                        </Text>
                      </Tooltip>
                    </Tag>
                  </Col>
                )}
                {props.doc.tissue_type && (
                  <Col>
                    <Tag className="slide-tag">
                      <Tooltip title="Tissue Type">
                        <Text copyable>{props.doc.tissue_type}</Text>
                      </Tooltip>
                    </Tag>
                  </Col>
                )}{" "}
                {props.doc.sex && (
                  <Col>
                    <Tag className="slide-tag">
                      <Tooltip title="Patient Sex">
                        <Text>{props.doc.sex}</Text>
                      </Tooltip>
                    </Tag>
                  </Col>
                )}
                {props.doc.age_range && (
                  <Col>
                    <Tag className="slide-tag">
                      <Tooltip title="Patient Age">
                        <Text>
                          {props.doc.age_range.lower} -{" "}
                          {props.doc.age_range.upper}
                        </Text>
                      </Tooltip>
                    </Tag>
                  </Col>
                )}
              </Row>
            </div>
            <Thumbnail
              src={props.doc._presigned_thumbnail}
              allowPreview={!props.doc.file_path || props.doc.file_path === ""}
              showSlideViewerCallback={() => {
                props.showSlideViewerCallback(props.doc);
              }}
            />
          </>
        }
      ></Card>
    </>
  );
}

export default Tile;
