import "antd/dist/reset.css";
import "./Folder.less";
import { Card, Typography, Row, Col, Tooltip } from "antd";
import {
  SearchOutlined,
  TagsOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
const { Text } = Typography;

function Folder(props) {
  return (
    <div class="folder">
      <Card
        title={
          <Text copyable ellipsis>
            {props.doc.key}
          </Text>
        }
        bordered={false}
        actions={[
          <Tooltip
            title={
              props.doc.doc_count === 1
                ? "1 Slide"
                : props.doc.doc_count + " Slides"
            }
          >
            <Text>
              <TagsOutlined /> {props.doc.doc_count}
            </Text>
          </Tooltip>,
          <SearchOutlined
            onClick={() => props.searchCaseSlidesCallback(props.doc)}
          />,
        ]}
      >
        <Row align="middle" justify="center">
          <Col style={{ fontSize: "80px" }}>
            <ContainerOutlined />
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default Folder;
