import React from "react";
import { Image } from "antd";

function Thumbnail(props) {
  return (
    <>
      <Image
        onClick={props.showSlideViewerCallback}
        style={{
          width: "100%",
          height: "300px",
          objectFit: "cover",
          objectPosition: "50% 50%",
        }}
        preview={props.allowPreview}
        src={props.src}
        placeholder={true}
        loading="lazy"
      ></Image>
    </>
  );
}

export default Thumbnail;
