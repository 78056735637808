import React, { useEffect } from "react";
import { useAuth } from "../auth/AuthProvider";
import { Empty, Layout, Col, Row } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import DefaultHeader from "../layout/DefaultHeader";
import DefaultFooter from "../layout/DefaultFooter";
const { Content } = Layout;

function NotFoundPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { loaded, onLoad } = useAuth();

  useEffect(() => {
    if (!loaded) {
      onLoad();
    }
  }, [loaded, onLoad]);

  useEffect(() => {
    document.title = "PathLAKE - Page Not Found";
    if (location.pathname !== "/404" && location.pathname !== "/login") {
      navigate("/404");
    }
  }, [location, navigate]);

  return (
    <Layout>
      <DefaultHeader />
      <Content>
        <Row style={{ minHeight: "50vh" }} align="middle" justify="center">
          <Col>
            <Empty description="Page Not Found" />
          </Col>
        </Row>
      </Content>
      <DefaultFooter />
    </Layout>
  );
}

export default NotFoundPage;
