import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import {
  Row,
  Col,
  Modal,
  Card,
  Button,
  Form,
  Input,
  Space,
  Segmented,
} from "antd";
import {
  PlusCircleOutlined,
  ClockCircleOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  AppstoreOutlined,
  BarsOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

function BasketsModal(props) {
  const [selected, setSelected] = useState(null);
  const [createMode, setCreateMode] = useState(false);
  const [baskets, setBaskets] = useState([]);
  const [sortDirection, setSortDirection] = useState("acs");
  const [sortField, setSortField] = useState("created");
  const createBasketForm = React.createRef();

  const handleCreate = async () => {
    const session = await Auth.currentSession();
    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      response: false,
      body: {
        name: createBasketForm.current.getFieldValue("name"),
        slides: [],
      },
    };
    API.post("PathLakeRESTAPI", `/baskets/`, myInit)
      .then((response) => {
        // This forces the state to update each time a new basket is created
        props.setBasketsLastUpdated(new Date());
        setCreateMode(false);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const fetchData = async () => {
      let query = {};
      query["limit"] = 20;
      const session = await Auth.currentSession();
      const myInit = {
        headers: {
          Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
        },
        response: false, //Only want response body and not the whole Axiom object
        queryStringParameters: query,
      };
      API.get("PathLakeRESTAPI", "/baskets", myInit)
        .then((response) => {
          setBaskets(response["baskets"]);

          response["baskets"].map((basket) => {
            if (selected !== null && selected.id === basket.id) {
              setSelected(basket);
              props.setSelectedBasket(basket);
              props.setSelectedSlides(new Set(basket.slides));
            }
          });
        })
        .catch((error) => {});
    };
    fetchData();
  }, [props.basketsLastUpdated]);
  return (
    <Modal
      title="Your Baskets"
      open={props.isOpen}
      onOk={() => {
        props.setIsOpen(false);
        props.setSelectedBasket(selected);
        if (!selected?.manifest) {
          if (selected) {
            props.setInSelectionMode(true);
            props.setSelectedSlides(new Set(selected.slides));
          } else {
            props.setSelectedSlides(new Set());
            props.setInSelectionMode(false);
          }
        } else {
          props.setInSelectionMode(false);
        }
      }}
      onCancel={() => {
        setCreateMode(false);
        props.setIsOpen(false);
      }}
      destroyOnClose={true}
      width={900}
    >
      <Row gutter={[16, 16]} style={{ maxHeight: "70vh", overflowY: "scroll" }}>
        <Col span={24}>
          <Segmented
            defaultValue={sortField}
            onChange={(value) => {
              setSortField(value);
            }}
            options={[
              {
                key: "created",
                label: "Created",
                value: "created",
                icon: <BarsOutlined />,
              },
              {
                key: "name",
                label: "Name",
                value: "name",
                icon: <AppstoreOutlined />,
              },
              {
                key: "size",
                label: "Size",
                value: "size",
                icon: <AppstoreOutlined />,
              },
            ]}
          />
          <Segmented
            defaultValue={sortDirection}
            onChange={(value) => {
              setSortDirection(value);
            }}
            options={[
              {
                key: "acs",
                value: "acs",
                icon: <SortAscendingOutlined />,
              },
              {
                key: "desc",
                value: "desc",
                icon: <SortDescendingOutlined />,
              },
            ]}
          />
        </Col>
        {baskets
          .sort((a, b) => {
            var comA;
            var comB;
            if (sortField === "size") {
              comA = a.slides.length;
              comB = b.slides.length;
            } else if (sortField === "name") {
              comA = a.name;
              comB = b.name;
            } else if (sortField === "created") {
              comA = a.created_at;
              comB = b.created_at;
            }
            if (sortDirection === "acs") {
              return comA > comB;
            } else {
              return comA < comB;
            }
          })
          .map((basket) => {
            const isSelected = selected && selected.id === basket.id;
            return (
              <Col key={basket.id} span={24}>
                <Card
                  hoverable={!isSelected}
                  style={
                    isSelected
                      ? { backgroundColor: "#582475", color: "white" }
                      : {}
                  }
                  onClick={() => {
                    if (selected !== null && selected.id === basket.id) {
                      setSelected(null);
                    } else {
                      setSelected(basket);
                    }
                  }}
                >
                  <Row>
                    <Col span={18}>
                      <Space>
                        <p>{basket.name}</p>
                        <p>{basket.slides.length} WSIs</p>

                        <p>{dayjs(basket.created_at).format("D MMM YYYY")}</p>
                      </Space>
                    </Col>
                    {basket.manifest &&
                      (!("is_approved" in basket.manifest) ||
                        basket.manifest.is_approved !== true) && (
                        <Col span={6}>
                          <ClockCircleOutlined style={{ fontSize: "1em" }} />{" "}
                          Pending
                        </Col>
                      )}
                    {basket.manifest && basket.manifest.is_approved && (
                      <Col span={6}>
                        <CheckCircleOutlined style={{ fontSize: "1em" }} />{" "}
                        Approved
                      </Col>
                    )}
                  </Row>
                </Card>
              </Col>
            );
          })}

        <Col span={24}>
          <Card
            hoverable
            onClick={() => {
              if (!createMode) {
                setCreateMode(true);
                createBasketForm.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }
            }}
          >
            <Row>
              <Col>
                <PlusCircleOutlined style={{ fontSize: "2em" }} /> Create New
                Basket
              </Col>
              {createMode && (
                <Col offset={2}>
                  <Form ref={createBasketForm}>
                    <Form.Item name="name">
                      <Input placeholder="BasketName" autoComplete="off" />
                    </Form.Item>
                    <Form.Item>
                      <Space>
                        <Button
                          onClick={() => {
                            setCreateMode(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button type="primary" onClick={handleCreate}>
                          Create
                        </Button>
                      </Space>
                    </Form.Item>
                  </Form>
                </Col>
              )}
            </Row>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
}

export default BasketsModal;
