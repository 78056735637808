import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import dayjs from "dayjs";
import { Card, Table, Typography, Button, Modal, Skeleton } from "antd";
import { API } from "aws-amplify";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import CommonAlert from "../Core/CommonAlert";

function ListManifests({ user }, props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  const [manifests, setManifests] = useState([]);
  const [selectedManifest, setSelectedManifest] = useState(null);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      render: (name, item) => <a href={`/manifests/${item.id}`}>{name}</a>,
    },
    {
      title: "User",
      dataIndex: "user_id",
      key: "user_id",
      fixed: "left",
      render: (user_id, item) =>
        "created_by" in item ? (
          <a href={`/users/${item.created_by}`}>{item.created_by}</a>
        ) : (
          user_id
        ),
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (time) => <>{dayjs(time).format("YYYY MMM D")}</>,
      sorter: (a, b) => dayjs(a.created).diff(dayjs(b.created)),
    },
    {
      title: "",
      key: "action",
      render: (item) => (
        <>
          {"is_approved" in item && item.is_approved ? (
            <>Approved by: {item.approved_by}</>
          ) : (
            <Button
              danger
              onClick={() => {
                setSelectedManifest(item);
              }}
            >
              Approve
            </Button>
          )}
        </>
      ),
    },
  ];

  const fetchData = async () => {
    const session = await Auth.currentSession();

    let query = {};

    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      queryStringParameters: query,
      response: false, //Only want response body and not the whole Axiom object
    };

    API.get("PathLakeRESTAPI", `/manifests/`, myInit)
      .then((response) => {
        setManifests(response["manifests"]);
        setIsLoaded(true);
      })
      .catch((error) => {
        setIsLoaded(true);
        setError({ data: error.response.data, code: error.response.status });
      });
  };

  const approveManifest = async () => {
    const session = await Auth.currentSession();

    let query = { approve: true };

    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      queryStringParameters: query,
      response: false, //Only want response body and not the whole Axiom object
    };

    API.put("PathLakeRESTAPI", `/manifests/${selectedManifest.id}`, myInit)
      .then((response) => {
        setSelectedManifest(null);
        fetchData();
      })
      .catch((error) => {
        setError({ data: error.response.data, code: error.response.status });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Card>
        <Typography.Title>Manifests</Typography.Title>
        {error && <CommonAlert data={error.data} code={error.code} />}
        {isLoaded && (
          <Table
            dataSource={manifests}
            columns={columns}
            scroll={{ x: 1200 }}
            pagination={false}
          />
        )}
        {!isLoaded && <Skeleton active />}
      </Card>
      <Modal
        title="Approve Manifest"
        closable={false}
        icon={<ExclamationCircleOutlined />}
        open={selectedManifest !== null}
        onCancel={() => {
          setSelectedManifest(null);
        }}
        onOk={approveManifest}
        okText="Approve"
      >
        <Typography>
          <Typography.Paragraph>
            Mark this manifest as approved. The basket owner will be able to see
            that this action has been approved.
          </Typography.Paragraph>
          <>
            {selectedManifest && (
              <>
                <Typography.Paragraph>
                  {selectedManifest.name}
                </Typography.Paragraph>
                <Typography.Paragraph>
                  {"username" in selectedManifest
                    ? selectedManifest.username
                    : selectedManifest.user_id}
                </Typography.Paragraph>
                <Typography.Paragraph>
                  {dayjs(selectedManifest.date).format("YYYY MMM D")}
                </Typography.Paragraph>
              </>
            )}
          </>
          <Typography.Paragraph>
            <Typography.Text type="danger">
              Are you sure you want to perform this action?
            </Typography.Text>
          </Typography.Paragraph>
        </Typography>
      </Modal>
    </>
  );
}

export default ListManifests;
