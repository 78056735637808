const awsconfig = {
  Auth: {
    region: `${process.env.REACT_APP_REGION}`,
    userPoolId: `${process.env.REACT_APP_USER_POOL_ID}`,
    userPoolWebClientId: `${process.env.REACT_APP_USER_POOL_CLIENT_ID}`,
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
  API: {
    endpoints: [
      {
        name: "PathLakeRESTAPI",
        endpoint: `${process.env.REACT_APP_API_ENDPOINT}`,
      },
    ],
  },
};

export default awsconfig;
