import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
import { useSearchParams } from "react-router-dom";
import BasketWidget from "./BasketWidget";
import "@aws-amplify/ui-react/styles.css";
import { useMediaQuery } from "react-responsive";
import {
  Row,
  Col,
  Tag,
  Space,
  Collapse,
  Form,
  Input,
  Switch,
  Select,
  Slider,
  Tooltip,
  Button,
  Drawer,
  Divider,
} from "antd";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";

import "antd/dist/reset.css";

const { Option } = Select;

function AgeFilters(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAgeRangeEnabled, setIsAgeRangeEnabled] = useState(
    searchParams.get("age") ? true : false,
  );
  return (
    <Row>
      <Col span={24}>
        <Form.Item name="age" style={{ width: "100%", marginRight: 0 }}>
          <Slider
            range
            step={5}
            max={110}
            min={0}
            disabled={!isAgeRangeEnabled}
          />
        </Form.Item>
      </Col>
      <Col span={4}>On</Col>
      <Col span={4}>
        <Form.Item
          name="isAgeRangeEnabled"
          style={{ width: "100%", marginRight: 0 }}
        >
          <Switch
            defaultChecked={isAgeRangeEnabled}
            onChange={() => {
              setIsAgeRangeEnabled(!isAgeRangeEnabled);
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}

function SidebarFilters(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tissues, setTissues] = useState([]);
  const [stains, setStains] = useState([]);
  const [codes, setCodes] = useState([]);
  const [formState, setFormState] = useState({
    name: searchParams.get("name"),
    project: searchParams.get("project"),
    dataset: searchParams.get("dataset"),
    sex: searchParams.get("sex"),
    age: searchParams.get("age")
      ? searchParams.get("age").split(",")
      : [0, 109],
    stain: searchParams.get("stain"),
    site: searchParams.get("site"),
    codes: searchParams.get("codes")
      ? searchParams.get("codes").split(",")
      : [],
    allCodes: searchParams.get("allCodes") === "true" ? true : null,
    ethnicity: searchParams.get("ethnicity"),
    caseId: searchParams.get("caseId"),
    missing: searchParams.get("missing"),
    groupBy: searchParams.get("groupBy"),
    page: searchParams.get("page"),
    limit: searchParams.get("limit"),
  });
  const myForm = React.createRef();

  useEffect(() => {
    setFormState({
      name: searchParams.get("name"),
      project: searchParams.get("project"),
      dataset: searchParams.get("dataset"),
      sex: searchParams.get("sex"),
      age: searchParams.get("age")
        ? searchParams.get("age").split(",")
        : [0, 109],
      stain: searchParams.get("stain"),
      site: searchParams.get("site"),
      codes: searchParams.get("codes")
        ? searchParams.get("codes").split(",")
        : [],
      allCodes: searchParams.get("allCodes") === "true" ? true : null,
      ethnicity: searchParams.get("ethnicity"),
      caseId: searchParams.get("caseId"),
      missing: searchParams.get("missing"),
      groupBy: searchParams.get("groupBy"),
      page: searchParams.get("page"),
      limit: searchParams.get("limit"),
    });
  }, [searchParams]);

  useEffect(() => {
    if (myForm.current) {
      myForm.current.resetFields();
    }
  }, [formState]);

  const sortDocs = (a, b) => {
    return a["doc_count"] < b["doc_count"] ? 1 : -1;
  };

  const fetchData = async () => {
    const session = await Auth.currentSession();

    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      response: false, //Only want response body and not the whole Axiom object
    };

    if (!props.isLoading) {
      API.get("PathLakeRESTAPI", `/tissues/`, myInit)
        .then((response) => {
          setTissues(
            response["tissues"].sort(sortDocs).map((obj) => {
              return { label: obj.key, value: obj.key };
            }),
          );
        })
        .catch((error) => {});

      API.get("PathLakeRESTAPI", `/stains/`, myInit)
        .then((response) => {
          setStains(
            response["stains"].sort(sortDocs).map((obj) => {
              return { label: obj.key, value: obj.key };
            }),
          );
        })
        .catch((error) => {});

      API.get("PathLakeRESTAPI", `/diagnoses/`, myInit)
        .then((response) => {
          setCodes(
            response["diagnoses"].sort(sortDocs).map((obj) => {
              return { label: obj.key, value: obj.key };
            }),
          );
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.isLoading]);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const search = (values) => {
    setSearchParams(
      Object.fromEntries(
        Object.entries({ ...values, q: searchParams.get("q") })
          .filter(([k, v]) => v !== null && v !== "" && v !== undefined)
          .filter(([k, v]) => !(k === "page"))
          .map((v, _) => {
            return Array.isArray(v[1]) ? [v[0], v[1].join()] : v;
          }),
      ),
    );
    props.clearSelectedSlides();
  };

  const handleCloseTag = (tag) => {
    var temp = { ...formState };
    temp[tag[0]] = null;
    setFormState(temp);
    props.clearSelectedSlides();
    search(temp);
  };

  const FiltersPane = () => {
    return (
      <Col span={24}>
        <Form
          ref={myForm}
          initialValues={formState}
          onFinish={(values) => {
            if (!values.isAgeRangeEnabled) {
              values.age = null;
            }
            delete values["isAgeRangeEnabled"];
            search(values);
            setFormState(values);
          }}
        >
          <Collapse
            ghost
            expandIcon={({ isActive }) => (
              <PlusOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Collapse.Panel
              header="Name"
              key="1"
              extra={
                <Tooltip placement="right" title={"Slide Name"}>
                  <InfoCircleOutlined />
                </Tooltip>
              }
              expandIcon={<PlusOutlined />}
              forceRender={true}
            >
              <Form.Item name="name">
                <Input
                  placeholder="Name"
                  allowClear={true}
                  autoComplete="off"
                />
              </Form.Item>
            </Collapse.Panel>
            <Collapse.Panel
              header="Project"
              key="2"
              extra={
                <Tooltip
                  placement="right"
                  title={"Project Name (e.g. PathLAKE)"}
                >
                  <InfoCircleOutlined />
                </Tooltip>
              }
              forceRender={true}
            >
              <Form.Item name="project">
                <Input
                  placeholder="Project"
                  allowClear={true}
                  autoComplete="off"
                />
              </Form.Item>
            </Collapse.Panel>
            <Collapse.Panel
              header="Dataset"
              key="3"
              extra={
                <Tooltip placement="right" title={"Dataset (e.g. Skin)"}>
                  <InfoCircleOutlined />
                </Tooltip>
              }
              forceRender={true}
            >
              <Form.Item name="dataset">
                <Input
                  placeholder="Dataset"
                  allowClear={true}
                  autoComplete="off"
                />
              </Form.Item>
            </Collapse.Panel>
            <Collapse.Panel
              header="Sex"
              key="4"
              extra={
                <Tooltip placement="right" title={"Sex (Male or Female)"}>
                  <InfoCircleOutlined />
                </Tooltip>
              }
              forceRender={true}
            >
              <Form.Item name="sex">
                <Select allowClear={true}>
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                </Select>
              </Form.Item>
            </Collapse.Panel>
            <Collapse.Panel
              header="Age Range"
              key="5"
              extra={
                <Tooltip placement="right" title={"Age Range"}>
                  <InfoCircleOutlined />
                </Tooltip>
              }
              forceRender={true}
            >
              <AgeFilters />
            </Collapse.Panel>
            <Collapse.Panel
              header="Diagnosis Codes"
              key="6"
              extra={
                <Tooltip
                  placement="right"
                  title={
                    "Diagnosis Codes (e.g. SNOMED or SNOMED_CT codes). Note: Not all slides have diagnosis codes"
                  }
                >
                  <InfoCircleOutlined />
                </Tooltip>
              }
              forceRender={true}
            >
              <Row>
                <Col span={24}>
                  <Form.Item name="codes">
                    <Select
                      placeholder="Codes"
                      allowClear={true}
                      autoComplete="off"
                      mode="tags"
                      tokenSeparators={[","]}
                      options={codes}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>All</Col>
                <Col span={4}>
                  <Form.Item name="allCodes">
                    <Switch defaultChecked={formState.allCodes} />
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
            <Collapse.Panel
              header="Stain"
              key="7"
              extra={
                <Tooltip
                  placement="right"
                  title={"Stain Name or Type (e.g. HE, IHC)"}
                >
                  <InfoCircleOutlined />
                </Tooltip>
              }
              forceRender={true}
            >
              <Form.Item name="stain">
                <Select
                  showSearch
                  placeholder="Stain"
                  allowClear={true}
                  autoComplete="off"
                  options={stains}
                />
              </Form.Item>
            </Collapse.Panel>
            <Collapse.Panel
              header="Tissue"
              key="8"
              extra={
                <Tooltip placement="right" title={"Primary Site"}>
                  <InfoCircleOutlined />
                </Tooltip>
              }
              forceRender={true}
            >
              <Form.Item name="site">
                <Select
                  showSearch
                  placeholder="Tissue"
                  allowClear={true}
                  autoComplete="off"
                  options={tissues}
                />
              </Form.Item>
            </Collapse.Panel>
            <Collapse.Panel
              header="Ethnicity"
              key="9"
              extra={
                <Tooltip placement="right" title={"Ethnicity"}>
                  <InfoCircleOutlined />
                </Tooltip>
              }
              forceRender={true}
            >
              <Form.Item name="ethnicity">
                <Input
                  placeholder="Ethnicity"
                  allowClear={true}
                  autoComplete="off"
                />
              </Form.Item>
            </Collapse.Panel>
            <Collapse.Panel
              header="Case"
              key="10"
              extra={
                <Tooltip placement="right" title={"Case ID"}>
                  <InfoCircleOutlined />
                </Tooltip>
              }
              forceRender={true}
            >
              <Form.Item name="caseId">
                <Input
                  placeholder="Case ID"
                  allowClear={true}
                  autoComplete="off"
                />
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
          <Row align="end" style={{ margin: 16 }}>
            <Col>
              <Button type="primary" htmlType="submit">
                Apply
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    );
  };
  return (
    <Row justify={"center"}>
      {formState.groupBy !== "GroupByCase" && (
        <Col span={24}>
          <Divider>Baskets</Divider>
          <BasketWidget
            openBaskets={props.openBaskets}
            selectedBasket={props.selectedBasket}
            toggleInSelectionMode={props.toggleInSelectionMode}
            searchParams={props.searchParams}
            selectedSlides={props.selectedSlides}
            setSelectedBasket={props.setSelectedBasket}
            basketsLastUpdated={props.basketsLastUpdated}
            setBasketsLastUpdated={props.setBasketsLastUpdated}
            noOfResults={props.noOfResults}
          />
        </Col>
      )}

      <Col span={24}>
        <Divider>Filters</Divider>
        <Space align="center" wrap style={{ margin: 32 }}>
          {Object.entries(formState).map((item) => {
            var key = item[0];
            var value = item[1];
            if (item[1] !== null) {
              if (item[0] === "age") {
                value = item[1][0] + " - " + item[1][1];
              }
              if (item[0] === "codes" && Array.isArray(item[1])) {
                value = item[1].join(", ");
              }
              if (
                item[0] !== "q" &&
                item[0] !== "groupBy" &&
                item[0] !== "page" &&
                item[0] !== "limit" &&
                item[0] !== "allCodes" &&
                item[1] !== [] &&
                item[1] !== "" &&
                !(item[0] === "codes" && item[1].length === 0)
              ) {
                return (
                  <Tooltip placement="right" title={value}>
                    <Tag
                      closable
                      onClose={(e) => {
                        e.preventDefault();
                        handleCloseTag(item);
                      }}
                    >
                      {key}:{" "}
                      {typeof value === "string" || value instanceof String
                        ? value.substring(0, 15)
                        : value}
                      {value.length > 15 ? "..." : ""}
                    </Tag>
                  </Tooltip>
                );
              }
            }
          })}
        </Space>
      </Col>

      {!isTabletOrMobile && <FiltersPane />}
      {isTabletOrMobile && (
        <Drawer open={props.open} width="100%" onClose={props.onClose}>
          <FiltersPane />
        </Drawer>
      )}
    </Row>
  );
}

export default SidebarFilters;
