import React, { useState, useEffect } from "react";
import { Col, Divider, Drawer, Row } from "antd";
import { Auth } from "aws-amplify";

import "./ProfileDrawer.less";

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

function ProfileDrawer(props) {
  const [user, setUser] = useState({ attributes: false });
  const updateUser = async () => {
    try {
      setUser(await Auth.currentAuthenticatedUser());
    } catch (error) {
      setUser(null);
    }
  };
  useEffect(() => {
    updateUser();
  }, []);
  return (
    <>
      <Drawer
        width={640}
        placement="right"
        closable={true}
        onClose={props.onClose}
        open={props.open}
      >
        <p
          className="site-description-item-profile-p"
          style={{
            marginBottom: 24,
          }}
        >
          Profile
        </p>
        <Row>
          <Col span={24} xl={12}>
            <DescriptionItem
              title="Given Name"
              content={user ? user.attributes.given_name : ""}
            />
          </Col>
          <Col span={24} xl={12}>
            <DescriptionItem
              title="Surname"
              content={user ? user.attributes.family_name : ""}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} xl={12}>
            <DescriptionItem
              title="Username"
              content={user ? user.username : ""}
            />
          </Col>
          <Col span={24} xl={12}>
            <DescriptionItem
              title="Email"
              content={user ? user.attributes.email : ""}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Organisation"
              content={user ? user.attributes["custom:organisation"] : ""}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Role"
              content={user ? user.attributes["custom:role"] : ""}
            />
          </Col>
        </Row>
        <Divider />
      </Drawer>
    </>
  );
}

export default ProfileDrawer;
