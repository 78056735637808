import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import dayjs from "dayjs";

import DefaultHeader from "../layout/DefaultHeader";
import DefaultFooter from "../layout/DefaultFooter";
import AdminSider from "../components/AdminPage/AdminSider";
import {
  Layout,
  Form,
  Typography,
  Input,
  Button,
  Select,
  Card,
  Breadcrumb,
  Space,
} from "antd";
import CommonAlert from "../components/Core/CommonAlert";

import { useParams } from "react-router-dom";
const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

function UserPage(props) {
  const [userObj, setUserObj] = useState({});
  const { username } = useParams();
  const [error, setError] = useState(null);
  const [hasSuccess, setSuccess] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const myForm = React.createRef();

  useEffect(() => {
    document.title = "PathLAKE - User";
    async function fetchData() {
      const session = await Auth.currentSession();

      const myInit = {
        headers: {
          Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
        },
        response: false, //Only want response body and not the whole Axiom object
      };
      API.get("PathLakeRESTAPI", `users/${username}`, myInit)
        .then((response) => {
          setUserObj(response);
        })
        .catch((error) => {
          setError({ data: error.response.data, code: error.response.status });
        });
    }
    fetchData();
  }, [username]);

  const updateUser = async (values) => {
    setIsUpdating(true);
    const session = await Auth.currentSession();

    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      response: false, //Only want response body and not the whole Axiom object
      body: {
        username: username,
        email: values.email,
        given_name: values.given_name,
        family_name: values.family_name,
        role: values.role,
        organisation: values.organisation,
      },
    };
    API.put("PathLakeRESTAPI", `users/${username}`, myInit)
      .then(() => {
        setIsUpdating(false);

        setSuccess(true);
        setIsEditMode(false);
      })
      .catch((error) => {
        setIsUpdating(false);

        setSuccess(false);
        setError({ data: error.response.data, code: error.response.status });
      });
  };

  const normaliseUser = async (values) => {
    const session = await Auth.currentSession();

    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      response: false, //Only want response body and not the whole Axiom object
      body: { username: username },
    };

    API.put("PathLakeRESTAPI", `users/${username}`, myInit)
      .then((response) => {
        setSuccess(true);
      })
      .catch((error) => {
        setSuccess(false);
        setError({ data: error.response.data, code: error.response.status });
      });
  };

  const disableUser = async () => {
    const session = await Auth.currentSession();

    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      response: false, //Only want response body and not the whole Axiom object
      body: { username: username, enabled: false },
    };

    API.put("PathLakeRESTAPI", `users/${username}`, myInit)
      .then((response) => {
        setSuccess(true);
        setUserObj(
          Object.fromEntries(
            Object.entries({
              ...userObj,
              enabled: false,
            }),
          ),
        );
      })
      .catch((error) => {
        setSuccess(false);
        setError({ data: error.response.data, code: error.response.status });
      });
  };

  const enableUser = async () => {
    const session = await Auth.currentSession();

    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      response: false, //Only want response body and not the whole Axiom object
      body: { username: username, enabled: true },
    };

    API.put("PathLakeRESTAPI", `users/${username}`, myInit)
      .then(() => {
        setSuccess(true);
        setUserObj(
          Object.fromEntries(
            Object.entries({
              ...userObj,
              enabled: true,
            }),
          ),
        );
      })
      .catch((error) => {
        setSuccess(false);
        setError({ data: error.response.data, code: error.response.status });
      });
  };

  const confirmUser = async () => {
    const session = await Auth.currentSession();

    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      response: false, //Only want response body and not the whole Axiom object
      queryStringParameters: { confirmed: true },
      body: { username: username },
    };

    API.put("PathLakeRESTAPI", `users/${username}`, myInit)
      .then(() => {
        setSuccess(true);
        setUserObj(
          Object.fromEntries(
            Object.entries({
              ...userObj,
              status: "CONFIRMED",
            }),
          ),
        );
      })
      .catch((error) => {
        setSuccess(false);
        setError({ data: error.response.data, code: error.response.status });
      });
  };

  return (
    <Layout>
      <DefaultHeader />
      <Layout>
        <AdminSider />
        <Content>
          <Card>
            <Breadcrumb>
              <Breadcrumb.Item>
                <a href="/admin#users">Users</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{userObj.username}</Breadcrumb.Item>
            </Breadcrumb>

            <Card
              extra={
                <Button
                  type="primary"
                  disabled={isEditMode}
                  onClick={() => setIsEditMode(true)}
                >
                  Edit
                </Button>
              }
            >
              {userObj.username && (
                <Form
                  ref={myForm}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  layout="vertical"
                  onFinish={updateUser}
                  style={{ width: "500px" }}
                  initialValues={userObj}
                >
                  {hasSuccess && <CommonAlert data="User Updated" code={200} />}
                  {error && <CommonAlert data={error.data} code={error.code} />}
                  <Title>{username}</Title>
                  <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input the username!",
                      },
                    ]}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: "Please input the email!" },
                    ]}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                  <Form.Item
                    label="First Name"
                    name="given_name"
                    rules={[
                      {
                        required: true,
                        message: "Please input the first name!",
                      },
                    ]}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                  <Form.Item
                    label="Surname"
                    name="family_name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your surname!",
                      },
                    ]}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                  <Form.Item
                    label="Role"
                    name="role"
                    rules={[
                      { required: true, message: "Please input the role!" },
                    ]}
                  >
                    <Select disabled={!isEditMode}>
                      <Option value="user">User</Option>
                      <Option value="admin">Administrator</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Organisation"
                    name="organisation"
                    rules={[
                      {
                        required: true,
                        message: "Please input the organisation!",
                      },
                    ]}
                  >
                    <Select disabled={!isEditMode}>
                      <Option value="warwick">University of Warwick</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      loading={isUpdating}
                      disabled={!isEditMode}
                      htmlType="submit"
                    >
                      Update
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Card>
          </Card>
          <Card>
            <ul>
              <li>Updated: {dayjs(userObj.updated).format("YYYY MMM D")}</li>
              <li>Created: {dayjs(userObj.created).format("YYYY MMM D")}</li>
              <li>Status: {userObj.status}</li>
              <li>Enabled: {userObj.enabled ? "ENABLED" : "DISABLED"}</li>
              <li>Normalised: {userObj.normalised ? "YES" : "NO"}</li>
            </ul>
            <Space>
              <Button
                type="primary"
                onClick={confirmUser}
                disabled={userObj.Status !== "UNCONFIRMED"}
              >
                Confirm User
              </Button>
              <Button
                type="primary"
                onClick={enableUser}
                disabled={userObj.enabled}
              >
                Enable User
              </Button>
              <Button
                type="primary"
                onClick={normaliseUser}
                disabled={userObj.normalised}
              >
                Normalise
              </Button>
              <Button
                type="primary"
                danger
                onClick={disableUser}
                disabled={!userObj.enabled}
              >
                Disable User
              </Button>
            </Space>
          </Card>
        </Content>
      </Layout>
      <DefaultFooter />
    </Layout>
  );
}

export default UserPage;
