var tissues = [
  {
    key: "Aorta",
    doc_count: 8,
  },
  {
    key: "Appendix",
    doc_count: 49,
  },
  {
    key: "Ascending colon biopsies",
    doc_count: 1,
  },
  {
    key: "Ascending colon polyp",
    doc_count: 5,
  },
  {
    key: "Bladder Bx",
    doc_count: 716,
  },
  {
    key: "Breast",
    doc_count: 15475,
  },
  {
    key: "Breast Core Bx",
    doc_count: 11280,
  },
  {
    key: "Breast Excision",
    doc_count: 738,
  },
  {
    key: "Breast Mastectomy",
    doc_count: 602,
  },
  {
    key: "Breast Punch Bx",
    doc_count: 9,
  },
  {
    key: "Breast Reduction",
    doc_count: 39,
  },
  {
    key: "Breast WLE",
    doc_count: 1233,
  },
  {
    key: "Breast biopsy",
    doc_count: 408,
  },
  {
    key: "CVS/Resp Excision",
    doc_count: 1134,
  },
  {
    key: "Caecal Bx L1",
    doc_count: 1,
  },
  {
    key: "Caecal biopsies",
    doc_count: 2,
  },
  {
    key: "Caecal polyp",
    doc_count: 2,
  },
  {
    key: "Cervical Bx",
    doc_count: 89,
  },
  {
    key: "Colonic Bx L1",
    doc_count: 17,
  },
  {
    key: "Colonic biopsies",
    doc_count: 22,
  },
  {
    key: "Colonic polyp",
    doc_count: 9,
  },
  {
    key: "Core Bx",
    doc_count: 679,
  },
  {
    key: "DLE",
    doc_count: 9565,
  },
  {
    key: "Descending colon biopsies",
    doc_count: 3,
  },
  {
    key: "Descending colon polyp",
    doc_count: 2,
  },
  {
    key: "Duodenal Bx 1",
    doc_count: 1022,
  },
  {
    key: "Duodenal Bx 2",
    doc_count: 670,
  },
  {
    key: "Duodenal Bx 3",
    doc_count: 37,
  },
  {
    key: "Duodenal biopsies",
    doc_count: 14,
  },
  {
    key: "Duodenal bx L1",
    doc_count: 4,
  },
  {
    key: "Duodenal/Gastric Bx 1",
    doc_count: 10,
  },
  {
    key: "Duodenal/Gastric Bx 2",
    doc_count: 1224,
  },
  {
    key: "Duodenal/Gastric Bx 3",
    doc_count: 3441,
  },
  {
    key: "Duodenal/Gastric Bx 4",
    doc_count: 1304,
  },
  {
    key: "Duodenal/Gastric Bx 5",
    doc_count: 216,
  },
  {
    key: "Duodenal/Gastric Bx 6",
    doc_count: 31,
  },
  {
    key: "Duodenal/Gastric/Oesophageal Bx 1",
    doc_count: 3,
  },
  {
    key: "Duodenal/Gastric/Oesophageal Bx 2",
    doc_count: 12,
  },
  {
    key: "Duodenal/Gastric/Oesophageal Bx 3",
    doc_count: 293,
  },
  {
    key: "Duodenal/Gastric/Oesophageal Bx 4",
    doc_count: 714,
  },
  {
    key: "Duodenal/Gastric/Oesophageal Bx 5",
    doc_count: 719,
  },
  {
    key: "Duodenal/Gastric/Oesophageal Bx 6",
    doc_count: 530,
  },
  {
    key: "Duodenal/Oesophageal Bx 2",
    doc_count: 176,
  },
  {
    key: "Duodenal/Oesophageal Bx 3",
    doc_count: 172,
  },
  {
    key: "Duodenal/Oesophageal Bx 4",
    doc_count: 75,
  },
  {
    key: "Duodenal/Oesophageal Bx 5",
    doc_count: 12,
  },
  {
    key: "Duodenal/Oesophageal Bx 6",
    doc_count: 9,
  },
  {
    key: "EMR",
    doc_count: 741,
  },
  {
    key: "Fallopian Tube",
    doc_count: 72,
  },
  {
    key: "Foreskin",
    doc_count: 15,
  },
  {
    key: "Gallbladder",
    doc_count: 233,
  },
  {
    key: "Gastric Bx 1",
    doc_count: 1452,
  },
  {
    key: "Gastric Bx 2",
    doc_count: 1712,
  },
  {
    key: "Gastric Bx 3",
    doc_count: 551,
  },
  {
    key: "Gastric Bx 4",
    doc_count: 186,
  },
  {
    key: "Gastric Bx 5",
    doc_count: 51,
  },
  {
    key: "Gastric Bx6",
    doc_count: 56,
  },
  {
    key: "Gastric/Oesophageal Bx 1",
    doc_count: 59,
  },
  {
    key: "Gastric/Oesophageal Bx 2",
    doc_count: 561,
  },
  {
    key: "Gastric/Oesophageal Bx 3",
    doc_count: 911,
  },
  {
    key: "Gastric/Oesophageal Bx 4",
    doc_count: 943,
  },
  {
    key: "Gastric/Oesophageal Bx 5",
    doc_count: 373,
  },
  {
    key: "Gastric/Oesophageal Bx 6",
    doc_count: 131,
  },
  {
    key: "Gastrointestinal Excision",
    doc_count: 891,
  },
  {
    key: "Gastrointestinal Polyp",
    doc_count: 3481,
  },
  {
    key: "Gastrointestinal Resection",
    doc_count: 3174,
  },
  {
    key: "Gynae Bx",
    doc_count: 1051,
  },
  {
    key: "Gynae Chippings",
    doc_count: 169,
  },
  {
    key: "Gynae Endometrial/ Pipelle Bx",
    doc_count: 2907,
  },
  {
    key: "Gynae Excision",
    doc_count: 5490,
  },
  {
    key: "Gynae Homograft",
    doc_count: 1,
  },
  {
    key: "Gynae Polyp",
    doc_count: 746,
  },
  {
    key: "Head and Neck Bx",
    doc_count: 9,
  },
  {
    key: "Left Colonic Bx L1",
    doc_count: 16,
  },
  {
    key: "Left colon biopsies",
    doc_count: 21,
  },
  {
    key: "Liver Bx",
    doc_count: 18,
  },
  {
    key: "Liver Excision",
    doc_count: 3,
  },
  {
    key: "Lower GI Bx 1",
    doc_count: 3706,
  },
  {
    key: "Lower GI Bx 2",
    doc_count: 3161,
  },
  {
    key: "Lower GI Bx 3",
    doc_count: 3383,
  },
  {
    key: "Lower GI Bx 4",
    doc_count: 2281,
  },
  {
    key: "Lower GI Bx 5",
    doc_count: 1752,
  },
  {
    key: "Lower GI Bx 6",
    doc_count: 4403,
  },
  {
    key: "Lung",
    doc_count: 7694,
  },
  {
    key: "Lymph Node Core Bx",
    doc_count: 182,
  },
  {
    key: "Lymph Node Dissection",
    doc_count: 442,
  },
  {
    key: "Lymph Node Excision",
    doc_count: 4245,
  },
  {
    key: "Nail",
    doc_count: 71,
  },
  {
    key: "Oesophageal Bx 1",
    doc_count: 1326,
  },
  {
    key: "Oesophageal Bx 2",
    doc_count: 1084,
  },
  {
    key: "Oesophageal Bx 3",
    doc_count: 910,
  },
  {
    key: "Oesophageal Bx 4",
    doc_count: 315,
  },
  {
    key: "Oesophageal Bx 5",
    doc_count: 213,
  },
  {
    key: "Oesophageal Bx 6",
    doc_count: 184,
  },
  {
    key: "Omentum",
    doc_count: 339,
  },
  {
    key: "Pancreas",
    doc_count: 98,
  },
  {
    key: "Penile Bx",
    doc_count: 11,
  },
  {
    key: "Pleural Excision",
    doc_count: 333,
  },
  {
    key: "Products of Conception",
    doc_count: 4,
  },
  {
    key: "Prostate",
    doc_count: 4641,
  },
  {
    key: "Prostate Bx",
    doc_count: 13937,
  },
  {
    key: "Prostate biopsies",
    doc_count: 166,
  },
  {
    key: "Prostate chippings",
    doc_count: 8,
  },
  {
    key: "Rectal Bx L1",
    doc_count: 28,
  },
  {
    key: "Rectal biopsies",
    doc_count: 46,
  },
  {
    key: "Rectal biopsy",
    doc_count: 1,
  },
  {
    key: "Rectal polyp",
    doc_count: 5,
  },
  {
    key: "Referral Case",
    doc_count: 20,
  },
  {
    key: "Renal IMF",
    doc_count: 25,
  },
  {
    key: "Renal Native Bx",
    doc_count: 301,
  },
  {
    key: "Renal Transplant Bx",
    doc_count: 83,
  },
  {
    key: "Respiratory Bx",
    doc_count: 4751,
  },
  {
    key: "Right Colonic Bx L1",
    doc_count: 22,
  },
  {
    key: "Right colon biopsies",
    doc_count: 38,
  },
  {
    key: "Sigmoid Bx L1",
    doc_count: 8,
  },
  {
    key: "Sigmoid colon biopsies",
    doc_count: 21,
  },
  {
    key: "Sigmoid polyp",
    doc_count: 13,
  },
  {
    key: "Skin Alopecia Bx",
    doc_count: 178,
  },
  {
    key: "Skin Excision",
    doc_count: 18098,
  },
  {
    key: "Skin IMF",
    doc_count: 45,
  },
  {
    key: "Skin Incision Bx",
    doc_count: 1320,
  },
  {
    key: "Skin Lymph Node Dissection",
    doc_count: 172,
  },
  {
    key: "Skin Punch Bx",
    doc_count: 3247,
  },
  {
    key: "Skin SLN",
    doc_count: 1351,
  },
  {
    key: "Skin ShavDeeper x4e Bx",
    doc_count: 9,
  },
  {
    key: "Skin Shave Bx",
    doc_count: 6075,
  },
  {
    key: "Skins MOHS",
    doc_count: 23,
  },
  {
    key: "Small Bowel Transplant Bx 1",
    doc_count: 2,
  },
  {
    key: "Small Bowel Transplant Bx 2",
    doc_count: 41,
  },
  {
    key: "Small Bowel Transplant Bx 3",
    doc_count: 34,
  },
  {
    key: "Small Bowel Transplant Bx 4",
    doc_count: 34,
  },
  {
    key: "Small Bowel Transplant Bx 5",
    doc_count: 12,
  },
  {
    key: "Spleen",
    doc_count: 17,
  },
  {
    key: "Splenic flexure biopsies",
    doc_count: 1,
  },
  {
    key: "Subcutaneous",
    doc_count: 531,
  },
  {
    key: "TEM",
    doc_count: 117,
  },
  {
    key: "Terminal Ileum bx L1",
    doc_count: 8,
  },
  {
    key: "Terminal ileum biopsies",
    doc_count: 20,
  },
  {
    key: "Thymus",
    doc_count: 67,
  },
  {
    key: "Thyroid",
    doc_count: 19,
  },
  {
    key: "Transverse Bx L1",
    doc_count: 15,
  },
  {
    key: "Transverse colon biopsies",
    doc_count: 35,
  },
  {
    key: "Transverse colon polyp",
    doc_count: 5,
  },
  {
    key: "Urology Chippings",
    doc_count: 478,
  },
  {
    key: "Urology Excision",
    doc_count: 2833,
  },
  {
    key: "Vaginal Bx",
    doc_count: 77,
  },
  {
    key: "Vas deferens Bx",
    doc_count: 9,
  },
  {
    key: "Vulval  Punch Bx",
    doc_count: 269,
  },
];

var stains = [
  {
    key: "",
    doc_count: 23,
  },
  {
    key: "34B12",
    doc_count: 247,
  },
  {
    key: "34BE12",
    doc_count: 2,
  },
  {
    key: "4B5",
    doc_count: 22,
  },
  {
    key: "A1 AT",
    doc_count: 2,
  },
  {
    key: "AB HE",
    doc_count: 28,
  },
  {
    key: "AB RED",
    doc_count: 21,
  },
  {
    key: "ABPAS",
    doc_count: 11,
  },
  {
    key: "ACTIN",
    doc_count: 293,
  },
  {
    key: "AFP",
    doc_count: 28,
  },
  {
    key: "ALK 1",
    doc_count: 29,
  },
  {
    key: "ALK Lung",
    doc_count: 107,
  },
  {
    key: "ALK NEG",
    doc_count: 108,
  },
  {
    key: "AMACR",
    doc_count: 12,
  },
  {
    key: "AMY A",
    doc_count: 5,
  },
  {
    key: "ANDROGEN",
    doc_count: 2,
  },
  {
    key: "Adenovirus",
    doc_count: 3,
  },
  {
    key: "Alcian blue",
    doc_count: 53,
  },
  {
    key: "Androg rec",
    doc_count: 60,
  },
  {
    key: "BAP-1",
    doc_count: 159,
  },
  {
    key: "BCAT",
    doc_count: 5,
  },
  {
    key: "BCL/6",
    doc_count: 79,
  },
  {
    key: "BCL2",
    doc_count: 112,
  },
  {
    key: "BCL2 ANTIBODY",
    doc_count: 1,
  },
  {
    key: "BEREP4",
    doc_count: 1,
  },
  {
    key: "BETA-CAT",
    doc_count: 49,
  },
  {
    key: "BOB 1",
    doc_count: 2,
  },
  {
    key: "BRAF",
    doc_count: 37,
  },
  {
    key: "BRAF haem",
    doc_count: 1,
  },
  {
    key: "BRAF skin",
    doc_count: 2,
  },
  {
    key: "C KIT",
    doc_count: 130,
  },
  {
    key: "C-MYC",
    doc_count: 2,
  },
  {
    key: "C-Myc",
    doc_count: 18,
  },
  {
    key: "C1q Renal",
    doc_count: 1,
  },
  {
    key: "C3 Renal",
    doc_count: 17,
  },
  {
    key: "C4d",
    doc_count: 16,
  },
  {
    key: "CA125",
    doc_count: 24,
  },
  {
    key: "CA19.9",
    doc_count: 19,
  },
  {
    key: "CA1X",
    doc_count: 18,
  },
  {
    key: "CALCITONIN",
    doc_count: 1,
  },
  {
    key: "CALDES",
    doc_count: 41,
  },
  {
    key: "CALPO",
    doc_count: 1,
  },
  {
    key: "CALPONIN",
    doc_count: 94,
  },
  {
    key: "CALRET",
    doc_count: 166,
  },
  {
    key: "CAM52",
    doc_count: 4,
  },
  {
    key: "CD10",
    doc_count: 284,
  },
  {
    key: "CD123",
    doc_count: 35,
  },
  {
    key: "CD138",
    doc_count: 13,
  },
  {
    key: "CD14",
    doc_count: 5,
  },
  {
    key: "CD15",
    doc_count: 17,
  },
  {
    key: "CD163",
    doc_count: 39,
  },
  {
    key: "CD18",
    doc_count: 1,
  },
  {
    key: "CD19",
    doc_count: 12,
  },
  {
    key: "CD1A",
    doc_count: 15,
  },
  {
    key: "CD2",
    doc_count: 65,
  },
  {
    key: "CD20",
    doc_count: 248,
  },
  {
    key: "CD20 (PAN B)",
    doc_count: 1,
  },
  {
    key: "CD21",
    doc_count: 45,
  },
  {
    key: "CD23",
    doc_count: 82,
  },
  {
    key: "CD25",
    doc_count: 7,
  },
  {
    key: "CD3",
    doc_count: 274,
  },
  {
    key: "CD3 ANTIBODY",
    doc_count: 1,
  },
  {
    key: "CD30",
    doc_count: 167,
  },
  {
    key: "CD31",
    doc_count: 148,
  },
  {
    key: "CD31 ANGIOGENESIS MARKER",
    doc_count: 1,
  },
  {
    key: "CD34",
    doc_count: 369,
  },
  {
    key: "CD34 (Q BEND 10)ENDOTHELIAL",
    doc_count: 2,
  },
  {
    key: "CD4",
    doc_count: 77,
  },
  {
    key: "CD43",
    doc_count: 6,
  },
  {
    key: "CD5",
    doc_count: 174,
  },
  {
    key: "CD56",
    doc_count: 308,
  },
  {
    key: "CD56 ANTIBODY (NCAM)",
    doc_count: 1,
  },
  {
    key: "CD57",
    doc_count: 1,
  },
  {
    key: "CD61",
    doc_count: 4,
  },
  {
    key: "CD68",
    doc_count: 6,
  },
  {
    key: "CD7",
    doc_count: 54,
  },
  {
    key: "CD79A",
    doc_count: 169,
  },
  {
    key: "CD8",
    doc_count: 75,
  },
  {
    key: "CD99",
    doc_count: 19,
  },
  {
    key: "CDX2",
    doc_count: 204,
  },
  {
    key: "CEA",
    doc_count: 183,
  },
  {
    key: "CERBB2",
    doc_count: 2,
  },
  {
    key: "CHROMA",
    doc_count: 5,
  },
  {
    key: "CHROMO A",
    doc_count: 279,
  },
  {
    key: "CK(AE1/3)",
    doc_count: 731,
  },
  {
    key: "CK14",
    doc_count: 65,
  },
  {
    key: "CK18",
    doc_count: 1,
  },
  {
    key: "CK19",
    doc_count: 20,
  },
  {
    key: "CK20",
    doc_count: 392,
  },
  {
    key: "CK5",
    doc_count: 2387,
  },
  {
    key: "CK5/6",
    doc_count: 1,
  },
  {
    key: "CK5/6 NOC",
    doc_count: 2,
  },
  {
    key: "CK7",
    doc_count: 785,
  },
  {
    key: "CK8",
    doc_count: 2,
  },
  {
    key: "CK8/18",
    doc_count: 188,
  },
  {
    key: "CKHMW",
    doc_count: 2,
  },
  {
    key: "CMV",
    doc_count: 119,
  },
  {
    key: "CXCL13",
    doc_count: 1,
  },
  {
    key: "CYCD1",
    doc_count: 1,
  },
  {
    key: "CYTOKERATIN (CAM5.2)",
    doc_count: 2,
  },
  {
    key: "CYTOKERATIN 14",
    doc_count: 32,
  },
  {
    key: "CYTOKERATIN 5/6",
    doc_count: 4,
  },
  {
    key: "CathepsinK",
    doc_count: 2,
  },
  {
    key: "Congo Red",
    doc_count: 119,
  },
  {
    key: "CyclinD1",
    doc_count: 108,
  },
  {
    key: "D240",
    doc_count: 2,
  },
  {
    key: "DABPAS",
    doc_count: 2414,
  },
  {
    key: "DDISH",
    doc_count: 1,
  },
  {
    key: "DES",
    doc_count: 1,
  },
  {
    key: "DESMIN",
    doc_count: 91,
  },
  {
    key: "DESMIN D33",
    doc_count: 245,
  },
  {
    key: "DOG1",
    doc_count: 60,
  },
  {
    key: "DPAS",
    doc_count: 740,
  },
  {
    key: "E-CAD",
    doc_count: 208,
  },
  {
    key: "E-CADHERIN",
    doc_count: 14,
  },
  {
    key: "EBER Pb",
    doc_count: 80,
  },
  {
    key: "EBV",
    doc_count: 1,
  },
  {
    key: "ECAD",
    doc_count: 22,
  },
  {
    key: "EMA",
    doc_count: 431,
  },
  {
    key: "EP4",
    doc_count: 352,
  },
  {
    key: "EPITHELIAL MEMBRANE ANTIGEN",
    doc_count: 1,
  },
  {
    key: "ER",
    doc_count: 2104,
  },
  {
    key: "ERG",
    doc_count: 12,
  },
  {
    key: "EVG",
    doc_count: 1413,
  },
  {
    key: "F13a",
    doc_count: 59,
  },
  {
    key: "F8 VWF",
    doc_count: 5,
  },
  {
    key: "FAC13A",
    doc_count: 1,
  },
  {
    key: "FAC8",
    doc_count: 1,
  },
  {
    key: "FG/SR",
    doc_count: 2,
  },
  {
    key: "FH",
    doc_count: 7,
  },
  {
    key: "GASTRIN",
    doc_count: 1,
  },
  {
    key: "GATA3",
    doc_count: 20,
  },
  {
    key: "GCDFP",
    doc_count: 7,
  },
  {
    key: "GCDFP-15",
    doc_count: 36,
  },
  {
    key: "GFAP",
    doc_count: 2,
  },
  {
    key: "GLUT SYN",
    doc_count: 1,
  },
  {
    key: "GLYPICAN-3",
    doc_count: 26,
  },
  {
    key: "GRAM",
    doc_count: 1,
  },
  {
    key: "GRANB",
    doc_count: 12,
  },
  {
    key: "Gata/3",
    doc_count: 142,
  },
  {
    key: "Giemsa",
    doc_count: 12,
  },
  {
    key: "Glucagon",
    doc_count: 1,
  },
  {
    key: "Gram",
    doc_count: 295,
  },
  {
    key: "Grocott",
    doc_count: 175,
  },
  {
    key: "H. Pylori",
    doc_count: 110,
  },
  {
    key: "HAEMATOXYLIN AND EOSIN",
    doc_count: 2,
  },
  {
    key: "HCG",
    doc_count: 19,
  },
  {
    key: "HE",
    doc_count: 123117,
  },
  {
    key: "HE DUPLICATE",
    doc_count: 2,
  },
  {
    key: "HE-DP",
    doc_count: 1,
  },
  {
    key: "HE-ICC",
    doc_count: 1,
  },
  {
    key: "HE-RC",
    doc_count: 11,
  },
  {
    key: "HER2",
    doc_count: 382,
  },
  {
    key: "HER2 SISH",
    doc_count: 1,
  },
  {
    key: "HHF35",
    doc_count: 3,
  },
  {
    key: "HHV-8",
    doc_count: 49,
  },
  {
    key: "HMB45",
    doc_count: 192,
  },
  {
    key: "HPV 16 ISH",
    doc_count: 51,
  },
  {
    key: "HSV 1/60",
    doc_count: 78,
  },
  {
    key: "Her-2",
    doc_count: 1182,
  },
  {
    key: "Her2 CISH",
    doc_count: 163,
  },
  {
    key: "INHIBIN",
    doc_count: 14,
  },
  {
    key: "INI-1",
    doc_count: 7,
  },
  {
    key: "INSULIN",
    doc_count: 1,
  },
  {
    key: "IgA Renal",
    doc_count: 18,
  },
  {
    key: "IgG",
    doc_count: 28,
  },
  {
    key: "IgG Renal",
    doc_count: 18,
  },
  {
    key: "IgG4",
    doc_count: 45,
  },
  {
    key: "IgM",
    doc_count: 1,
  },
  {
    key: "IgM Renal",
    doc_count: 1,
  },
  {
    key: "JHS",
    doc_count: 42,
  },
  {
    key: "KAPPA",
    doc_count: 1,
  },
  {
    key: "KAPPA BM",
    doc_count: 19,
  },
  {
    key: "KAPPA LN",
    doc_count: 3,
  },
  {
    key: "KI67",
    doc_count: 6,
  },
  {
    key: "KP1",
    doc_count: 7,
  },
  {
    key: "Kappa Pb",
    doc_count: 109,
  },
  {
    key: "Ki67",
    doc_count: 434,
  },
  {
    key: "L1",
    doc_count: 91,
  },
  {
    key: "L1-2",
    doc_count: 109,
  },
  {
    key: "L1-3",
    doc_count: 26,
  },
  {
    key: "L10-11",
    doc_count: 3,
  },
  {
    key: "L12",
    doc_count: 3,
  },
  {
    key: "L2",
    doc_count: 28,
  },
  {
    key: "L2-3",
    doc_count: 77,
  },
  {
    key: "L2-4",
    doc_count: 7,
  },
  {
    key: "L3",
    doc_count: 129,
  },
  {
    key: "L3-4",
    doc_count: 4,
  },
  {
    key: "L4",
    doc_count: 40,
  },
  {
    key: "L4-5",
    doc_count: 15,
  },
  {
    key: "L4-6",
    doc_count: 7,
  },
  {
    key: "L5",
    doc_count: 2,
  },
  {
    key: "L5-6",
    doc_count: 1,
  },
  {
    key: "L6",
    doc_count: 5,
  },
  {
    key: "L6-7",
    doc_count: 8,
  },
  {
    key: "L7",
    doc_count: 1,
  },
  {
    key: "L7-8",
    doc_count: 1,
  },
  {
    key: "L7-9",
    doc_count: 1,
  },
  {
    key: "L8-9",
    doc_count: 3,
  },
  {
    key: "L9",
    doc_count: 1,
  },
  {
    key: "LAMBDA",
    doc_count: 1,
  },
  {
    key: "LCA",
    doc_count: 69,
  },
  {
    key: "LEF-1",
    doc_count: 34,
  },
  {
    key: "LEVEL 1",
    doc_count: 165,
  },
  {
    key: "LEVEL 2",
    doc_count: 12,
  },
  {
    key: "LEVEL 3",
    doc_count: 171,
  },
  {
    key: "LEVEL 6",
    doc_count: 9,
  },
  {
    key: "LEVELS 1-2",
    doc_count: 159,
  },
  {
    key: "LEVELS 1-3",
    doc_count: 219,
  },
  {
    key: "LEVELS 10-11",
    doc_count: 1,
  },
  {
    key: "LEVELS 12-13",
    doc_count: 1,
  },
  {
    key: "LEVELS 14-15",
    doc_count: 1,
  },
  {
    key: "LEVELS 16-17",
    doc_count: 1,
  },
  {
    key: "LEVELS 4-5",
    doc_count: 10,
  },
  {
    key: "LEVELS 4-6",
    doc_count: 1,
  },
  {
    key: "LEVELS 6-7",
    doc_count: 1,
  },
  {
    key: "LEVELS 8-9",
    doc_count: 1,
  },
  {
    key: "LMO2",
    doc_count: 7,
  },
  {
    key: "LYSOZYME",
    doc_count: 13,
  },
  {
    key: "Lambda Pb",
    doc_count: 108,
  },
  {
    key: "LambdaBM",
    doc_count: 19,
  },
  {
    key: "LambdaLN",
    doc_count: 3,
  },
  {
    key: "Langerin",
    doc_count: 18,
  },
  {
    key: "MELAN",
    doc_count: 1,
  },
  {
    key: "MIB1",
    doc_count: 677,
  },
  {
    key: "MLH 1",
    doc_count: 458,
  },
  {
    key: "MNF116",
    doc_count: 60,
  },
  {
    key: "MOC-31",
    doc_count: 138,
  },
  {
    key: "MPO",
    doc_count: 17,
  },
  {
    key: "MSB",
    doc_count: 2,
  },
  {
    key: "MSH 2",
    doc_count: 459,
  },
  {
    key: "MSH 6",
    doc_count: 457,
  },
  {
    key: "MUC4",
    doc_count: 1,
  },
  {
    key: "MUC5AC",
    doc_count: 1,
  },
  {
    key: "MUM 1",
    doc_count: 71,
  },
  {
    key: "MYOD1",
    doc_count: 13,
  },
  {
    key: "MYOSIN",
    doc_count: 36,
  },
  {
    key: "Masson Fon",
    doc_count: 13,
  },
  {
    key: "Masson tri",
    doc_count: 10,
  },
  {
    key: "Mast CT",
    doc_count: 2,
  },
  {
    key: "Melan A",
    doc_count: 917,
  },
  {
    key: "Myogenin",
    doc_count: 11,
  },
  {
    key: "NAPSIN",
    doc_count: 3,
  },
  {
    key: "NEURON SPECIFIC ENOLASE",
    doc_count: 1,
  },
  {
    key: "NF",
    doc_count: 18,
  },
  {
    key: "NKIC3",
    doc_count: 2,
  },
  {
    key: "NKX3.1",
    doc_count: 141,
  },
  {
    key: "NSE",
    doc_count: 1,
  },
  {
    key: "NSE1/200",
    doc_count: 1,
  },
  {
    key: "Napsin A",
    doc_count: 135,
  },
  {
    key: "OCT /2",
    doc_count: 1,
  },
  {
    key: "OCT 3/4",
    doc_count: 81,
  },
  {
    key: "OESTROGEN RECEPTOR (ID5)",
    doc_count: 34,
  },
  {
    key: "ORO",
    doc_count: 1,
  },
  {
    key: "Orcein",
    doc_count: 2,
  },
  {
    key: "P 63",
    doc_count: 325,
  },
  {
    key: "P COMP",
    doc_count: 11,
  },
  {
    key: "P120 CATENIN",
    doc_count: 9,
  },
  {
    key: "P16",
    doc_count: 721,
  },
  {
    key: "P53",
    doc_count: 912,
  },
  {
    key: "P63",
    doc_count: 53,
  },
  {
    key: "P63 ANTIBODY",
    doc_count: 28,
  },
  {
    key: "PAS",
    doc_count: 3016,
  },
  {
    key: "PAX 5",
    doc_count: 15,
  },
  {
    key: "PAX008",
    doc_count: 192,
  },
  {
    key: "PAX8",
    doc_count: 8,
  },
  {
    key: "PCP",
    doc_count: 1,
  },
  {
    key: "PD-L1",
    doc_count: 158,
  },
  {
    key: "PD-L1 NEG",
    doc_count: 156,
  },
  {
    key: "PD1",
    doc_count: 26,
  },
  {
    key: "PGM1",
    doc_count: 215,
  },
  {
    key: "PLAP",
    doc_count: 14,
  },
  {
    key: "PMS2",
    doc_count: 458,
  },
  {
    key: "PR",
    doc_count: 1805,
  },
  {
    key: "PROGESTERONE ANTIBODY",
    doc_count: 23,
  },
  {
    key: "PROSTATIC SPECIFIC ANTIGEN",
    doc_count: 2,
  },
  {
    key: "PSA",
    doc_count: 10,
  },
  {
    key: "PSA (poly)",
    doc_count: 14,
  },
  {
    key: "PSA POLY",
    doc_count: 33,
  },
  {
    key: "PSAP",
    doc_count: 1,
  },
  {
    key: "PSMA",
    doc_count: 16,
  },
  {
    key: "Perls",
    doc_count: 78,
  },
  {
    key: "RACEMASE",
    doc_count: 47,
  },
  {
    key: "RCC",
    doc_count: 5,
  },
  {
    key: "ROS1",
    doc_count: 107,
  },
  {
    key: "ROS1 NEG",
    doc_count: 103,
  },
  {
    key: "RPAS",
    doc_count: 34,
  },
  {
    key: "RPAS1",
    doc_count: 8,
  },
  {
    key: "RPAS2",
    doc_count: 8,
  },
  {
    key: "Ref Slide",
    doc_count: 10,
  },
  {
    key: "Ret40f",
    doc_count: 4,
  },
  {
    key: "Retic",
    doc_count: 2,
  },
  {
    key: "S100",
    doc_count: 1552,
  },
  {
    key: "SDHB",
    doc_count: 5,
  },
  {
    key: "SERIAL 1",
    doc_count: 3,
  },
  {
    key: "SERIAL SECTION 2",
    doc_count: 1,
  },
  {
    key: "SMA",
    doc_count: 10,
  },
  {
    key: "SMM",
    doc_count: 47,
  },
  {
    key: "SOX-10",
    doc_count: 472,
  },
  {
    key: "SOX/11",
    doc_count: 26,
  },
  {
    key: "SPIRO",
    doc_count: 71,
  },
  {
    key: "STAT6",
    doc_count: 18,
  },
  {
    key: "SV40",
    doc_count: 11,
  },
  {
    key: "SYNAP",
    doc_count: 5,
  },
  {
    key: "SYNAPTO",
    doc_count: 289,
  },
  {
    key: "SYNAPTOPHYSIN",
    doc_count: 3,
  },
  {
    key: "TDT",
    doc_count: 21,
  },
  {
    key: "TFE3",
    doc_count: 8,
  },
  {
    key: "THSD7A",
    doc_count: 1,
  },
  {
    key: "THYROGLO",
    doc_count: 7,
  },
  {
    key: "TTF1",
    doc_count: 827,
  },
  {
    key: "Tol Blue",
    doc_count: 3313,
  },
  {
    key: "Unknown",
    doc_count: 6854,
  },
  {
    key: "VIMENTIN",
    doc_count: 167,
  },
  {
    key: "VS38",
    doc_count: 3,
  },
  {
    key: "WT1",
    doc_count: 3,
  },
  {
    key: "WT1 RTU",
    doc_count: 248,
  },
  {
    key: "ZN",
    doc_count: 284,
  },
  {
    key: "p40",
    doc_count: 431,
  },
];

var dataSets = [
  {
    key: "bladder",
    doc_count: 1672,
  },
  {
    key: "breast",
    doc_count: 30530,
  },
  {
    key: "colorectal",
    doc_count: 24334,
  },
  {
    key: "dermatology",
    doc_count: 31466,
  },
  {
    key: "gynae",
    doc_count: 21671,
  },
  {
    key: "kidney",
    doc_count: 838,
  },
  {
    key: "lung",
    doc_count: 15727,
  },
  {
    key: "pancreas",
    doc_count: 497,
  },
  {
    key: "prostate",
    doc_count: 20938,
  },
  {
    key: "testis",
    doc_count: 816,
  },
  {
    key: "upper_gi",
    doc_count: 25704,
  },
];
var ages = [
  {
    key: "0 - 4",
    doc_count: 21,
  },
  {
    key: "15 - 19",
    doc_count: 598,
  },
  {
    key: "19 - 23",
    doc_count: 267,
  },
  {
    key: "20 - 24",
    doc_count: 2662,
  },
  {
    key: "25 - 29",
    doc_count: 4873,
  },
  {
    key: "30 - 34",
    doc_count: 7571,
  },
  {
    key: "35 - 39",
    doc_count: 6584,
  },
  {
    key: "40 - 44",
    doc_count: 10447,
  },
  {
    key: "45 - 49",
    doc_count: 8338,
  },
  {
    key: "50 - 54",
    doc_count: 16770,
  },
  {
    key: "55 - 59",
    doc_count: 15104,
  },
  {
    key: "60 - 64",
    doc_count: 20388,
  },
  {
    key: "65 - 69",
    doc_count: 19224,
  },
  {
    key: "70 - 74",
    doc_count: 24434,
  },
  {
    key: "75 - 79",
    doc_count: 16818,
  },
  {
    key: "80 - 84",
    doc_count: 11422,
  },
  {
    key: "85 - 89",
    doc_count: 6027,
  },
  {
    key: "90 - 94",
    doc_count: 2242,
  },
  {
    key: "95 - 99",
    doc_count: 379,
  },
  {
    key: "100 - 104",
    doc_count: 21,
  },
];
export { stains, tissues, dataSets, ages };
