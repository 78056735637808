import React, { useEffect, useState } from "react";
import "../IndexPage.css";
import AuthLayout from "../../layout/AuthLayout";

import "@aws-amplify/ui-react/styles.css";
import { useAuth } from "../../auth/AuthProvider";

import { Typography, Form, Input, Button } from "antd";
import "antd/dist/reset.css";
const { Text } = Typography;

function ResetPasswordPage(props) {
  const { onNewPassword } = useAuth();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "PathLAKE - ResetPassword";
  }, []);

  const resetPassword = async (values) => {
    setIsLoading(true);
    onNewPassword(values.password)
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  };

  return (
    <AuthLayout title="Set New Password">
      <Form
        name="resetPassword"
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
        onFinish={resetPassword}
      >
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

        <Form.Item
          name="password_2"
          rules={[
            {
              required: true,
              message: "Please re-input your password!",
            },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            loading={isLoading}
          >
            Set Password
          </Button>
        </Form.Item>
      </Form>
      {error && <Text type="danger">{error}</Text>}
    </AuthLayout>
  );
}

export default ResetPasswordPage;
