import React, { useEffect, useState } from "react";
import { Menu, Layout, Row, Col } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  MenuOutlined,
  ShoppingCartOutlined,
  SearchOutlined,
  SettingOutlined,
  DeploymentUnitOutlined,
} from "@ant-design/icons";
import ProfileDrawer from "./ProfileDrawer.js";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useAuth } from "../auth/AuthProvider.jsx";
import "./DefaultHeader.less";
const { Header } = Layout;

function DefaultHeader(props) {
  const navigate = useNavigate();
  const [profileOpen, setProfileOpen] = useState(false);
  const [pages, setPages] = useState([]);
  const { user, onLogout } = useAuth();

  useEffect(() => {
    var items;
    if (user) {
      items = [
        {
          label: "Explore",
          key: "explore",
          icon: <DeploymentUnitOutlined />,
          onClick: () => {
            navigate("/explore");
          },
        },
        {
          label: "Search",
          key: "search",
          icon: <SearchOutlined />,
          onClick: () => {
            navigate("/search");
          },
        },
        {
          label: "Baskets",
          key: "baskets",
          icon: <ShoppingCartOutlined />,
          onClick: () => {
            navigate("/baskets");
          },
        },
      ];
    }

    if (user && user.attributes["custom:role"] === "admin") {
      items.push({
        label: "Admin",
        key: "admin",
        icon: <SettingOutlined />,
        onClick: () => {
          navigate("/admin");
        },
      });
    }

    if (user) {
      items.push({
        label: user.attributes.given_name,
        key: "user",
        icon: <UserOutlined />,
        children: [
          {
            label: "Profile",
            key: "profile",
            onClick: () => {
              setProfileOpen(!profileOpen);
            },
            icon: <UserOutlined />,
          },
          {
            label: "Logout",
            key: "logout",
            danger: "true",
            onClick: onLogout,
            icon: <LogoutOutlined />,
          },
        ],
      });
    }

    setPages(items);
  }, [user, profileOpen, navigate]);

  return (
    <>
      <Header
        className={props.className}
        style={{ background: 0, collapsedIconSize: 64 }}
      >
        <Row justify="space-between">
          <Col>
            <Link to={"/"}>
              <img
                className="logo"
                src={props.whiteLogo ? "/logo-sm-white.svg" : "/logo-sm.svg"}
                alt="PathLAKE Logo"
              />
            </Link>
          </Col>
          <Col>
            <Menu
              style={{
                minWidth: "50vw",
                justifyContent: "right",
                borderBottom: 0,
              }}
              className="menu"
              mode="horizontal"
              items={pages}
              theme={props.whiteLogo ? "dark" : "light"}
              overflowedIndicator={<MenuOutlined width="8em" />}
              selectedKeys={props.selected ? [props.selected] : []}
            />
          </Col>
        </Row>
      </Header>
      <ProfileDrawer
        onClose={() => {
          setProfileOpen(false);
        }}
        open={profileOpen}
      />
    </>
  );
}

export default DefaultHeader;
