import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Card, Typography } from "antd";
import { API } from "aws-amplify";
import TissuePieChart from "../ExplorePage/TissuePieChart";
import CommonAlert from "../Core/CommonAlert";

function Dashboard() {
  const [error, setError] = useState(null);

  const [organisations, setOrganisations] = useState([]);
  const sortDocs = (a, b) => {
    return a["doc_count"] < b["doc_count"] ? 1 : -1;
  };
  const fetchData = async () => {
    const session = await Auth.currentSession();

    let query = {};

    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      queryStringParameters: query,
      response: false, //Only want response body and not the whole Axiom object
    };

    API.get("PathLakeRESTAPI", `/organisations/`, myInit)
      .then((response) => {
        setOrganisations(response["organisations"].sort(sortDocs));
      })
      .catch((error) => {
        setError({ data: error.response.data, code: error.response.status });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card>
      <Typography.Title>Dashboard</Typography.Title>
      {error && <CommonAlert data={error.data} code={error.code} />}

      <Card style={{ width: "100%", height: "100%" }}>
        <Typography.Title level={3}>Organisations</Typography.Title>
        <TissuePieChart data={organisations} />
      </Card>
    </Card>
  );
}

export default Dashboard;
