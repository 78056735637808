import React from "react";

import "@aws-amplify/ui-react/styles.css";
import TweenOne from "rc-tween-one";
import Children from "rc-tween-one/lib/plugin/ChildrenPlugin";
import { Row, Col, Card, Space } from "antd";
import Icon from "@ant-design/icons";
import "antd/dist/reset.css";
import { ReactComponent as HospitalSvg } from "../../icons/Hospital.svg";

import { ReactComponent as LungsSvg } from "../../icons/Lungs.svg";

import { ReactComponent as CollectionSvg } from "../../icons/Collection.svg";

TweenOne.plugins.push(Children);
function OverviewBox(props) {
  const animation = (value) => {
    return {
      Children: {
        value: value,
        floatLength: 0,
        formatMoney: true,
      },
      duration: 500,
    };
  };
  return (
    <Card
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: props.filled ? "#582475" : "rgba(0,0,0,0)",
        borderColor: props.filled ? "#582475" : "rgba(0,0,0,0)",
        color: "white",
      }}
    >
      <Row style={{ width: "100%" }} justify="space-around" align="middle">
        <Col>
          <Space style={{ fontSize: 64, marginRight: 14 }}>
            <Icon component={HospitalSvg} />
          </Space>
          <Space direction="horizontal">
            <span
              style={{
                fontSize: "2em",
              }}
            >
              <TweenOne
                animation={animation(props.tissues ? props.tissues : 0)}
              >
                0
              </TweenOne>
            </span>{" "}
            Tissue Sites
          </Space>
        </Col>
        <Col>
          <Space style={{ fontSize: 64, marginRight: 14 }}>
            <Icon component={LungsSvg} />
          </Space>
          <Space direction="horizontal">
            <span
              style={{
                fontSize: "2em",
              }}
            >
              <TweenOne
                animation={animation(props.dataSets ? props.dataSets : 0)}
              >
                0
              </TweenOne>
            </span>{" "}
            Data Sets
          </Space>
        </Col>
        <Col>
          <Space style={{ fontSize: 64, marginRight: 14 }}>
            <Icon component={CollectionSvg} />
          </Space>
          <Space direction="horizontal">
            <span
              style={{
                fontSize: "2em",
              }}
            >
              <TweenOne animation={animation(props.total ? props.total : 0)}>
                0
              </TweenOne>
            </span>{" "}
            Slides
          </Space>
        </Col>
      </Row>
    </Card>
  );
}

export default OverviewBox;
