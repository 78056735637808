import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import dayjs from "dayjs";

import { Button, Card, Table, Tag, Typography, Skeleton } from "antd";
import { API } from "aws-amplify";
import CommonAlert from "../Core/CommonAlert";

const columns = [
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
    fixed: "left",
    render: (username) => <a href={`/users/${username}`}>{username}</a>,
  },
  {
    title: "Role",
    dataIndex: "custom:role",
    key: "role",
    fixed: "left",
    sorter: (a, b) => (a["custom:role"] > b["custom:role"] ? 1 : -1),
  },
  {
    title: "Organisation",
    dataIndex: "custom:organisation",
    key: "Organisation",
    sorter: (a, b) =>
      a["custom:organisation"] > b["custom:organisation"] ? 1 : -1,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: (a, b) => (a["status"] > b["status"] ? 1 : -1),
  },
  {
    title: "Enabled",
    dataIndex: "enabled",
    key: "enabled",
    render: (enabled) => (
      <>
        {enabled ? <Tag color="success">Yes</Tag> : <Tag color="error">No</Tag>}
      </>
    ),
    sorter: (a, b) => (a["enabled"] > b["enabled"] ? 1 : -1),
  },
  {
    title: "Updated",
    dataIndex: "updated",
    key: "updated",
    render: (time) => <>{dayjs(time).format("YYYY MMM D")}</>,
    sorter: (a, b) => dayjs(a.created).diff(dayjs(b.created)),
  },
  {
    title: "Created",
    dataIndex: "created",
    key: "created",
    render: (time) => <>{dayjs(time).format("YYYY MMM D")}</>,
    sorter: (a, b) => dayjs(a.created).diff(dayjs(b.created)),
  },
];

function ListUsers({ user }, props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  const [paginationToken, setPaginationToken] = useState(null);

  const [users, setUsers] = useState([]);

  const fetchData = async () => {
    const session = await Auth.currentSession();

    let query = {};
    if (paginationToken) {
      query = { next: paginationToken };
    }

    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      queryStringParameters: query,
      response: false, //Only want response body and not the whole Axiom object
    };

    API.get("PathLakeRESTAPI", `/users/`, myInit)
      .then((response) => {
        setIsLoaded(true);
        setUsers(response["users"]);
        if ("next" in response) {
          setPaginationToken(response["next"]);
        } else {
          setPaginationToken(null);
        }
      })
      .catch((error) => {
        setIsLoaded(true);
        setError({ data: error.response.data, code: error.response.status });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card>
      <Typography.Title>Users</Typography.Title>
      {error && <CommonAlert data={error.data} code={error.code} />}

      {isLoaded && (
        <Table
          dataSource={users}
          columns={columns}
          scroll={{ x: 1200 }}
          pagination={false}
        />
      )}
      {!isLoaded && <Skeleton active />}
      <Button onClick={fetchData} disabled={!paginationToken}>
        Next
      </Button>
    </Card>
  );
}

export default ListUsers;
