import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAuth } from "./AuthProvider";
const ProtectedRoute = ({ children }) => {
  const { user, loaded, onLoad } = useAuth();
  const location = useLocation();

  if (!loaded) {
    onLoad();
  }

  if (!user && loaded) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;
