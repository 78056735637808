import React from "react";
import { Layout, Typography, Row, Col } from "antd";
import DefaultHeader from "../layout/DefaultHeader";
import DefaultFooter from "../layout/DefaultFooter";
import { MailOutlined } from "@ant-design/icons";
const { Content } = Layout;
const { Paragraph } = Typography;

function HelpPage() {

  return (
    <Layout>
      <DefaultHeader />
      <Content style={{ padding: 16 }}>
        <Row>
          <Col span={24} >
            <Typography.Title>Help & Support</Typography.Title>
          </Col>
          <Col span={24} >
            <Typography.Title level={2}>Contact Us</Typography.Title>
            <Paragraph>
              If you have any questions please send us an email
            </Paragraph>
            <Typography.Link href="mailto:pathlake-support@warwick.ac.uk">
              <MailOutlined style={{ padding: 4 }} />
              pathlake-support@warwick.ac.uk
            </Typography.Link>
          </Col>
        </Row>
      </Content>
      <DefaultFooter />
    </Layout>
  );
}

export default HelpPage;
