import React from "react";
import { Row, Col, Layout, Typography } from "antd";
import "./DefaultFooter.less";
import { useAuth } from "../auth/AuthProvider.jsx";

const { Paragraph } = Typography;
const { Footer } = Layout;

function DefaultFooter(props) {
  const { user } = useAuth();
  return (
    <Footer className={props.className} style={{ background: 0 }}>
      <Row gutter={16}>
        <Col span={18}>
          <Paragraph></Paragraph>
        </Col>
        <Col span={6}>
          <Paragraph></Paragraph>
        </Col>
      </Row>
      <Row gutter={16} align={"space-between"}>
        <Col>
          <Paragraph>© 2023 University of Warwick</Paragraph>
        </Col>
        {user && (
          <Col>
            <Typography.Link href="help">Help & Support</Typography.Link>
          </Col>
        )}
      </Row>
    </Footer>
  );
}

export default DefaultFooter;
