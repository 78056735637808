import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.less";
import SearchPage from "./pages/SearchPage";
import IndexPage from "./pages/IndexPage";
import BasketsPage from "./pages/BasketsPage";
import BasketPage from "./pages/BasketPage";
import ViewerPage from "./pages/ViewerPage";
import DownloadPage from "./pages/DownloadPage";
import AdminPage from "./pages/AdminPage";
import UserPage from "./pages/UserPage";
import LoginPage from "./pages/auth/LoginPage";
import RegisterPage from "./pages/auth/RegisterPage";
import HelpPage from "./pages/HelpPage";
import ResetPasswordPage from "./pages/auth/ResetPasswordPage";

import ProjectAdminPage from "./pages/ProjectAdminPage";
import ManifestPage from "./pages/ManifestPage";
import NotFoundPage from "./pages/NotFoundPage";
import ExplorePage from "./pages/ExplorePage";
import ProtectedRoute from "./auth/ProtectedRoute";
import AdminRoute from "./auth/AdminRoute";
import AuthProvider from "./auth/AuthProvider";
import "@aws-amplify/ui-react/styles.css";
import { Layout, ConfigProvider } from "antd";
import ForgottenPasswordPage from "./pages/auth/ForgottenPasswordPage";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#582475",
          colorLink: "#582475",
          colorLinkActive: "#582475",
          colorLinkHover: "rgba(88, 36, 117, 0.7)",
          colorPrimaryHover: "rgba(88, 36, 117, 0.7)",
          colorBgContainer: "rgba(255, 255, 255, 0)",
          fontFamily: '"Raleway", sans-serif',
        },
      }}
    >
      <Layout style={{ minHeight: "100vh" }}>
        <AuthProvider>
          <Routes>
            <Route index path="/" element={<IndexPage />} />
            <Route path="signin" element={<LoginPage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="reset" element={<ResetPasswordPage />} />
            <Route path="forgotten" element={<ForgottenPasswordPage />} />
            <Route
              path="help"
              element={
                <ProtectedRoute>
                  <HelpPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="viewer/:slideId"
              element={
                <ProtectedRoute>
                  <ViewerPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="search"
              element={
                <ProtectedRoute>
                  <SearchPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="explore"
              element={
                <ProtectedRoute>
                  <ExplorePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="baskets"
              element={
                <ProtectedRoute>
                  <BasketsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="baskets/:basketId"
              element={
                <ProtectedRoute>
                  <BasketPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="download/:downloadId"
              element={
                <AdminRoute>
                  <DownloadPage />
                </AdminRoute>
              }
            />
            <Route
              path="admin/"
              element={
                <AdminRoute>
                  <AdminPage />
                </AdminRoute>
              }
            />
            <Route
              path="users/:username"
              element={
                <AdminRoute>
                  <UserPage />
                </AdminRoute>
              }
            />
            <Route
              path="projects/:projectId"
              element={
                <AdminRoute>
                  <ProjectAdminPage />
                </AdminRoute>
              }
            />
            <Route
              path="manifests/:manifestId"
              element={
                <AdminRoute>
                  <ManifestPage />
                </AdminRoute>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </AuthProvider>
      </Layout>
    </ConfigProvider>
  );
}

export default App;
