import { Button, Pagination } from "antd";

function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return <Button>Previous</Button>;
  }
  if (type === "next") {
    return <Button>Next</Button>;
  }
  return originalElement;
}

function SearchPagination(props) {
  return (
    <Pagination
      total={
        props.total > 10000 ? 10000 - (10000 % props.pageSize) : props.total
      }
      itemRender={itemRender}
      onChange={props.callback}
      current={props.page}
      pageSize={props.pageSize}
      pageSizeOptions={[15, 30, 45, 60]}
    />
  );
}

export default SearchPagination;
