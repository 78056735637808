import React, { useState } from "react";
import { API, Auth } from "aws-amplify";
import {
  Space,
  Button,
  Card,
  notification,
  Typography,
  Row,
  Col,
  Tooltip,
  Modal,
} from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";
function BasketWidget(props) {
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isAddAllLoading, setIsAddAllLoading] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const basketNotification = (message, success) => {
    notification[success ? "success" : "error"]({
      message: message,
    });
  };

  const handleUpdateBasket = async () => {
    setIsUpdateLoading(true);
    var updatedBasket = { ...props.selectedBasket };
    updatedBasket.slides = Array.from(props.selectedSlides); // Only send slide IDs

    const session = await Auth.currentSession();
    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      response: false, //Only want response body and not the whole Axiom object
      body: updatedBasket,
    };
    API.put("PathLakeRESTAPI", `/baskets/${props.selectedBasket.id}`, myInit)
      .then((response) => {
        basketNotification("Successfully updated basket", true);
        props.setBasketsLastUpdated(new Date());
        props.setSelectedBasket(updatedBasket);
        setIsUpdateLoading(false);
      })
      .catch((error) => {
        basketNotification("Something went wrong", false);
        setIsUpdateLoading(false);
      });
  };

  const handleAddQueryResults = async () => {
    setIsAddAllLoading(true);
    let query = {};
    if (props.searchParams.get("q")) {
      query["q"] = props.searchParams.get("q");
    }
    if (props.searchParams.get("name")) {
      query["name"] = props.searchParams.get("name");
    }
    if (props.searchParams.get("project")) {
      query["project"] = props.searchParams.get("project");
    }
    if (props.searchParams.get("dataset")) {
      query["dataset"] = props.searchParams.get("dataset");
    }
    if (props.searchParams.get("sex")) {
      query["sex"] = props.searchParams.get("sex");
    }
    if (props.searchParams.get("age")) {
      query["age"] = props.searchParams.get("age");
    }
    if (props.searchParams.get("stain")) {
      query["stain"] = props.searchParams.get("stain");
    }
    if (props.searchParams.get("site")) {
      query["tissue_type"] = props.searchParams.get("site");
    }
    if (props.searchParams.get("ethnicity")) {
      query["ethnicity"] = props.searchParams.get("ethnicity");
    }
    if (props.searchParams.get("caseId")) {
      query["case_id"] = props.searchParams.get("caseId");
    }

    const session = await Auth.currentSession();
    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      response: false, //Only want response body and not the whole Axiom object
      body: query,
      queryStringParameters: {
        add_query: "true",
      },
    };
    API.patch(
      "PathLakeRESTAPI",
      `/baskets/${props.selectedBasket.id}/slides`,
      myInit,
    )
      .then((response) => {
        basketNotification("Successfully added slides to basket", true);
        props.setBasketsLastUpdated(new Date());
        setIsAddAllLoading(false);
      })
      .catch((error) => {
        basketNotification("Something went wrong", false);
        setIsAddAllLoading(false);
      });
  };

  return (
    <>
      <Card>
        <Row align="space-evenly">
          <Col>
            <ShoppingCartOutlined style={{ fontSize: "48px" }} />
          </Col>
          <Col>
            <Space direction="vertical">
              {!props.selectedBasket && (
                <Tooltip placement="right" title={"Choose a current basket"}>
                  <Button type="text" onClick={props.openBaskets}>
                    Select a basket
                  </Button>
                </Tooltip>
              )}
              {props.selectedBasket && (
                <Typography.Paragraph>
                  {props.selectedBasket.name}
                </Typography.Paragraph>
              )}
              {props.selectedBasket && (
                <Typography.Paragraph>
                  {props.selectedBasket.slides.length} Slides
                </Typography.Paragraph>
              )}
              {props.selectedBasket && !props.selectedBasket.manifest && (
                <Tooltip
                  placement="right"
                  title={"Modify this basket with your changes"}
                >
                  <Button
                    type="text"
                    onClick={handleUpdateBasket}
                    loading={isUpdateLoading}
                  >
                    Save Selection{" "}
                    {props.selectedSlides.size -
                      props.selectedBasket.slides.length >
                    0
                      ? "(+"
                      : "("}
                    {props.selectedSlides.size -
                      props.selectedBasket.slides.length}
                    )
                  </Button>
                </Tooltip>
              )}
              {props.selectedBasket && !props.selectedBasket.manifest && (
                <Tooltip
                  placement="right"
                  title={
                    props.noOfResults > 10000
                      ? "There are too many query results to add them all to a basket. Basket sizes are capped at 10k."
                      : "Add all slides returned by this query to your basket"
                  }
                >
                  <Button
                    type="text"
                    onClick={handleAddQueryResults}
                    loading={isAddAllLoading}
                    disabled={props.noOfResults > 10000}
                  >
                    Add All Results
                  </Button>
                </Tooltip>
              )}
              {props.selectedBasket && (
                <Tooltip
                  placement="right"
                  title={
                    "Choose another basket from your list or create a new one"
                  }
                >
                  <Button type="text" onClick={props.openBaskets}>
                    Switch Basket
                  </Button>
                </Tooltip>
              )}
              {props.selectedBasket && (
                <Tooltip
                  placement="right"
                  title={"Open this basket's full details page"}
                >
                  <Button
                    type="text"
                    href={`baskets/${props.selectedBasket.id}`}
                  >
                    View Basket
                  </Button>
                </Tooltip>
              )}
              <Button type="text" onClick={() => setIsHelpModalOpen(true)}>
                How does this work?
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>
      <Modal
        open={isHelpModalOpen}
        onOk={() => setIsHelpModalOpen(false)}
        onCancel={() => setIsHelpModalOpen(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => setIsHelpModalOpen(false)}
          >
            I understand
          </Button>,
        ]}
      >
        <Typography.Title>Baskets</Typography.Title>
        <Typography.Paragraph>
          Baskets allow you to save a collection of slides for future use. You
          may create a basket and add slides to it as you wish. Baskets are
          limited to 10k slides each.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Once you are happy with your basket you may submit it for approval by
          the access committee.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Baskets that are awaiting approval or that have been approved are not
          able to be modified.
        </Typography.Paragraph>
      </Modal>
    </>
  );
}
export default BasketWidget;
