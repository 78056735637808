import React, { useEffect, useState } from "react";
import "../IndexPage.css";
import AuthLayout from "../../layout/AuthLayout";
import "@aws-amplify/ui-react/styles.css";
import { useAuth } from "../../auth/AuthProvider";

import { Typography, Form, Input, Button } from "antd";
import "antd/dist/reset.css";
const { Text } = Typography;

function RegisterPage() {
  const { onRegister } = useAuth();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "PathLAKE - Register";
  }, []);

  const register = async (values) => {
    setIsLoading(true);
    onRegister(
      values.username,
      values.email,
      values.given_name,
      values.family_name,
      values.password,
    )
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  };

  return (
    <AuthLayout title="Register Account">
      <Form
        name="signIn"
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
        onFinish={register}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="given_name"
          rules={[
            {
              required: true,
              message: "Please input your firstname!",
            },
          ]}
        >
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item
          name="family_name"
          rules={[
            {
              required: true,
              message: "Please input your surname!",
            },
          ]}
        >
          <Input placeholder="Surname" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item
          name="password2"
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!"),
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="Repeat Password" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            loading={isLoading}
          >
            Register
          </Button>
        </Form.Item>
      </Form>
      {error && <Text type="danger">{error}</Text>}
    </AuthLayout>
  );
}

export default RegisterPage;
