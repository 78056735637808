import React from "react";
import { Layout } from "antd";

import { useParams } from "react-router-dom";
import ViewManifest from "../components/AdminPage/ViewManifest";
import DefaultHeader from "../layout/DefaultHeader";
import DefaultFooter from "../layout/DefaultFooter";

function ManifestPage({ user }, props) {
  const { manifestId } = useParams();
  return (
    <Layout>
      <DefaultHeader />
      <Layout>
        <ViewManifest manifestId={manifestId} />
      </Layout>
      <DefaultFooter />
    </Layout>
  );
}

export default ManifestPage;
