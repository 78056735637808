import React from "react";
import { useNavigate } from "react-router-dom";

import { Layout, Menu } from "antd";
const { Sider } = Layout;

function AdminSider() {
  const navigate = useNavigate();

  const items = [
    {
      label: "Dashboard",
      key: "index",
      onClick: () => {
        navigate("/admin");
      },
    },
    {
      label: "Projects",
      key: "projects",
      children: [
        {
          label: "Create Project",
          key: "createProject",
          onClick: () => {
            navigate("/admin#createProject");
          },
        },
        {
          label: "List Projects",
          key: "listProjects",
          onClick: () => {
            navigate("/admin#projects");
          },
        },
      ],
    },
    {
      label: "Users",
      key: "users",
      children: [
        {
          label: "Create User",
          key: "createUser",
          onClick: () => {
            navigate("/admin#createUser");
          },
        },
        {
          label: "List Users",
          key: "listUsers",
          onClick: () => {
            navigate("/admin#users");
          },
        },
      ],
    },
    {
      label: "Manifests",
      key: "manifests",
      onClick: () => {
        navigate("/admin#manifests");
      },
    },
  ];
  return (
    <Sider theme="light">
      <Menu
        mode="inline"
        defaultOpenKeys={["projects", "users"]}
        items={items}
      />
    </Sider>
  );
}

export default AdminSider;
