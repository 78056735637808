import React from "react";
import "../pages/IndexPage.css";
import IndexHeader from "../layout/IndexHeader";
import IndexFooter from "../layout/IndexFooter";
import Animate from "rc-animate";
import "@aws-amplify/ui-react/styles.css";

import { Card, Layout, Row, Col, Typography } from "antd";
import "antd/dist/reset.css";
const { Content } = Layout;
const { Title } = Typography;

function AuthLayout({ title, children }) {
  return (
    <Layout id="screen">
      <IndexHeader />
      <Content>
        <Row align="bottom" justify="center" style={{ minHeight: "50vh" }}>
          <Animate transitionName="fade" transitionAppear>
            <Col>
              <Card style={{ backgroundColor: "white" }}>
                <div style={{ textAlign: "center" }}>
                  <Title>{title}</Title>
                </div>
                {children}
              </Card>
            </Col>
          </Animate>
        </Row>
      </Content>
      <IndexFooter />
    </Layout>
  );
}

export default AuthLayout;
