import React, { useState, useEffect, useRef } from "react";
import "ol/ol.css";
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import View from "ol/View";
import Zoomify from "ol/source/Zoomify";
import TileState from "ol/TileState";
import { Auth } from "aws-amplify";
import axios from "axios";
import { Button, Space } from "antd";
import { PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";

function MiniViewer(props) {
  const [map, setMap] = useState();

  // pull refs
  const mapElement = useRef();

  // create state ref that can be accessed in OpenLayers onclick callback function
  //  https://stackoverflow.com/a/60643670
  const mapRef = useRef();
  mapRef.current = map;

  const [isLoaded, setIsLoaded] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [maxZoom, setMaxZoom] = useState(0);
  const [minZoom, setMinZoom] = useState(0);

  const increaseZoomLevel = () => {
    const currentLevel = map.getView().getZoom();
    if (currentLevel < maxZoom) {
      setZoomLevel(Math.floor(currentLevel) + 1);
    }
  };

  const decreaseZoomLevel = () => {
    const currentLevel = map.getView().getZoom();
    if (currentLevel > minZoom + 1) {
      setZoomLevel(Math.ceil(currentLevel) - 1);
    }
  };

  useEffect(() => {
    const imgWidth = props.width;
    const imgHeight = props.height;

    // get the slide url from the api.
    const zoomifyUrl =
      process.env.REACT_APP_API_ENDPOINT +
      "slide/" +
      props.name +
      "/{TileGroup}/{z}-{x}-{y}.jpg" +
      "?path=" +
      props.path;

    // create Zoomify instance
    const source = new Zoomify({
      url: zoomifyUrl,
      size: [imgWidth, imgHeight],
      crossOrigin: "anonymous",
      zDirection: -1, // Ensure we get a tile with the screen resolution or higher
    });

    const makeRequest = async () => {
      // Using Auth.currentSession() seems to ensure that the token is refreshed
      const session = await Auth.currentSession();

      // create an axios instance to retrieve the tiles
      const tileRetriever = axios.create({
        headers: {
          Authorization: session.getIdToken().getJwtToken(),
        },
        responseType: "blob",
      });

      /*
      / Function that creates a custom tile loader in order to send authorisation header and load tiles
      / params: tile(Image)
      /         src(string)
      / assigns: the content specified by the created domstring to the underlying image(getImage())
      */
      function customLoader(tile, src) {
        tileRetriever
          .get(src)
          .then((response) => {
            // create a DOMString(16-bit unsigned integers) containing a URL representing the object return by the api
            tile.getImage().src = URL.createObjectURL(response.data);
          })
          .catch((error) => {
            // if tile cannot be loaded set its state to error
            // otherwise the tile cannot be removed from the tile queue and will block other requests
            tile.setState(TileState.ERROR);
          });
      }

      // set the tile load function to the created custom loader
      source.setTileLoadFunction(customLoader);

      const extent = source.getTileGrid().getExtent();

      const layer = new TileLayer({
        source: source,
      });

      const view = new View({
        resolutions: layer.getSource().getTileGrid().getResolutions(),
        extent: extent,
      });

      setMaxZoom(view.getMaxZoom());
      setMinZoom(view.getMinZoom());

      layer.setSource(source);

      const map = new Map({
        controls: [],
        layers: [layer],
        target: mapElement.current,
        view: view,
      });
      map.getView().fit(extent);

      // save map and vector layer references to state
      setMap(map);
    };

    makeRequest();
    setIsLoaded(true);
  }, [props.width, props.height, props.name, props.path]);

  useEffect(() => {
    if (isLoaded) {
      map.getView().setZoom(zoomLevel);
    }
  }, [zoomLevel]);

  return (
    <>
      <div>
        <Space size={"middle"}>
          <Button
            type="default"
            onClick={increaseZoomLevel}
            disabled={!isLoaded}
            icon={<PlusSquareOutlined />}
            size={"large"}
          ></Button>
          <Button
            type="default"
            onClick={decreaseZoomLevel}
            disabled={!isLoaded}
            icon={<MinusSquareOutlined />}
            size={"large"}
          ></Button>
        </Space>
      </div>
      <div
        ref={mapElement}
        style={{ height: props.containerHeight }}
        className="map-container"
      ></div>
    </>
  );
}

export default MiniViewer;
