import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import { useMediaQuery } from "react-responsive";
import "./IndexPage.css";
import { useParams } from "react-router-dom";
import _ from "lodash";
import DefaultHeader from "../layout/DefaultHeader";
import DefaultFooter from "../layout/DefaultFooter";
import { useSearchParams } from "react-router-dom";
import AgesBarChart from "../components/ExplorePage/AgesBarChart";
import TissuePieChart from "../components/ExplorePage/TissuePieChart";
import CommonAlert from "../components/Core/CommonAlert";
import "@aws-amplify/ui-react/styles.css";
import { Layout, Row, Col, Button, Typography } from "antd";
import "antd/dist/reset.css";
import SubmitBasketModal from "../components/BasketPage/SubmitBasketModal";
import RemoveSlideModal from "../components/BasketPage/RemoveSlideModal";
import StatisticsCol from "../components/BasketPage/StatisticsCol";

import BasketTable from "../components/BasketPage/BasketTable";

const { Content } = Layout;
const { Title } = Typography;

function BasketPage() {
  const isNotXL = useMediaQuery({ query: "(max-width: 1200px)" });

  const [basket, setBasket] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showRemoveSlideModal, setShowRemoveSlideModal] = useState(false);
  const [removeModalSlide, setRemoveModalSlide] = useState(null);
  const [showManifestModal, setShowManifestModal] = useState(false);
  const { basketId } = useParams();
  const [stats, setStats] = useState({});
  const [searchParams, setSearchParams] = useSearchParams({
    page: "0",
    limit: "10",
  });
  const [hasManifest, setHasManifest] = useState(false);

  const fetchStats = async () => {
    const session = await Auth.currentSession();
    setIsLoading(true);
    // Get basket statistics
    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      response: false, //Only want response body and not the whole Axiom object
      queryStringParameters: {
        no_slides: "False",
        aggs: "True",
      },
    };
    API.get("PathLakeRESTAPI", `/baskets/${basketId}`, myInit)
      .then((response) => {
        setStats(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setError({ data: error.response.data, code: error.response.status });
        setIsLoading(false);
      });
  };

  const fetchData = async () => {
    const session = await Auth.currentSession();
    setIsLoading(true);
    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      response: false, //Only want response body and not the whole Axiom object
      queryStringParameters: {
        no_slides: "False",
        aggs: "False",
        page: parseInt(searchParams.get("page")),
        limit: parseInt(searchParams.get("limit")),
      },
    };
    API.get("PathLakeRESTAPI", `/baskets/${basketId}`, myInit)
      .then((response) => {
        //Create keys for slides
        response?.slides?.map((obj) => {
          obj.key = obj._id;
          return obj;
        });
        setBasket(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setError({ data: error.response.data, code: error.response.status });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.title = "PathLAKE - Basket - basketId";
    fetchStats();
    fetchData();
  }, [basketId]);

  const basketCallback = (args) => {
    setSelectedRowKeys([]);
    setSearchParams({
      page: args.current - 1,
      limit: args.pageSize,
    });
  };

  useEffect(() => {
    fetchData();
  }, [searchParams]);

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const showRemoveSelectedModal = () => {
    setShowRemoveSlideModal(true);
  };

  const handleRemoveSlides = () => {
    setIsLoading(true);
    var newBasket = _.cloneDeep(basket);

    // Condition for removing a single slide
    var body;

    if (removeModalSlide) {
      newBasket.slides = basket.slides?.filter(
        (slide, _) => slide.key !== removeModalSlide.key,
      );
      body = _.cloneDeep(removeModalSlide.key);
      body = [body];
    }

    // Condition for removing all selected slides
    else {
      newBasket.slides = basket.slides.filter(
        (slide, _) => !selectedRowKeys.includes(slide.key),
      );
      body = _.cloneDeep(selectedRowKeys);
      setSelectedRowKeys([]);
    }

    const deleteSlides = async () => {
      const session = await Auth.currentSession();
      const myInit = {
        headers: {
          Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
        },
        response: false, //Only want response body and not the whole Axiom object
        body: body,
        queryStringParameters: {
          remove: "true",
        },
      };
      API.patch("PathLakeRESTAPI", `/baskets/${basketId}/slides`, myInit)
        .then((response) => {
          setBasket(newBasket);

          fetchStats(); // Get new statistics, this also resets the total for pagination

          if (newBasket?.slides?.length === 0) {
            // If current page is empty, go to previous page
            const currentPage = parseInt(searchParams.get("page"));
            if (currentPage !== 0) {
              setSearchParams({
                page: currentPage - 1,
                limit: searchParams.get("limit"),
              });
            }
          } else {
            fetchData();
          }
        })
        .catch((error) => {
          setError({ data: error.response.data, code: error.response.status });
        });
    };

    deleteSlides();
    setRemoveModalSlide(null);
    setShowRemoveSlideModal(false);
  };

  const handleCancel = () => {
    setRemoveModalSlide(null);
    setShowRemoveSlideModal(false);
  };

  const handleOpenManifestModal = () => {
    setShowManifestModal(true);
  };

  const handleCreateManifest = async () => {
    setSubmitLoading(true);
    const session = await Auth.currentSession();
    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      response: false, //Only want response body and not the whole Axiom object
      queryStringParameters: {
        basketId: basketId,
      },
    };
    API.post("PathLakeRESTAPI", "/manifests/", myInit)
      .then((response) => {
        setSubmitLoading(false);
        setHasManifest(true);
        setShowManifestModal(false);
      })
      .catch((error) => {
        setSubmitLoading(false);
        setShowManifestModal(false);
        setError({ data: error.response.data, code: error.response.status });
      });
  };

  const handleCloseManifestModal = () => {
    setShowManifestModal(false);
  };

  const sortAges = (a, b, reverse = false) => {
    return parseInt(a["key"].split(" - ")[0]) >
      parseInt(b["key"].split(" - ")[0])
      ? 1
      : -1;
  };

  return (
    <>
      <Layout>
        <DefaultHeader />
        <Content style={{ padding: 16 }}>
          <Row align="middle" justify="space-around">
            <Col span={24}>
              {error && <CommonAlert data={error.data} code={error.code} />}
            </Col>
            <Col span={18} md={20}>
              <Title>{basket.name}</Title>
            </Col>
            <Col span={6} md={4}>
              <Button
                onClick={handleOpenManifestModal}
                disabled={
                  "manifest" in basket ||
                  isLoading ||
                  hasManifest ||
                  basket.slides?.length === 0
                }
                type="primary"
                block
              >
                {isNotXL ? "Submit" : "Submit Basket"}
              </Button>
            </Col>
            <StatisticsCol
              title="Age"
              isLoading={isLoading}
              hasData={"age_stats" in stats}
            >
              <AgesBarChart data={stats?.age_stats?.sort(sortAges)} />
            </StatisticsCol>
            <StatisticsCol
              title="Sex"
              isLoading={isLoading}
              hasData={"sex_stats" in stats}
            >
              <TissuePieChart data={stats?.sex_stats} />
            </StatisticsCol>
            <StatisticsCol
              title="Tissues"
              isLoading={isLoading}
              hasData={"tissue_stats" in stats}
            >
              <TissuePieChart data={stats?.tissue_stats} />
            </StatisticsCol>
            <StatisticsCol
              title="Stains"
              isLoading={isLoading}
              hasData={"stain_stats" in stats}
            >
              <TissuePieChart data={stats?.stain_stats} />
            </StatisticsCol>
            <Col span={24}>
              <Row justify="end">
                <Col span={12} md={4}>
                  <Button
                    onClick={showRemoveSelectedModal}
                    disabled={!selectedRowKeys.length}
                    block
                  >
                    Remove Selected{" "}
                    {selectedRowKeys.length
                      ? `(${selectedRowKeys.length})`
                      : ""}
                  </Button>
                </Col>
              </Row>
              <BasketTable
                data={basket.slides}
                onChange={basketCallback}
                rowSelection={rowSelection}
                isLoading={isLoading}
                pagination={{
                  total: stats.total,
                  current: parseInt(searchParams.get("page")) + 1,
                  pageSize: parseInt(searchParams.get("limit")),
                }}
                isNotXL={isNotXL}
              />
            </Col>
          </Row>
        </Content>
        <DefaultFooter />
      </Layout>
      <RemoveSlideModal
        open={showRemoveSlideModal}
        onOk={handleRemoveSlides}
        onCancel={handleCancel}
        removeModalSlide={removeModalSlide}
        selectedRowKeys={selectedRowKeys}
      />
      <SubmitBasketModal
        open={showManifestModal}
        onCancel={handleCloseManifestModal}
        onOk={handleCreateManifest}
        confirmLoading={isSubmitLoading}
      />
    </>
  );
}

export default BasketPage;
