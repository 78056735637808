import React, { useState, useEffect } from "react";
import "@aws-amplify/ui-react/styles.css";
import Animate from "rc-animate";
import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
import { Card, Form, Input, Row, Col, Select, Slider, Switch } from "antd";
import "antd/dist/reset.css";
import "./ProjectsSearchFilter.less";
const { Option } = Select;

function ProjectsSearchFilter(props) {
  const [tissues, setTissues] = useState([]);
  const [stains, setStains] = useState([]);
  const [codes, setCodes] = useState([]);
  const toggleAgeRange = (checked) => {
    props.setIsAgeRangeEnabled(checked);
  };
  const toggleCodesMode = (checked) => {
    props.setIsAllCodesEnabled(checked);
  };
  const sortDocs = (a, b) => {
    return a["doc_count"] < b["doc_count"] ? 1 : -1;
  };

  const registerUser = async () => {
    props.setIsCreatingAccount(true);
    const session = await Auth.currentSession();
    var username = session.getIdToken().payload["cognito:username"];

    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      response: false, //Only want response body and not the whole Axiom object
      body: { username: username },
    };

    API.put("PathLakeRESTAPI", `users/${username}`, myInit)
      .then((response) => {
        props.setIsCreatingAccount(false);
      })
      .catch((error) => {});
  };

  const fetchData = async () => {
    Auth.currentSession()
      .then((session) => {
        const myInit = {
          headers: {
            Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
          },
          response: false, //Only want response body and not the whole Axiom object
        };
        API.get("PathLakeRESTAPI", `/tissues/`, myInit)
          .then((response) => {
            setTissues(
              response["tissues"].sort(sortDocs).map((obj) => {
                return { label: obj.key, value: obj.key };
              }),
            );
          })
          .catch((error) => {
            if (
              error.response.status === 400 &&
              error.response.data === "Account not registered"
            ) {
              registerUser();
            }
          });

        API.get("PathLakeRESTAPI", `/stains/`, myInit)
          .then((response) => {
            setStains(
              response["stains"].sort(sortDocs).map((obj) => {
                return { label: obj.key, value: obj.key };
              }),
            );
          })
          .catch((error) => {});

        API.get("PathLakeRESTAPI", `/diagnoses/`, myInit)
          .then((response) => {
            setCodes(
              response["diagnoses"].sort(sortDocs).map((obj) => {
                return { label: obj.key, value: obj.key };
              }),
            );
          })
          .catch((error) => {});
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Animate transitionName="fade" transitionAppear>
        {props.showSearchFilter && (
          <>
            <Card className="searchFilter" style={{ backgroundColor: "white" }}>
              <Row gutter={16} justify="center">
                <Col style={{ width: "200px" }}>
                  <Form.Item name="name" label="Name">
                    <Input
                      placeholder="Slide Name"
                      allowClear={true}
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Form.Item name="project" label="Project">
                    <Input
                      placeholder="Project Name"
                      allowClear={true}
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Form.Item name="dataset" label="Dataset">
                    <Input
                      placeholder="Dataset"
                      allowClear={true}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col style={{ width: "200px" }}>
                  <Form.Item name="sex" label="Sex">
                    <Select allowClear={true}>
                      <Option value="Male">Male</Option>
                      <Option value="Female">Female</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="age"
                    label={
                      <>
                        Age range{" "}
                        <span style={{ marginLeft: 10 }}>
                          <Switch
                            size="small"
                            checked={props.isAgeRangeEnabled}
                            onChange={toggleAgeRange}
                          />
                        </span>
                      </>
                    }
                    style={{ width: "100%", marginRight: 0 }}
                  >
                    <Slider
                      range
                      step={5}
                      max={110}
                      min={0}
                      disabled={!props.isAgeRangeEnabled}
                    />
                  </Form.Item>
                  <Form.Item
                    name="codes"
                    label={
                      <>
                        Codes{" "}
                        <span style={{ marginLeft: 10 }}>
                          <Switch
                            size="small"
                            checked={props.isCodesAnyEnabled}
                            onChange={toggleCodesMode}
                          />
                        </span>
                        {props.isCodesAnyEnabled ? " Any" : " All"}
                      </>
                    }
                  >
                    <Select
                      placeholder="Codes"
                      allowClear={true}
                      autoComplete="off"
                      mode="tags"
                      options={codes}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ width: "200px" }}>
                  <Form.Item name="stain" label="Stain">
                    <Select
                      showSearch
                      placeholder="Stain"
                      allowClear={true}
                      autoComplete="off"
                      options={stains}
                    />
                  </Form.Item>
                  <Form.Item name="site" label="Tissue">
                    <Select
                      showSearch
                      placeholder="Tissue"
                      allowClear={true}
                      autoComplete="off"
                      options={tissues}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ width: "200px" }}>
                  <Form.Item name="caseId" label="Case">
                    <Input
                      placeholder="Case Id"
                      allowClear={true}
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Form.Item name="groupBy" label="Group By">
                    <Select allowClear={true}>
                      <Option value="GroupByCase">Case ID</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </>
        )}
      </Animate>
    </>
  );
}

export default ProjectsSearchFilter;
