import React from "react";
import dayjs from "dayjs";
import { Row, Col, Space, Card, Typography, Spin } from "antd";
import { Link } from "react-router-dom";
import "./BasketCard.less";
import {
  IssuesCloseOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
const { Text } = Typography;

// Enum for status
const status = {
  CREATED: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
  FAILED: 3,
  AWAITING_ADMIN: 4,
  APPROVED: 5,
};

function StatusPart(props) {
  let className = "created";
  let logo = <CheckCircleOutlined style={{ fontSize: "2em" }} />;
  let heading = "Created";
  let subheading = "";
  if (props.status === status.FAILED) {
    className = "failed";
    logo = <IssuesCloseOutlined style={{ fontSize: "2em" }} />;
    heading = "Failed";
    subheading = "Your basket has failed to process.";
  } else if (props.status === status.COMPLETED) {
    className = "ready";
    logo = <CheckCircleOutlined style={{ fontSize: "2em" }} />;
    heading = "Ready";
    subheading = "Your basket is ready for download.";
  } else if (props.status === status.IN_PROGRESS) {
    className = "preparing";
    logo = (
      <Spin indicator={<LoadingOutlined style={{ fontSize: "2em" }} spin />} />
    );
    heading = "Preparing";
    subheading = "We are preparing your basket for download.";
  } else if (props.status === status.AWAITING_ADMIN) {
    className = "awaiting";
    logo = <ClockCircleOutlined style={{ fontSize: "2em" }} />;
    heading = "Awaiting Approval";
    subheading = "We are awaiting approval from the PathLAKE Access Committee";
  } else if (props.status === status.APPROVED) {
    className = "ready";
    logo = <CheckCircleOutlined style={{ fontSize: "2em" }} />;
    heading = "Approved";
    subheading = "Your request has been approved";
  }
  return (
    <>
      <Space align="center" style={{ width: "100%" }} className={className}>
        {logo}
        <Text>{heading}</Text>
      </Space>
      <Space align="center" style={{ width: "100%" }} className={className}>
        <Text italic>{subheading}</Text>
      </Space>
    </>
  );
}

function BasketCard(props) {
  return (
    <Card style={{ margin: 12, backgroundColor: "white" }}>
      <Row gutter={24}>
        <Col span={12}>
          <Text strong>{props.name}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Status</Text>
        </Col>
        <Col span={12}>
          <Space direction="vertical">
            <Text italic>{dayjs(props.date).format("D MMM YYYY")}</Text>
            <Text danger>{props.noSlides} Slides</Text>
            <Space>
              {props.step === status.CREATED && (
                <Typography.Link onClick={props.onDelete}>
                  Delete
                </Typography.Link>
              )}
              <Link to={`/baskets/${props.id}`}>Open</Link>
            </Space>
          </Space>
        </Col>
        <Col span={12}>
          <Row style={{ height: "100%", minHeight: "100px" }}>
            <Col span={24} className="status">
              <StatusPart status={props.step} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}
export default BasketCard;
