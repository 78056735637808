import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./AdminPage.less";
import DefaultHeader from "../layout/DefaultHeader";
import DefaultFooter from "../layout/DefaultFooter";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import {
  Layout,
  Typography,
  Form,
  Input,
  Button,
  Select,
  Card,
  Row,
  Col,
} from "antd";

import "antd/dist/reset.css";
import Dashboard from "../components/AdminPage/Dashboard";

import ListUsers from "../components/AdminPage/ListUsers";
import CreateProject from "../components/AdminPage/CreateProject";
import ListProjects from "../components/AdminPage/ListProjects";
import ListManifests from "../components/AdminPage/ListManifests";
import AdminSider from "../components/AdminPage/AdminSider";
import CommonAlert from "../components/Core/CommonAlert";

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

function AdminPage() {
  const [error, setError] = useState(null);
  const [hasSuccess, setSuccess] = useState(false);
  let location = useLocation();

  let subpage = location["hash"];

  const myForm = React.createRef();

  useEffect(() => {
    document.title = "PathLAKE - Admin";
  }, []);

  const createUser = async (values) => {
    const session = await Auth.currentSession();

    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      response: false, //Only want response body and not the whole Axiom object
      body: values,
    };

    API.post("PathLakeRESTAPI", `/users/`, myInit)
      .then((response) => {
        setSuccess(true);
      })
      .catch((error) => {
        setSuccess(false);
        setError({ data: error.response.data, code: error.response.status });
      });
  };

  return (
    <>
      <Layout>
        <DefaultHeader selected="admin" />
        <Layout>
          <AdminSider />
          <Content>
            <Row>
              <Col span={24}>
                {!subpage && <Dashboard />}
                {subpage === "#createUser" && (
                  <Card>
                    <Form
                      ref={myForm}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      layout="vertical"
                      onFinish={createUser}
                      style={{ width: "500px" }}
                    >
                      <Title>Create User</Title>
                      {hasSuccess && (
                        <CommonAlert data="Created User" code={200} />
                      )}
                      {error && (
                        <CommonAlert data={error.data} code={error.code} />
                      )}
                      <Form.Item
                        label="Username"
                        name="username"
                        rules={[
                          {
                            required: true,
                            message: "Please input the username!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please input the email!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="First Name"
                        name="given_name"
                        rules={[
                          {
                            required: true,
                            message: "Please input the first name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Surname"
                        name="family_name"
                        rules={[
                          {
                            required: true,
                            message: "Please input your surname!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Role"
                        name="role"
                        rules={[
                          { required: true, message: "Please input the role!" },
                        ]}
                      >
                        <Select>
                          <Option value="trust-user">Trust User</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Organisation"
                        name="organisation"
                        rules={[
                          {
                            required: true,
                            message: "Please input the organisation!",
                          },
                        ]}
                      >
                        <Select>
                          <Option value="warwick">University of Warwick</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Create
                        </Button>
                      </Form.Item>
                    </Form>
                  </Card>
                )}
                {subpage === "#users" && <ListUsers />}
                {subpage === "#createProject" && <CreateProject />}
                {subpage === "#projects" && <ListProjects />}
                {subpage === "#manifests" && <ListManifests />}
              </Col>
            </Row>
          </Content>
        </Layout>
        <DefaultFooter />
      </Layout>
    </>
  );
}

export default AdminPage;
