/*
 * TODO: Need to rename Projects Page to Index page
 * And rename ProjectsAdmin page to Projects page
 */
import React from "react";
import { Layout } from "antd";

import { useParams } from "react-router-dom";
import ViewProject from "../components/AdminPage/ViewProject";
import DefaultHeader from "../layout/DefaultHeader";
import DefaultFooter from "../layout/DefaultFooter";

function ProjectsAdminPage({ user }, props) {
  const { projectId } = useParams();
  return (
    <Layout>
      <DefaultHeader />
      <Layout>
        <ViewProject projectId={projectId} />
      </Layout>
      <DefaultFooter />
    </Layout>
  );
}

export default ProjectsAdminPage;
