import React, { useEffect, useState } from "react";
import "../IndexPage.css";
import AuthLayout from "../../layout/AuthLayout";
import "@aws-amplify/ui-react/styles.css";
import { useAuth } from "../../auth/AuthProvider";
import { Link } from "react-router-dom";

import { Typography, Form, Input, Button, Space } from "antd";
import "antd/dist/reset.css";
const { Text } = Typography;

function LoginPage() {
  const { onLogin, onConfirm } = useAuth();
  const [error, setError] = useState();
  const [isConfirmState, setIsConfirmState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "PathLAKE - Login";
  }, []);

  const signIn = async (values) => {
    setIsLoading(true);
    onLogin(values.username, values.password)
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.message === "User is not confirmed.") {
          setIsConfirmState(true);
        } else {
          setError(error.message);
        }
      });
  };

  const confirm = async (values) => {
    setIsLoading(true);
    onConfirm(values.username, values.code)
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  };

  return (
    <AuthLayout title={isConfirmState ? "Verify" : "Welcome"}>
      <Form
        name="signIn"
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
        onFinish={isConfirmState ? confirm : signIn}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input placeholder="Login" />
        </Form.Item>

        {!isConfirmState ? (
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
        ) : (
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: "Please input your verification code!",
              },
            ]}
          >
            <Input placeholder="Verification code" />
          </Form.Item>
        )}

        {!isConfirmState ? (
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={isLoading}
            >
              Sign In
            </Button>
          </Form.Item>
        ) : (
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={isLoading}
            >
              Confirm
            </Button>
          </Form.Item>
        )}
      </Form>
      <Space direction="vertical">
        {error && <Text type="danger">{error}</Text>}
        <Link to="/forgotten" component={Typography.Link}>
          Forgotten Password
        </Link>
      </Space>
    </AuthLayout>
  );
}

export default LoginPage;
