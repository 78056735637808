import React from "react";
import "@aws-amplify/ui-react/styles.css";
import { Table } from "antd";
import "antd/dist/reset.css";

const excludedFields = [
  "thumbnail_path",
  "file_path",
  "project_specific",
  "owner",
  "age_group",
];

const technicalFields = [
  "scanner",
  "mpp",
  "dimensions",
  "objective_power",
  "level_count",
  "level_dimensions",
  "level_downsample",
  "axes",
  "suffix",
  "conversion",
];

// Converts data to a format that the table understands
function convertDataFormat(
  oldData,
  includeTechnical = true,
  filterTerm = "",
  prepKey = "",
  isChild = false,
) {
  var newData = [];
  for (const field in oldData) {
    if (
      field[0] !== "_" &&
      field[0] !== "@" &&
      !excludedFields.includes(field) &&
      !(
        !isChild &&
        includeTechnical === false &&
        technicalFields.includes(field)
      ) &&
      !(
        !isChild &&
        includeTechnical === true &&
        !technicalFields.includes(field)
      ) &&
      (field.includes(filterTerm) || prepKey !== "")
    ) {
      let key = prepKey + "_" + field;
      if (Array.isArray(oldData[field])) {
        newData.push({
          key: key,
          field: field,
          value: oldData[field].join(", "),
        });
      } else if (typeof oldData[field] === "object") {
        newData.push({
          key: key,
          field: field,
          children: convertDataFormat(
            oldData[field],
            true,
            filterTerm,
            key,
            true,
          ),
        });
      } else {
        newData.push({
          key: key,
          field: field,
          value: oldData[field],
        });
      }
    }
  }
  console.log(newData);
  return newData;
}

function MetadataTree(props) {
  return (
    <Table
      bordered={true}
      pagination={false}
      dataSource={convertDataFormat(
        props.data,
        props.showTechnicalMetadata,
        props.filterTerm,
      )}
    >
      <Table.ColumnGroup
        title={props.showTechnicalMetadata ? "Technical" : "Clinical"}
      >
        <Table.Column title="Field" dataIndex="field" key="key" />
        <Table.Column title="Value" dataIndex="value" key="value" width="60%" />
      </Table.ColumnGroup>
    </Table>
  );
}

export default MetadataTree;
