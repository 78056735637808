import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
import { useSearchParams } from "react-router-dom";
import DefaultHeader from "../layout/DefaultHeader";
import DefaultFooter from "../layout/DefaultFooter";
import TissuePieChart from "../components/ExplorePage/TissuePieChart";
import DataSetsBarChart from "../components/ExplorePage/DataSetsBarChart";
import AgesBarChart from "../components/ExplorePage/AgesBarChart";
import OverviewBox from "../components/Statistics/OverviewBox";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import CommonAlert from "../components/Core/CommonAlert";

import "@aws-amplify/ui-react/styles.css";
import TweenOne from "rc-tween-one";
import Children from "rc-tween-one/lib/plugin/ChildrenPlugin";
import {
  Layout,
  Row,
  Col,
  Typography,
  Card,
  Space,
  Table,
  Skeleton,
  Form,
  Switch,
  Slider,
  Select,
  Button,
  Tooltip,
  Empty,
  Collapse,
  Divider,
  Tag,
} from "antd";
import {
  SearchOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import "antd/dist/reset.css";

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;
TweenOne.plugins.push(Children);
function ExplorePage() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [ages, setAges] = useState([]);
  const [tissues, setTissues] = useState([]);
  const [tissuesAll, setTissuesAll] = useState([]);
  const [dataSets, setDataSets] = useState([]);
  const [dataSetsAll, setDataSetsAll] = useState([]);
  const [stains, setStains] = useState([]);
  const [stainsAll, setStainsAll] = useState([]);
  const [agesLoaded, setAgesLoaded] = useState(false);
  const [tissuesLoaded, setTissuesLoaded] = useState(false);
  const [dataSetsLoaded, setDataSetsLoaded] = useState(false);
  const [stainsLoaded, setStainsLoaded] = useState(false);
  const [total, setTotal] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [requestState, setRequestState] = useState({
    dataset: searchParams.get("dataset"),
    sex: searchParams.get("sex"),
    age: searchParams.get("age")
      ? searchParams.get("age").split(",")
      : [0, 109],
    stain: searchParams.get("stain"),
    site: searchParams.get("site"),
  });
  const [isAgeRangeEnabled, setIsAgeRangeEnabled] = useState(
    searchParams.get("age") !== null,
  );
  const [formState, setFormState] = useState({
    dataset: requestState.dataset,
    sex: requestState.sex,
    age: requestState.age,
    stain: requestState.stain,
    site: requestState.site,
  });

  const myForm = React.createRef();

  const search = () => {
    navigate(`/search?${searchParams.toString()}`);
  };

  const sortDocs = (a, b) => {
    return a["doc_count"] < b["doc_count"] ? 1 : -1;
  };

  const sortKeys = (a, b) => {
    return a["key"] < b["key"] ? 1 : -1;
  };

  const sortAges = (a, b, reverse = false) => {
    return parseInt(a["key"].split(" - ")[0]) >
      parseInt(b["key"].split(" - ")[0])
      ? 1
      : -1;
  };

  const handleCloseTag = (tag) => {
    if (tag[0] === "age") {
      myForm.current?.setFieldValue(tag[0], [0, 109]);
      setIsAgeRangeEnabled(false);
    } else {
      myForm.current?.setFieldValue(tag[0], null);
    }
    myForm.current?.submit();
  };

  useEffect(() => {
    if (myForm.current) {
      myForm.current.resetFields();
    }
  }, [formState]);

  const columns = [
    {
      title: "Name",
      dataIndex: "key",
      key: "key",
      render: (key, slide) => (
        <Button
          type="text"
          onClick={() => {
            setSearchParams({ ...searchParams, site: key });
            myForm.current.setFieldValue("site", key);
          }}
        >
          {key}
        </Button>
      ),
      sorter: sortKeys,
    },
    {
      title: "Slides",
      dataIndex: "doc_count",
      key: "doc_count",
      sorter: sortDocs,
    },
  ];

  const fetchData = async () => {
    const session = await Auth.currentSession();

    let query = {};
    if (requestState.dataset) {
      query["dataset"] = requestState.dataset;
    }
    if (requestState.sex) {
      query["sex"] = requestState.sex;
    }
    if (requestState.age && isAgeRangeEnabled) {
      query["age"] = requestState.age.join();
    }
    if (requestState.stain) {
      query["stain"] = requestState.stain;
    }
    if (requestState.site) {
      query["tissue_type"] = requestState.site;
    }
    if (requestState.ethnicity) {
      query["ethnicity"] = requestState.ethnicity;
    }
    if (requestState.caseId) {
      query["case_id"] = requestState.caseId;
    }

    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      response: false, //Only want response body and not the whole Axiom object
      queryStringParameters: query,
    };

    const myInitNoQuery = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      response: false, //Only want response body and not the whole Axiom object
    };

    API.get("PathLakeRESTAPI", `/tissues/`, myInit)
      .then((response) => {
        setTissues(response["tissues"].sort(sortDocs));
        setTotal(response["total"]);
        setTissuesLoaded(true);
      })
      .catch((error) => {
        setError({ data: error.response.data, code: error.response.status });
      });

    API.get("PathLakeRESTAPI", `/tissues/`, myInitNoQuery)
      .then((response) => {
        setTissuesAll(response["tissues"].sort(sortDocs));
      })
      .catch((error) => {
        setError({ data: error.response.data, code: error.response.status });
      });

    API.get("PathLakeRESTAPI", `/datasets/`, myInit)
      .then((response) => {
        setDataSets(response["datasets"].sort(sortDocs));
        setDataSetsLoaded(true);
      })
      .catch((error) => {
        setError({ data: error.response.data, code: error.response.status });
      });

    API.get("PathLakeRESTAPI", `/datasets/`, myInitNoQuery)
      .then((response) => {
        setDataSetsAll(response["datasets"].sort(sortDocs));
      })
      .catch((error) => {
        setError({ data: error.response.data, code: error.response.status });
      });

    API.get("PathLakeRESTAPI", `/stains/`, myInit)
      .then((response) => {
        setStains(response["stains"].sort(sortDocs));
        setStainsLoaded(true);
      })
      .catch((error) => {
        setError({ data: error.response.data, code: error.response.status });
      });

    API.get("PathLakeRESTAPI", `/stains/`, myInitNoQuery)
      .then((response) => {
        setStainsAll(response["stains"].sort(sortDocs));
      })
      .catch((error) => {
        setError({ data: error.response.data, code: error.response.status });
      });

    API.get("PathLakeRESTAPI", `/ages/`, myInit)
      .then((response) => {
        setAges(response["ages"].sort(sortAges));
        setAgesLoaded(true);
      })
      .catch((error) => {
        setError({ data: error.response.data, code: error.response.status });
      });
  };
  useEffect(() => {
    fetchData();
  }, [requestState]);

  useEffect(() => {
    setRequestState({
      dataset: searchParams.get("dataset"),
      sex: searchParams.get("sex"),
      age: searchParams.get("age")
        ? searchParams.get("age").split(",")
        : [0, 109],
      stain: searchParams.get("stain"),
      site: searchParams.get("site"),
      ethnicity: searchParams.get("ethnicity"),
    });
  }, [searchParams]);

  const applyFilter = (searchValues) => {
    setSearchParams(
      Object.fromEntries(
        Object.entries(searchValues)
          .filter(([k, v]) => v !== null && v !== "" && v !== undefined)
          .filter(([k, v]) => !(k === "age" && !isAgeRangeEnabled))
          .map((v, _) => {
            return Array.isArray(v[1]) ? [v[0], v[1].join()] : v;
          }),
      ),
    );
  };
  return (
    <Layout>
      <DefaultHeader selected="explore" />
      <Content style={{ minHeight: "100vh" }}>
        {error && (
          <Row
            align="middle"
            justify="space-around"
            style={{ width: "100%", height: "100%" }}
          >
            <Col span={20}>
              <CommonAlert data={error.data} code={error.code} />
            </Col>
          </Row>
        )}
        <Row justify="center" style={{ width: "100%" }}>
          <Col span={24} md={4}>
            <Row justify="center" style={{ width: "100%" }}>
              <Col span={22} md={24}>
                <Divider>Filters</Divider>
                <Space align="center" wrap style={{ margin: 32 }}>
                  {Object.entries(requestState).map((item) => {
                    var key = item[0];
                    var value = item[1];
                    if (item[1] !== null && item[1] !== undefined) {
                      if (item[0] === "age") {
                        value = item[1][0] + " - " + item[1][1];
                      }
                      if (item[0] === "codes" && Array.isArray(item[1])) {
                        value = item[1].join(", ");
                      }
                      if (
                        item[0] !== "q" &&
                        item[0] !== "groupBy" &&
                        item[0] !== "page" &&
                        item[0] !== "limit" &&
                        item[1] !== [] &&
                        item[1] !== "" &&
                        !(item[0] === "codes" && item[1].length === 0) &&
                        !(
                          item[0] === "age" &&
                          item[1][0] === 0 &&
                          item[1][1] === 109
                        )
                      ) {
                        return (
                          <Tooltip placement="right" title={value}>
                            <Tag
                              closable
                              onClose={(e) => {
                                e.preventDefault();
                                handleCloseTag(item);
                              }}
                            >
                              {key}: {value.substring(0, 15)}
                              {value.length > 15 ? "..." : ""}
                            </Tag>
                          </Tooltip>
                        );
                      }
                    }
                  })}
                </Space>
              </Col>
              <Col span={22} md={24}>
                <Form
                  ref={myForm}
                  onFinish={applyFilter}
                  initialValues={formState}
                >
                  <Collapse
                    ghost
                    expandIcon={({ isActive }) => (
                      <PlusOutlined rotate={isActive ? 90 : 0} />
                    )}
                  >
                    <Collapse.Panel
                      header="Dataset"
                      key="3"
                      extra={
                        <Tooltip
                          placement="right"
                          title={"Dataset (e.g. Skin)"}
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      }
                      forceRender={true}
                    >
                      <Form.Item name="dataset">
                        <Select
                          showSearch
                          placeholder="Dataset"
                          allowClear={true}
                          autoComplete="off"
                          options={dataSetsAll.map((obj) => {
                            return { label: obj.key, value: obj.key };
                          })}
                        />
                      </Form.Item>
                    </Collapse.Panel>
                    <Collapse.Panel
                      header="Sex"
                      key="4"
                      extra={
                        <Tooltip
                          placement="right"
                          title={"Sex (Male or Female)"}
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      }
                      forceRender={true}
                    >
                      <Form.Item name="sex">
                        <Select allowClear={true}>
                          <Option value="Male">Male</Option>
                          <Option value="Female">Female</Option>
                        </Select>
                      </Form.Item>
                    </Collapse.Panel>
                    <Collapse.Panel
                      header="Age Range"
                      key="5"
                      extra={
                        <Tooltip placement="right" title={"Age Range"}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      }
                      forceRender={true}
                    >
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            name="age"
                            style={{ width: "100%", marginRight: 0 }}
                          >
                            <Slider
                              range
                              step={5}
                              max={110}
                              min={0}
                              disabled={!isAgeRangeEnabled}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={4}>On</Col>
                        <Col span={4}>
                          <Switch
                            checked={isAgeRangeEnabled}
                            onChange={() => {
                              setIsAgeRangeEnabled(!isAgeRangeEnabled);
                            }}
                          />
                        </Col>
                      </Row>
                    </Collapse.Panel>
                    <Collapse.Panel
                      header="Stain"
                      key="7"
                      extra={
                        <Tooltip
                          placement="right"
                          title={"Stain Name or Type (e.g. HE, IHC)"}
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      }
                      forceRender={true}
                    >
                      <Form.Item name="stain">
                        <Select
                          showSearch
                          placeholder="Stain"
                          allowClear={true}
                          autoComplete="off"
                          options={stainsAll.map((obj) => {
                            return { label: obj.key, value: obj.key };
                          })}
                        />
                      </Form.Item>
                    </Collapse.Panel>
                    <Collapse.Panel
                      header="Tissue"
                      key="8"
                      extra={
                        <Tooltip placement="right" title={"Primary Site"}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      }
                      forceRender={true}
                    >
                      <Form.Item name="site">
                        <Select
                          showSearch
                          placeholder="Tissue"
                          allowClear={true}
                          autoComplete="off"
                          options={tissuesAll.map((obj) => {
                            return { label: obj.key, value: obj.key };
                          })}
                        />
                      </Form.Item>
                    </Collapse.Panel>
                  </Collapse>
                  <Row align="end" style={{ marginTop: 16 }}>
                    <Col style={{ marginLeft: 16 }}>
                      <Button
                        onClick={() => {
                          myForm.current.resetFields();
                          setIsAgeRangeEnabled(false);
                          setFormState({});
                        }}
                      >
                        Clear
                      </Button>
                    </Col>
                    <Col style={{ marginLeft: 16 }}>
                      <Button type="primary" htmlType="submit">
                        Apply
                      </Button>
                    </Col>
                  </Row>
                  <Row align="end">
                    <Col style={{ marginTop: 16 }}>
                      <Tooltip title="search">
                        <Button icon={<SearchOutlined />} onClick={search}>
                          Search
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col span={24} md={20}>
            <Row justify="space-around" align="middle">
              <Col
                style={{
                  width: 960,
                  maxWidth: "90vw",
                  margin: 12,
                }}
                span={22}
              >
                <OverviewBox
                  tissues={tissues.length}
                  dataSets={dataSets.length}
                  total={total}
                  filled={true}
                />
              </Col>
              {!requestState.dataset && (
                <Col
                  style={{
                    width: 480,
                    minHeight: 480,
                    maxWidth: "90vw",
                    margin: 12,
                  }}
                >
                  <Card style={{ width: "100%", height: "100%" }}>
                    <Title level={3}>Datasets</Title>
                    {!dataSetsLoaded && dataSets.length === 0 && (
                      <Skeleton
                        style={{ width: "100%", height: "100%" }}
                        paragraph={{ rows: 8 }}
                        active
                      />
                    )}
                    {dataSetsLoaded && dataSets.length === 0 && (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}

                    {dataSets.length > 0 && (
                      <DataSetsBarChart data={dataSets} />
                    )}
                  </Card>
                </Col>
              )}
              {!requestState.stain && (
                <Col
                  style={{
                    width: 480,
                    minHeight: 480,
                    maxWidth: "90vw",
                    margin: 12,
                  }}
                >
                  <Card style={{ width: "100%", height: "100%" }}>
                    <Title level={3}>Stains</Title>
                    {!stainsLoaded && stains.length === 0 && (
                      <Skeleton
                        style={{ width: "100%", height: "100%" }}
                        paragraph={{ rows: 8 }}
                        active
                      />
                    )}
                    {stainsLoaded && stains.length === 0 && (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                    {stains.length > 0 && <TissuePieChart data={stains} />}
                  </Card>
                </Col>
              )}
              {!requestState.site && (
                <Col
                  style={{
                    width: 480,
                    minHeight: 480,
                    maxWidth: "90vw",
                    margin: 12,
                  }}
                >
                  <Card style={{ width: "100%", height: "100%" }}>
                    <Title level={3}>Tissues</Title>
                    {!tissuesLoaded && tissues.length === 0 && (
                      <Skeleton
                        style={{ width: "100%", height: "100%" }}
                        paragraph={{ rows: 8 }}
                        active
                      />
                    )}
                    {tissuesLoaded && tissues.length === 0 && (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                    {tissues.length > 0 && <TissuePieChart data={tissues} />}
                  </Card>
                </Col>
              )}

              {!requestState.ages && (
                <Col
                  style={{
                    width: 480,
                    minHeight: 480,
                    maxWidth: "90vw",
                    margin: 12,
                  }}
                >
                  <Card style={{ width: "100%", height: "100%" }}>
                    <Title level={3}>Ages</Title>
                    {!agesLoaded && ages.length === 0 && (
                      <Skeleton
                        style={{ width: "100%", height: "100%" }}
                        paragraph={{ rows: 8 }}
                        active
                      />
                    )}
                    {agesLoaded && ages.length === 0 && (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                    {ages.length > 0 && <AgesBarChart data={ages} />}
                  </Card>
                </Col>
              )}

              {!requestState.site && (
                <Col
                  style={{
                    width: 960,
                    minHeight: 480,
                    maxWidth: "90vw",
                    margin: 12,
                  }}
                >
                  <Card style={{ width: "100%", height: "100%" }}>
                    <Title level={3}>Tissues</Title>
                    {!tissuesLoaded && tissues.length === 0 && (
                      <Skeleton
                        style={{ width: "100%", height: "100%" }}
                        paragraph={{ rows: 8 }}
                        active
                      />
                    )}
                    {tissuesLoaded && tissues.length === 0 && (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                    {tissues.length > 0 && (
                      <Table dataSource={tissues} columns={columns} />
                    )}
                  </Card>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Content>
      <DefaultFooter />
    </Layout>
  );
}

export default withAuthenticator(ExplorePage);
