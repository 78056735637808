import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import "./IndexPage.css";
import { useParams } from "react-router-dom";
import DefaultHeader from "../layout/DefaultHeader";
import DefaultFooter from "../layout/DefaultFooter";
import CommonAlert from "../components/Core/CommonAlert";

import "@aws-amplify/ui-react/styles.css";
import { Layout, Collapse, Typography } from "antd";
import "antd/dist/reset.css";
const { Content } = Layout;
const { Panel } = Collapse;
const { Paragraph } = Typography;

function DownloadPage(props, { user }) {
  const [error, setError] = useState(null);

  const { downloadId } = useParams();
  const [downloadUrl, setDownloadUrl] = useState("");

  useEffect(() => {
    document.title = "PathLAKE - Basket - downloadId";
    const fetchData = async () => {
      const session = await Auth.currentSession();
      const myInit = {
        headers: {
          Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
        },
        response: false, //Only want response body and not the whole Axiom object
      };

      API.get("PathLakeRESTAPI", `/baskets/download/${downloadId}`, myInit)
        .then((response) => {
          const endPoint = process.env.REACT_APP_API_ENDPOINT;
          setDownloadUrl(
            endPoint +
              "baskets/download/url/?X-Auth-Header=" +
              response.download_token,
          );
        })
        .catch((error) => {
          setError({ data: error.response.data, code: error.response.status });
        });
    };
    fetchData();
  }, [downloadId]);

  return (
    <>
      <Layout>
        <DefaultHeader />
        <Content style={{ padding: 16 }}>
          <h1>Download</h1>
          {error && <CommonAlert data={error.data} code={error.code} />}
          <Collapse defaultActiveKey={["1", "2"]}>
            <Panel header="Download our Download Tool" key="1">
              <p>
                <a href="https://github.com/TissueImageAnalytics/pathlake-downloader">
                  Link to download
                </a>
              </p>
            </Panel>
            <Panel
              header="Download your basket using this download url"
              key="2"
            >
              <Paragraph copyable>{downloadUrl}</Paragraph>
            </Panel>
          </Collapse>
        </Content>
        <DefaultFooter />
      </Layout>
    </>
  );
}

export default DownloadPage;
