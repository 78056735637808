import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import dayjs from "dayjs";
import { Card, Table, Typography, Skeleton } from "antd";
import { API } from "aws-amplify";
import CommonAlert from "../Core/CommonAlert";

function ListProjects({ user }, props) {
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const [projects, setProjects] = useState([]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      render: (name, item) => <a href={`/projects/${item.id}`}>{name}</a>,
    },
    {
      title: "Organisation",
      dataIndex: "org_id",
      key: "org_id",
      fixed: "left",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Admin(s)",
      dataIndex: "admins",
      key: "admins",
      render: (admins) => <>{admins.join(", ")}</>,
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (time) => <>{dayjs(time).format("YYYY MMM D")}</>,
      sorter: (a, b) => dayjs(a.created).diff(dayjs(b.created)),
    },
  ];

  const fetchData = async () => {
    const session = await Auth.currentSession();

    let query = {};

    const myInit = {
      headers: {
        Authorization: session.getIdToken().getJwtToken(), //ID Token set to Authorization header
      },
      queryStringParameters: query,
      response: false, //Only want response body and not the whole Axiom object
    };

    API.get("PathLakeRESTAPI", `/projects/`, myInit)
      .then((response) => {
        setProjects(response["projects"]);
        setLoaded(true);
      })
      .catch((error) => {
        setError({ data: error.response.data, code: error.response.status });
        setLoaded(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card>
      <Typography.Title>Projects</Typography.Title>
      {error && <CommonAlert data={error.data} code={error.code} />}
      {loaded && (
        <Table
          dataSource={projects}
          columns={columns}
          scroll={{ x: 1200 }}
          pagination={false}
        />
      )}
      {!loaded && <Skeleton active />}
    </Card>
  );
}

export default ListProjects;
