import React from "react";
import "@aws-amplify/ui-react/styles.css";
import { Column } from "@ant-design/plots";

import "antd/dist/reset.css";

function AgesBarChart(props) {
  const config = {
    data: props.data,
    xField: "key",
    yField: "doc_count",
    legend: false,
    color: "#582475",
    xAxis: {
      label: {
        autoRotate: false,
      },
    },
    slider: {
      start: 0,
      end: 1,
    },
  };
  return <Column {...config} />;
}

export default AgesBarChart;
